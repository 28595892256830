"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGenerateTextileFunctions;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _utils = require("wapplr/dist/common/utils");
var _getDefaults = require("./getDefaults");
function getGenerateTextileFunctions(_ref) {
  var shuffleArray = _ref.shuffleArray,
    getRandomInt = _ref.getRandomInt,
    initialData = _ref.initialData;
  function floor(n, p) {
    return Math.floor(n * p) / p;
  }
  var enableRandom = true;
  var sA = function sA(a) {
    if (enableRandom) {
      return shuffleArray(a);
    }
    return a;
  };
  function isIntersectedWith(d, i) {
    var minx = d.position.x - d.width / 2;
    var maxx = d.position.x + d.width / 2;
    var miny = d.position.y - d.height / 2;
    var maxy = d.position.y + d.height / 2;
    var r = i.filter(function (c) {
      var cminx = c.position.x - c.width / 2;
      var cmaxx = c.position.x + c.width / 2;
      var cminy = c.position.y - c.height / 2;
      var cmaxy = c.position.y + c.height / 2;
      var xI1 = minx >= cminx && minx <= cmaxx || maxx >= cminx && maxx <= cmaxx;
      var yI1 = miny >= cminy && miny <= cmaxy || maxy >= cminy && maxy <= cmaxy;
      var xI2 = cminx >= minx && cminx <= maxx || cmaxx >= minx && cmaxx <= maxx;
      var yI2 = cminy >= miny && cminy <= maxy || cmaxy >= miny && cmaxy <= maxy;
      return xI1 && yI1 || xI2 && yI2;
    });
    return r;
  }
  function generateTextile(_ref2) {
    var colorScheme = _ref2.colorScheme;
    var width = 82;
    var height = 82;
    var nN = sA([[16, 64], [64, 16], [32, 32]
    /*
    [8,8],
    [32,8],
    [8,32],
    [8,32],
    [8,64],
    [64,8],
    [32,4],*/])[0];
    var wN = nN[0];
    var dN = nN[1];
    var wO = width / wN;
    var hO = height / dN;
    var dO = 0.05;
    var startX = -width / 2;
    var startY = height / 2;
    var startZ = 0;
    var genPart = [];
    var plusLength = sA([1 /*, 2*/])[0];
    function getOctagon(_ref3) {
      var width = _ref3.width,
        height = _ref3.height;
      var wO = width;
      var heightO = height / 2;
      return [[{
        func: "moveTo",
        props: [0, 0]
      }, {
        func: "lineTo",
        props: [wO / 3, -heightO / 2]
      }, {
        func: "lineTo",
        props: [wO / 3 * 2, -heightO / 2]
      }, {
        func: "lineTo",
        props: [wO, 0]
      }, {
        func: "lineTo",
        props: [wO, heightO]
      }, {
        func: "lineTo",
        props: [wO / 3 * 2, heightO + heightO / 2]
      }, {
        func: "lineTo",
        props: [wO / 3, heightO + heightO / 2]
      }, {
        func: "lineTo",
        props: [0, heightO]
      }, {
        func: "lineTo",
        props: [0, 0]
      }]];
    }
    var geometryType = sA(["extrude", "rectangle", "mix"])[0];
    (0, _toConsumableArray2["default"])(Array(wN)).forEach(function (_, wI) {
      (0, _toConsumableArray2["default"])(Array(dN)).forEach(function (_, dI) {
        if (plusLength > 1 && dI >= dN - plusLength / 2) {
          return;
        }
        var heightO = hO * (getRandomInt(10, plusLength * 10) / 10);
        var y = startY - dI * hO - heightO / 2;
        if (y - heightO / 2 < -height / 2) {
          heightO = -height / 2 - (startY + dI * hO);
          y = startY - dI * hO - heightO / 2;
        }
        genPart.push((0, _objectSpread2["default"])({
          width: wO,
          height: heightO,
          depth: dO,
          position: {
            x: startX + wI * wO + wO / 2,
            y: y,
            z: startZ + hO / 2
          },
          material: {
            color: shuffleArray([colorScheme.white, colorScheme.black, colorScheme.yellow, colorScheme.blue, colorScheme.red, colorScheme.lensColor])[0]
          },
          rotation: {
            x: sA([0, -getRandomInt(3, 7) * Math.PI / 180])[0],
            y: sA([0, -getRandomInt(3, 7) * Math.PI / 180])[0],
            z: 0
          }
        }, geometryType === "extrude" ? {
          geometryType: "extrude",
          geometryProps: getOctagon({
            width: wO,
            height: heightO
          })
        } : geometryType === "mix" ? sA([{}, {
          geometryType: "extrude",
          geometryProps: getOctagon({
            width: wO,
            height: heightO
          })
        }])[0] : {}));
      });
    });
    var textileD = [].concat(genPart);
    function setNewMinMax(_ref4) {
      var _ref4$mm = _ref4.mm,
        mm = _ref4$mm === void 0 ? {} : _ref4$mm,
        object = _ref4.object,
        p = _ref4.p,
        d = _ref4.d,
        callback = _ref4.callback;
      var min = object.position[p] - object[d] / 2;
      var max = object.position[p] + object[d] / 2;
      if (typeof mm["min" + p] === "undefined" || mm["min" + p] > min) {
        mm["min" + p] = min;
        if (callback) {
          callback({
            type: "min",
            object: object
          });
        }
      }
      if (typeof mm["max" + p] === "undefined" || mm["max" + p] < max) {
        mm["max" + p] = max;
        if (callback) {
          callback({
            type: "max",
            object: object
          });
        }
      }
    }
    var generatedMinMax = {};
    textileD.forEach(function (object, i) {
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    return {
      generatedData: (0, _toConsumableArray2["default"])(textileD.map(function (data) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, data), {}, {
          width: floor(data.width, 100),
          height: floor(data.height, 100),
          depth: floor(data.depth, 100),
          position: {
            x: floor(data.position.x, 100),
            y: floor(data.position.y, 100),
            z: floor(data.position.z, 100)
          },
          rotation: {
            x: floor(data.rotation.x, 1000),
            y: floor(data.rotation.y, 1000),
            z: floor(data.rotation.z, 1000)
          }
        });
      })),
      generatedMinMax: generatedMinMax,
      analytics: {
        "1. Color scheme": colorScheme.name
      }
    };
  }
  function generateCompositionsForTextile() {
    var defaultColors = {
      red: "#de1305",
      blue: "#426dfb",
      yellow: "#e7b508",
      black: "#000000",
      white: "#e7ddcc"
    };
    var colorScheme = sA([{
      background: "#eefafa",
      sketchBg: "rgba(144,164,174,0.2)",
      sketchBgGrayscale: "rgba(144,164,174,0.2)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Candy",
      shortCode: "MR",
      borderAndFont: "#1537e0",
      white: "#d2fbfb",
      red: "#a6e0e3",
      blue: "#e794fe",
      yellow: "#fe94c3",
      black: "#1537e0",
      lensColor: "#ffa7ce"
    }
    /*{
        background: "#181a1a",
        sketchBg: "rgba(144,164,174,0.2)",
        sketchBgGrayscale: "rgba(144,164,174,0.2)",
        canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
        name: "Before falling asleep",
        shortCode: "MR",
        borderAndFont: "#606564",
        white: "#1f1e1c",
        red: "#000000",
        blue: "#2c2d2c",
        yellow: "#4b4f4f",
        black: "#f1e9ff",
        lensColor: "#000000",
    }
    {
        background: "#dde8ee",
        sketchBg: "rgba(34,196,138,0.24)",
        sketchBgGrayscale:  "rgba(34,196,138,0.24)",
        canvas1Colors: ["#dde8ee", "#dde8ee"],
        name: "Purple, silver",
        shortCode: "PS",
        borderAndFont: "#3b3333",
        white: "#c4ced0",
        red: "#D5DDE0",
        blue: "#e9ecee",
        yellow: "#e1eaee",
        black: "#a990e0",
        lensColor: "#a990e0",
    },
    {
        background: "#ffcdd2",
        sketchBg: "rgba(148,124,145,0.4)",
        sketchBgGrayscale: "rgba(115,121,128,0.3)",
        canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
        name: "Piggy pink geek",
        shortCode: "PP",
        borderAndFont: "rgba(29,29,38,0.7)",
        white: "#90a4ae",
        red: "#394643",
        blue: "#231f24",
        yellow: "#ffcdd2",
        black: "#90a4ae",
        lensColor: "#ffcdd2"
    },
    {
        background: "#e2efc3",
        sketchBg: "rgba(34,196,138,0.24)",
        sketchBgGrayscale:  "rgba(34,196,138,0.24)",
        canvas1Colors: ["#e2efc3", "#e2efc3"],
        name: "Wood, bone, leaf",
        shortCode: "WB",
        borderAndFont: "#312517",
        white: "#f0dfc1",
        red: "#e8c582",
        blue: "#ebca9d",
        yellow: "#886a4e",
        black: "#f0dfc1",
        lensColor: "#a1b27e"
    },
    {
        background: "#e6efee",
        sketchBg: "rgba(144,164,174,0.2)",
        sketchBgGrayscale: "rgba(144,164,174,0.2)",
        canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
        name: "Marigold, rose gold",
        shortCode: "MR",
        borderAndFont: "#3b3333",
        white: "#E9A7A1",
        red: "#E9A7A1",
        blue: "#e9b7a1",
        yellow: "#e9a7a1",
        black: "#efaa1e",
        lensColor: "#efaa1e",
    }*/])[0];
    var ggData = generateTextile({
      colorScheme: colorScheme
    });
    var generatedData = (0, _toConsumableArray2["default"])(ggData.generatedData);
    var compositions = (0, _toConsumableArray2["default"])(initialData.map(function (v) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, v), {}, {
        data: generatedData
      });
    }));
    compositions[0].background = colorScheme.background;
    compositions[0].randomVariables = (0, _toConsumableArray2["default"])(Array(100).fill(0).map(function () {
      return sA([0, getRandomInt(0, 99999) / 100000])[0];
    }));
    compositions[0].canvasFullBackground = [
    /*{
        type: "canvas1",
        props: colorScheme.canvas1Colors,
    },*/
    {
      type: "carco",
      props: [colorScheme.borderAndFont, 2, 2, 2, 2, [7, 17], 7, 10]
    }];
    compositions[0].canvasBackground = [];
    compositions[0].cameraTarget = {
      x: 0,
      y: 0,
      z: 0
    };
    compositions[0].cameraPosition = {
      x: 0,
      y: 170,
      z: 0
    };
    compositions[0].objectsPosition = {
      x: 0,
      y: 0,
      z: 0
    };
    compositions[0].cameraZoom = 1;
    compositions[0].splitScreen = {
      disableChangeSplitScreen: true
    };
    compositions[0].objectsRotation = {
      x: -Math.PI / 2,
      y: 0,
      z: 0
    };
    compositions[0].controlsProps = {
      minPolarAngle: 0,
      maxPolarAngle: 0,
      minAzimuthAngle: -Math.PI / 2,
      maxAzimuthAngle: Math.PI / 2
    };
    compositions[0].lights = [{
      x: 0,
      y: ggData.generatedMinMax.maxy + 80,
      z: 80,
      type: "Directional",
      props: ["#ffffff", 1]
    }];
    compositions[0].ambientLight = {
      props: ["#ffffff", 3]
    };
    compositions[0].animation = ["D"];
    compositions[0].animationProps = (0, _objectSpread2["default"])({
      addObjectsAnimationType: "fromCurrentToNext",
      addObjectsAnimationTime: [3000, 5000],
      cameraMoveTime: 3000,
      waitTimeBetweenAnimations: 1,
      waitTimeBeforeAnimation: 1,
      waitTimeBeforeAnimation2: 1,
      waitTimeFinalEnd: 1
    }, sA([{
      easingFunction: "Elastic",
      easingType: "Out"
    }
    /*{
        easingFunction: "Linear",
        easingType: "None"
    },
    {
        easingFunction: "Exponential",
        easingType: "In"
    },*/])[0]);
    var generatedDimension = sA(["square4K" /*"landscape4K", "portrait4K"*/])[0];
    var dimensions = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _getDefaults.defaultDimensions), {}, {
      squareHD: {}
    });
    compositions[0].dimensions = {
      dimensions: Object.keys(dimensions).map(function (key) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, dimensions[key]), {}, {
          key: key
        });
      }),
      defaultDimension: generatedDimension
    };
    compositions[0].data.forEach(function (d) {
      if (d.material.opacity > 0 || typeof d.material.opacity === "undefined") {
        d.shadow = {
          disableReceiveShadow: true
        };
      }
    });
    compositions[1] = (0, _utils.copyObject)(compositions[0]);
    compositions[1].data.forEach(function (d) {
      d.rotation = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.rotation), {}, {
        x: sA([d.rotation.x,
        //floor(d.rotation.x + getRandomInt(-90,90) * Math.PI / 180, 1000),
        floor(d.rotation.x + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0],
        y: sA([d.rotation.y,
        //floor(d.rotation.y + getRandomInt(-90,90) * Math.PI / 180, 1000),
        floor(d.rotation.y + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0],
        z: sA([
        //d.rotation.z + 0 * Math.PI / 180,
        floor(d.rotation.z + getRandomInt(-90, 90) * Math.PI / 180, 1000)])[0]
      });
      d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
        y: sA([d.position.y, floor(d.position.y - Math.min(d.width, d.height) * (getRandomInt(0, 10) / 10), 1000)])[0]
      });
    });
    compositions[1].objectsRotation = {
      x: -Math.PI / 2,
      y: 0,
      z: sA([0 /*,180*/])[0] * Math.PI / 180
    };
    compositions[2] = (0, _utils.copyObject)(compositions[1]);
    var cube1 = {
      width: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4,
      height: (ggData.generatedMinMax.maxy - ggData.generatedMinMax.miny) / 4,
      depth: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4,
      position: {
        x: 0,
        y: 0,
        z: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 / 2
      }
    };
    var cube2 = {
      width: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 2,
      height: (ggData.generatedMinMax.maxy - ggData.generatedMinMax.miny) / 4 * 2,
      depth: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 2,
      position: {
        x: 0,
        y: 0,
        z: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 2 / 2
      }
    };
    var cube3 = {
      width: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 3,
      height: (ggData.generatedMinMax.maxy - ggData.generatedMinMax.miny) / 4 * 3,
      depth: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 3,
      position: {
        x: 0,
        y: 0,
        z: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 3 / 2
      }
    };
    compositions[2].data.forEach(function (d) {
      var r = isIntersectedWith(d, [cube1]);
      if (!r.length) {
        d.rotation = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.rotation), {}, {
          x: sA([d.rotation.x + 179.9 * Math.PI / 180, d.rotation.x, floor(d.rotation.x + getRandomInt(-90, 90) * Math.PI / 180, 1000), floor(d.rotation.x + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0],
          z: sA([d.rotation.z, d.rotation.z * 2,
          //d.rotation.z + 360 * Math.PI / 180,
          floor(d.rotation.z + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0],
          y: sA([
          //d.rotation.y + 180 * Math.PI / 180,
          d.rotation.y, floor(d.rotation.y + getRandomInt(-90, 90) * Math.PI / 180, 1000), floor(d.rotation.y + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0]
        });
        d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
          y: sA([d.position.y, floor(d.position.y - Math.min(d.width, d.height) * (getRandomInt(0, 10) / 10), 1000)])[0]
        });
        d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
          z: floor(d.position.z + 4 * (getRandomInt(10, 20) / 10), 1000)
        });
        var r2 = isIntersectedWith(d, [cube2]);
        var r3 = isIntersectedWith(d, [cube3]);
        if (!r2.length && !r3.length) {
          d.position.z = d.position.z * 4;
        } else if (r3.length && !r2.length) {
          d.position.z = d.position.z;
        } else if (r2.length) {
          d.position.z = -d.position.z * 2;
        }
      } else {
        d.rotation = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.rotation), {}, {
          z: sA([floor(d.rotation.z + getRandomInt(-30, 30) * Math.PI / 180, 1000)])[0]
        });
        d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
          z: floor(d.position.z + 4 * (getRandomInt(10, 20) / 10), 1000) * -6
        });
      }
    });

    //compositions[2].data.push({...cube, material: {color: colorScheme.black}})

    compositions[2].animationProps = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, compositions[2].animationProps), sA([{
      easingFunction: "Sinusoidal",
      easingType: "Out"
    }
    /*{
        easingFunction: "Linear",
        easingType: "None"
    },
    {
        easingFunction: "Exponential",
        easingType: "Out"
    },*/])[0]);
    compositions.splice(0, 1);
    var analytics = (0, _objectSpread2["default"])({}, ggData.analytics);
    return {
      compositions: compositions,
      generatedData: generatedData,
      analytics: analytics
    };
  }
  function generateRenderPropsForTextile() {
    return {
      renderType: "WebGL",
      shadow: {
        enable: true,
        opacity: 0.5
      },
      camera: {
        d: 25,
        fov: 40,
        type: "perspective"
      }
    };
  }
  return {
    generateCompositionsForTextile: generateCompositionsForTextile,
    generateRenderPropsForTextile: generateRenderPropsForTextile
  };
}