"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGenerateConstructionsFunctions;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _getDefaults2 = _interopRequireDefault(require("./getDefaults"));
var _excluded = ["generatedData", "generatedMinMax"];
function getGenerateConstructionsFunctions(_ref) {
  var shuffleArray = _ref.shuffleArray,
    getRandomInt = _ref.getRandomInt,
    generatePropsArray = _ref.generatePropsArray,
    initialData = _ref.initialData;
  var _getDefaults = (0, _getDefaults2["default"])(),
    defaultCameraTarget = _getDefaults.defaultCameraTarget;

  /*function drawImage(img, ctx, samplePerRow = 10, disableCenter) {
       const canvas = ctx.canvas;
      const width = canvas.width;
      const height = canvas.height;
       const hRatio = width / img.width;
      const vRatio =  height / img.height;
      const ratio  = Math.min ( hRatio, vRatio );
      const centerShift_x = (disableCenter) ? 0 : Math.round(( width - img.width * ratio ) / 2);
      const centerShift_y = (disableCenter) ? 0 : Math.round(( height - img.height * ratio ) / 2);
      ctx.clearRect(0,0, width, height);
      ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
       const w = img.width * ratio;
      const h = img.height * ratio;
      const pixelArr = ctx.getImageData(centerShift_x, centerShift_y, w, h).data;
      const sample_size = Math.floor(w/samplePerRow)+1;
       const items = [];
       for (let y = 0; y < h; y += sample_size) {
          for (let x = 0; x < w; x += sample_size) {
              const p = (x + y * Math.floor(w)) * 4;
              ctx.fillStyle = "rgba(" + pixelArr[p] + "," + pixelArr[p + 1] + "," + pixelArr[p + 2] + "," + pixelArr[p + 3] + ")";
              ctx.fillRect(x+centerShift_x, y+centerShift_y, sample_size, sample_size);
               items.push({
                  color: "rgb(" + pixelArr[p] + ", " + pixelArr[p + 1] + ", " + pixelArr[p + 2] + ")",
                  opacity: pixelArr[p + 3],
                  x: x+centerShift_x,
                  y: y+centerShift_y,
                  width: sample_size,
                  height: sample_size
              })
           }
      }
       return items;
   }*/

  function generateData() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var red = "#de1305";
    var blue = "#426dfb";
    var yellow = "#e7b508";
    var black = "#000000";
    var white = "#ffffff";
    var gray = "#ddcfca";
    var _props$lengthMin = props.lengthMin,
      lengthMin = _props$lengthMin === void 0 ? 24 : _props$lengthMin,
      _props$lengthMax = props.lengthMax,
      lengthMax = _props$lengthMax === void 0 ? 48 : _props$lengthMax;
    var _props$length = props.length,
      length = _props$length === void 0 ? getRandomInt(lengthMin, lengthMax) : _props$length,
      _props$color = props.color,
      color = _props$color === void 0 ? [{
        d: white,
        n: 0,
        name: "White"
      }, {
        d: yellow,
        n: Math.floor(length / 12),
        name: "Yellow"
      }, {
        d: gray,
        n: Math.floor(length / 8),
        name: "Gray"
      }, {
        d: blue,
        n: Math.floor(length / 8),
        name: "Blue"
      }, {
        d: black,
        n: Math.floor(length / 12),
        name: "Black"
      }, {
        d: red,
        n: Math.floor(length / 8),
        name: "Red"
      }

      /*{d: 0x6ecbf6, n:Math.floor(length/12)},
      {d: 0xfcf600, n: Math.floor(length/8)},
      {d:0x00f79a, n:Math.floor(length/8)},
      {d: 0x2a2355, n:Math.floor(length/12)},
      {d:0xdfd9f5, n:Math.floor(length/8)}*/] : _props$color,
      _props$opacity = props.opacity,
      opacity = _props$opacity === void 0 ? [{
        d: 1,
        n: 0
      }, {
        d: 0.95,
        n: Math.floor(length / 2)
      }, {
        d: 0,
        n: Math.floor(length / 12)
      }] : _props$opacity,
      _props$thickness = props.thickness,
      thickness = _props$thickness === void 0 ? [{
        d: 0.2,
        n: 0
      }, {
        d: 0.9,
        n: Math.floor(length / 8)
      }, {
        d: 0.8,
        n: Math.floor(length / 8)
      }] : _props$thickness,
      _props$orientation = props.orientation,
      orientation = _props$orientation === void 0 ? [{
        d: "width",
        n: 0
      }, {
        d: "depth",
        n: 0
      }, {
        d: "height",
        n: Math.floor(length / 4)
      }] : _props$orientation,
      _props$size = props.size,
      size = _props$size === void 0 ? [{
        d: {
          width: [3, 7],
          height: [3, 7],
          depth: [6, 9]
        },
        n: 0
      }, {
        d: {
          width: [2, 4],
          height: [5, 8],
          depth: [6, 9]
        },
        n: Math.floor(length / 4)
      }] : _props$size,
      _props$symmetry = props.symmetry,
      symmetry = _props$symmetry === void 0 ? false : _props$symmetry,
      _props$disableCloseEl = props.disableCloseElements,
      disableCloseElements = _props$disableCloseEl === void 0 ? false : _props$disableCloseEl;

    /*
    {d:{width:[3,7], height:[3,17], depth:[6,9]}, n:0},
    {d:{width:[2,4], height:[20,24], depth:[6,9]}, n:Math.floor(length/4)}
    */

    /*
    {d:{width:[20,24], height:[2,2], depth:[2,2]}, n:0},
    {d:{width:[2,2], height:[16,24], depth:[2,2]}, n:Math.floor(length/8)},
    {d:{width:[6,6], height:[6,6], depth:[1,8]}, n:Math.floor(length/2)}
    */

    /*
    {d:{width:[20,24], height:[2,2], depth:[2,2]}, n:0},
    {d:{width:[2,2], height:[16,24], depth:[2,2]}, n:Math.floor(length/8)},
    {d:{width:[6,6], height:[6,6], depth:[1,8]}, n:Math.floor(length/2)}
    * */

    var minMax = props.minMax || {};
    function setNewMinMax(_ref2) {
      var _ref2$mm = _ref2.mm,
        mm = _ref2$mm === void 0 ? minMax : _ref2$mm,
        object = _ref2.object,
        p = _ref2.p,
        d = _ref2.d;
      var min = object.position[p] - object[d] / 2;
      var max = object.position[p] + object[d] / 2;
      if (typeof mm["min" + p] === "undefined" || mm["min" + p] > min) {
        mm["min" + p] = min;
      }
      if (typeof mm["max" + p] === "undefined" || mm["max" + p] < max) {
        mm["max" + p] = max;
      }
    }
    if (!props.minMax) {
      var defaultData = initialData[0].data;
      defaultData.forEach(function (object) {
        setNewMinMax({
          object: object,
          p: "x",
          d: "width"
        });
        setNewMinMax({
          object: object,
          p: "y",
          d: "height"
        });
        setNewMinMax({
          object: object,
          p: "z",
          d: "depth"
        });
      });
    }
    var g = {
      color: color,
      opacity: opacity,
      thickness: thickness,
      orientation: orientation,
      size: size
    };
    function generateOrDont(_ref3) {
      var name = _ref3.name;
      return g[name].length === length ? g[name] : generatePropsArray({
        props: g[name],
        length: length
      });
    }
    var generatedColor = generateOrDont({
      name: "color"
    });
    var generatedOpacity = generateOrDont({
      name: "opacity"
    });
    var generatedThickness = generateOrDont({
      name: "thickness"
    });
    var generatedOrientation = generateOrDont({
      name: "orientation"
    });
    var generatedSize = generateOrDont({
      name: "size"
    });
    var lastPosition = {
      x: 0,
      y: 0,
      z: 0
    };
    var lastDimensions = {
      width: 1,
      height: 1,
      depth: generatedThickness[0]
    };
    var generatedData = (0, _toConsumableArray2["default"])(Array(length)).map(function (x, i) {
      var orientation = generatedOrientation[i];
      var thickness = generatedThickness[i];
      var sizeMinMax = generatedSize[i];
      var dimensions = {
        width: getRandomInt(sizeMinMax.width[0], sizeMinMax.width[1]),
        height: getRandomInt(sizeMinMax.height[0], sizeMinMax.height[1]),
        depth: getRandomInt(sizeMinMax.depth[0], sizeMinMax.depth[1])
      };
      dimensions[orientation] = thickness;
      var multiplier = {
        x: shuffleArray([1, -1, 0])[0],
        y: shuffleArray([1, -1])[0],
        z: shuffleArray([1, -1, 0])[0]
      };
      if (multiplier.x === 0 && multiplier.z === 0) {
        multiplier.z = 1;
      }
      var position = {
        x: lastPosition.x + lastDimensions.width / 2 * multiplier.x + dimensions.width / 2 * multiplier.x,
        y: lastPosition.y + lastDimensions.height / 2 * multiplier.y + dimensions.height / 2 * multiplier.y,
        z: lastPosition.z + lastDimensions.depth / 2 * multiplier.z + dimensions.depth / 2 * multiplier.z
      };
      function checkMinMax(_ref4) {
        var p = _ref4.p,
          d = _ref4.d,
          _ref4$method = _ref4.method,
          method = _ref4$method === void 0 ? 1 : _ref4$method;
        var min = position[p] - dimensions[d] / 2;
        var max = position[p] + dimensions[d] / 2;
        if (min < minMax["min" + p] || max > minMax["max" + p]) {
          if (method === 1) {
            position[p] = lastPosition[p] + lastDimensions[d] / 2 * -multiplier[p] + dimensions[d] / 2 * -multiplier[p];
            checkMinMax({
              p: p,
              d: d,
              method: method + 1
            });
          }
          if (method === 2) {
            dimensions[d] = min < minMax["min" + p] ? dimensions[d] - Math.abs(min - minMax["min" + p]) : dimensions[d] - Math.abs(max - minMax["max" + p]);
            if (dimensions[d] < 0) {
              dimensions[d] = Math.abs(dimensions[d]);
            }
            position[p] = lastPosition[p] + lastDimensions[d] / 2 * -multiplier[p] + dimensions[d] / 2 * -multiplier[p];
            checkMinMax({
              p: p,
              d: d,
              method: method + 1
            });
          }
          if (method === 3) {
            dimensions[d] = min < minMax["min" + p] ? dimensions[d] - Math.abs(min - minMax["min" + p]) : dimensions[d] - Math.abs(max - minMax["max" + p]);
            if (dimensions[d] < 0) {
              dimensions[d] = Math.abs(dimensions[d]);
            }
            position[p] = lastPosition[p] + lastDimensions[d] / 2 * multiplier[p] + dimensions[d] / 2 * multiplier[p];
            checkMinMax({
              p: p,
              d: d,
              method: method + 1
            });
          }
          if (method === 4) {
            //console.log("ERROR", orientation, p, min, minMax["min"+p], max, minMax["max"+p])
          }
        }
      }
      checkMinMax({
        p: "x",
        d: "width"
      });
      checkMinMax({
        p: "y",
        d: "height"
      });
      checkMinMax({
        p: "z",
        d: "depth"
      });
      lastPosition = position;
      lastDimensions = dimensions;
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, dimensions), {}, {
        position: (0, _objectSpread2["default"])({}, position),
        material: {
          color: generatedColor[i],
          opacity: generatedOpacity[i]
        }
      });
    });
    var generatedMinMax = {};
    generatedData.forEach(function (object) {
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    if (!disableCloseElements) {
      try {
        var boundaryElementDown = generatedData.find(function (object) {
          return object.position.y - object.height / 2 === generatedMinMax.miny;
        });
        var boundaryElementRightBack = generatedData.find(function (object) {
          return object.position.z - object.depth / 2 === generatedMinMax.minz;
        });
        var boundaryElementLeftBack = generatedData.find(function (object) {
          return object.position.x - object.width / 2 === generatedMinMax.minx;
        });
        var possibilitiesForRight = [{
          width: generatedMinMax.maxx - (boundaryElementDown.position.x + boundaryElementDown.width / 2),
          height: 0.2,
          depth: Math.abs(generatedMinMax.minz - (boundaryElementDown.position.z - boundaryElementDown.depth / 2)),
          position: {
            x: boundaryElementDown.position.x + boundaryElementDown.width / 2 + (generatedMinMax.maxx - (boundaryElementDown.position.x + boundaryElementDown.width / 2)) / 2,
            y: generatedMinMax.miny + 0.2 / 2,
            z: boundaryElementDown.position.z - boundaryElementDown.depth / 2 + (generatedMinMax.minz - (boundaryElementDown.position.z - boundaryElementDown.depth / 2)) / 2
          },
          material: {
            color: white,
            opacity: 1
          }
        }, {
          width: generatedMinMax.maxx - (boundaryElementRightBack.position.x + boundaryElementRightBack.width / 2),
          height: Math.abs(generatedMinMax.miny - (boundaryElementRightBack.position.y - boundaryElementRightBack.height / 2)),
          depth: 0.2,
          position: {
            x: boundaryElementRightBack.position.x + boundaryElementRightBack.width / 2 + (generatedMinMax.maxx - (boundaryElementRightBack.position.x + boundaryElementRightBack.width / 2)) / 2,
            y: boundaryElementRightBack.position.y - boundaryElementRightBack.height / 2 + (generatedMinMax.miny - (boundaryElementRightBack.position.y - boundaryElementRightBack.height / 2)) / 2,
            z: generatedMinMax.minz + 0.2 / 2
          },
          material: {
            color: red,
            opacity: 1
          }
        }, {
          width: generatedMinMax.maxx - (boundaryElementRightBack.position.x + boundaryElementRightBack.width / 2),
          height: generatedMinMax.maxy - (boundaryElementRightBack.position.y + boundaryElementRightBack.height / 2),
          depth: 0.2,
          position: {
            x: boundaryElementRightBack.position.x + boundaryElementRightBack.width / 2 + (generatedMinMax.maxx - (boundaryElementRightBack.position.x + boundaryElementRightBack.width / 2)) / 2,
            y: boundaryElementRightBack.position.y + boundaryElementRightBack.height / 2 + (generatedMinMax.maxy - (boundaryElementRightBack.position.y + boundaryElementRightBack.height / 2)) / 2,
            z: generatedMinMax.minz + 0.2 / 2
          },
          material: {
            color: white,
            opacity: 1
          }
        }];
        var possibilitiesForLeft = [{
          width: Math.abs(generatedMinMax.minx - (boundaryElementDown.position.x - boundaryElementDown.width / 2)),
          height: 0.2,
          depth: generatedMinMax.maxz - (boundaryElementDown.position.z + boundaryElementDown.depth / 2),
          position: {
            x: boundaryElementDown.position.x - boundaryElementDown.width / 2 + (generatedMinMax.minx - (boundaryElementDown.position.x - boundaryElementDown.width / 2)) / 2,
            y: generatedMinMax.miny + 0.2 / 2,
            z: boundaryElementDown.position.z + boundaryElementDown.depth / 2 + (generatedMinMax.maxz - (boundaryElementDown.position.z + boundaryElementDown.depth / 2)) / 2
          },
          material: {
            color: white,
            opacity: 1
          }
        }, {
          width: 0.2,
          height: Math.abs(generatedMinMax.miny - (boundaryElementLeftBack.position.y - boundaryElementLeftBack.height / 2)),
          depth: Math.abs(generatedMinMax.maxz - (boundaryElementLeftBack.position.z + boundaryElementLeftBack.depth / 2)),
          position: {
            x: generatedMinMax.minx + 0.2 / 2,
            y: boundaryElementLeftBack.position.y - boundaryElementLeftBack.height / 2 + (generatedMinMax.miny - (boundaryElementLeftBack.position.y - boundaryElementLeftBack.height / 2)) / 2,
            z: boundaryElementLeftBack.position.z + boundaryElementLeftBack.depth / 2 + (generatedMinMax.maxz - (boundaryElementLeftBack.position.z + boundaryElementLeftBack.depth / 2)) / 2
          },
          material: {
            color: red,
            opacity: 1
          }
        }, {
          width: 0.2,
          height: Math.abs(generatedMinMax.maxy - (boundaryElementLeftBack.position.y + boundaryElementLeftBack.height / 2)),
          depth: Math.abs(generatedMinMax.maxz - (boundaryElementLeftBack.position.z + boundaryElementLeftBack.depth / 2)),
          position: {
            x: generatedMinMax.minx + 0.2 / 2,
            y: boundaryElementLeftBack.position.y + boundaryElementLeftBack.height / 2 + (generatedMinMax.maxy - (boundaryElementLeftBack.position.y + boundaryElementLeftBack.height / 2)) / 2,
            z: boundaryElementLeftBack.position.z + boundaryElementLeftBack.depth / 2 + (generatedMinMax.maxz - (boundaryElementLeftBack.position.z + boundaryElementLeftBack.depth / 2)) / 2
          },
          material: {
            color: gray,
            opacity: 1
          }
        }];
        var addBoundaryElements = [];
        var addPR = possibilitiesForRight.filter(function (o) {
          return o.width > 0 && o.height > 0 && o.depth > 0;
        }).sort(function (a, b) {
          var aT = a.width * a.height * a.depth;
          var bT = b.width * b.height * b.depth;
          return aT > bT ? 1 : aT === bT ? 0 : -1;
        });
        if (addPR.length >= 2) {
          addBoundaryElements.push(addPR[0]);
        }
        var addPL = possibilitiesForLeft.filter(function (o) {
          return o.width > 0 && o.height > 0 && o.depth > 0;
        }).sort(function (a, b) {
          var aT = a.width * a.height * a.depth;
          var bT = b.width * b.height * b.depth;
          return aT > bT ? 1 : aT === bT ? 0 : -1;
        });
        if (addPL.length >= 2) {
          addBoundaryElements.push(addPL[0]);
        }
        generatedData = [].concat(addBoundaryElements, (0, _toConsumableArray2["default"])(generatedData));
      } catch (e) {}
    }
    var shiftX = -(generatedMinMax.minx + generatedMinMax.maxx) / 2;
    var shiftY = -(generatedMinMax.miny + generatedMinMax.maxy) / 2;
    var shiftZ = -(generatedMinMax.minz + generatedMinMax.maxz) / 2;
    generatedData.forEach(function (object) {
      object.position.x = object.position.x + shiftX;
      object.position.y = object.position.y + shiftY;
      object.position.z = object.position.z + shiftZ;
    });
    if (symmetry) {
      generatedData = generatedData.reduce(function (a, value) {
        a.push(value);
        a.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, value), {}, {
          width: value.depth,
          depth: value.width,
          position: {
            x: value.position.z,
            y: value.position.y,
            z: value.position.x
          }
        }));
        return a;
      }, []);
    }
    function distanceTo(v1, v2) {
      var dx = v1.x - v2.x,
        dy = v1.y - v2.y,
        dz = v1.z - v2.z;
      return Math.sqrt(dx * dx + dy * dy + dz * dz);
    }
    generatedData.sort(function (a, b) {
      var minMaxA = {};
      setNewMinMax({
        mm: minMaxA,
        object: a,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: minMaxA,
        object: a,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: minMaxA,
        object: a,
        p: "z",
        d: "depth"
      });
      var distanceA = distanceTo({
        x: 16,
        y: 350,
        z: 9
      }, {
        x: minMaxA.maxx,
        y: minMaxA.maxy,
        z: minMaxA.maxz
      });
      var minMaxB = {};
      setNewMinMax({
        mm: minMaxB,
        object: b,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: minMaxB,
        object: b,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: minMaxB,
        object: b,
        p: "z",
        d: "depth"
      });
      var distanceB = distanceTo({
        x: 16,
        y: 350,
        z: 9
      }, {
        x: minMaxB.maxx,
        y: minMaxB.maxy,
        z: minMaxB.maxz
      });
      return distanceA > distanceB;
    });
    function getElementsDesc() {
      var range = lengthMax - lengthMin;
      var step = Math.floor(range / 3);
      if (range >= 3) {
        var range1End = lengthMin + step;
        var range2End = range1End + step;
        var elementsN = generatedData.length;
        if (elementsN <= range1End) {
          return "Low";
        } else if (elementsN > range1End && elementsN <= range2End) {
          return "Medium";
        } else {
          return "High";
        }
      }
      return "";
    }
    function getDimensionDesc(_ref5) {
      var _ref5$d = _ref5.d,
        d = _ref5$d === void 0 ? "x" : _ref5$d,
        _ref5$step = _ref5.step,
        step = _ref5$step === void 0 ? 10 : _ref5$step,
        _ref5$start = _ref5.start,
        start = _ref5$start === void 0 ? 0 : _ref5$start,
        _ref5$lowText = _ref5.lowText,
        lowText = _ref5$lowText === void 0 ? "Low" : _ref5$lowText,
        _ref5$mediumText = _ref5.mediumText,
        mediumText = _ref5$mediumText === void 0 ? "Medium" : _ref5$mediumText,
        _ref5$highText = _ref5.highText,
        highText = _ref5$highText === void 0 ? "High" : _ref5$highText;
      var range1End = start + step;
      var range2End = range1End + step;
      var value = generatedMinMax["max" + d] - generatedMinMax["min" + d];
      if (value <= range1End) {
        return lowText;
      } else if (value > range1End && value <= range2End) {
        return mediumText;
      } else {
        return highText;
      }
    }
    var n = 0;
    var analytics = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
      "Number of elements": getElementsDesc(),
      "Height": getDimensionDesc({
        d: "y",
        start: 17,
        step: 3,
        lowText: "Short",
        highText: "Tall"
      }),
      "Width": getDimensionDesc({
        d: "x",
        start: 15,
        step: 5,
        lowText: "Narrow",
        highText: "Wide"
      }),
      "Length": getDimensionDesc({
        d: "z",
        start: 22,
        step: 5,
        lowText: "Short",
        highText: "Long"
      })
    }, color.reduce(function (o, v) {
      var name = v.name;
      if (name) {
        var c = v.d;
        o[name] = generatedData.filter(function (a) {
          var _a$material, _a$material2;
          return ((_a$material = a.material) === null || _a$material === void 0 ? void 0 : _a$material.color) === c && ((_a$material2 = a.material) === null || _a$material2 === void 0 ? void 0 : _a$material2.opacity) > 0;
        }).length;
        n = n + o[name];
      }
      return o;
    }, {})), {}, {
      "Transparent": generatedData.filter(function (a) {
        var _a$material3;
        return ((_a$material3 = a.material) === null || _a$material3 === void 0 ? void 0 : _a$material3.opacity) === 0;
      }).length
    });
    return {
      generatedData: generatedData,
      analytics: analytics,
      generatedMinMax: generatedMinMax
    };
  }
  function generateCompositionsForConstructions(props) {
    var _generateData = generateData(props),
      generatedData = _generateData.generatedData,
      generatedMinMax = _generateData.generatedMinMax,
      rest = (0, _objectWithoutProperties2["default"])(_generateData, _excluded);
    var width = generatedMinMax.maxx - generatedMinMax.minx;
    var depth = generatedMinMax.maxz - generatedMinMax.minz;
    var compositions = (0, _toConsumableArray2["default"])(initialData.map(function (v) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, v), {}, {
        data: generatedData
      });
    }));
    compositions[0].cameraTarget = {};
    compositions[0].cameraTarget.x = 0;
    compositions[0].cameraTarget.y = defaultCameraTarget.y;
    compositions[0].cameraTarget.z = 0;
    compositions[0].objectsPosition = {
      x: compositions[0].cameraTarget.x,
      y: compositions[0].cameraTarget.y,
      z: compositions[0].cameraTarget.z
    };
    compositions[0].cameraPosition.x = compositions[0].cameraTarget.x + 20;
    compositions[0].cameraPosition.y = compositions[0].cameraTarget.y + 20;
    compositions[0].cameraPosition.z = width / depth * compositions[0].cameraPosition.x;
    compositions[0].cameraZoom = 1.4;
    compositions[0].animation = ["enableFirstAnimation", "ArrowRight", "ArrowLeft", "Space", "ArrowDown", "Space", 1000, "D"];
    return (0, _objectSpread2["default"])({
      compositions: compositions,
      generatedData: generatedData,
      generatedMinMax: generatedMinMax
    }, rest);
  }
  return {
    generateCompositionsForConstructions: generateCompositionsForConstructions
  };
}