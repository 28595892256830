"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGenerateGlassesFunctions;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _utils = require("wapplr/dist/common/utils");
var _getDefaults = require("./getDefaults");
function getGenerateGlassesFunctions(_ref) {
  var shuffleArray = _ref.shuffleArray,
    getRandomInt = _ref.getRandomInt,
    initialData = _ref.initialData;
  function round(n) {
    var p = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
    return Math.round(n * p) / p;
  }
  function getNearestPoints(_ref2) {
    var start = _ref2.start,
      end = _ref2.end,
      axis = _ref2.axis,
      force = _ref2.force;
    var dimName = axis === "x" ? "width" : axis === "y" ? "height" : "depth";
    var s1 = start.position[axis] + start[dimName] / 2;
    var s2 = start.position[axis] - start[dimName] / 2;
    var e1 = end.position[axis] + end[dimName] / 2;
    var e2 = end.position[axis] - end[dimName] / 2;
    var d1 = [Math.abs(s1 - e1), 1, 1];
    var d2 = [Math.abs(s1 - e2), 1, -1];
    var d3 = [Math.abs(s2 - e1), -1, 1];
    var d4 = [Math.abs(s2 - e2), -1, -1];
    var r = [d1, d2, d3, d4].sort(function (a, b) {
      if (force && force[0] === b[1] && force[1] === b[2]) {
        return 1;
      }
      return a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0;
    }).map(function (_ref3) {
      var _ref5;
      var _ref4 = (0, _slicedToArray2["default"])(_ref3, 3),
        d = _ref4[0],
        p1 = _ref4[1],
        p2 = _ref4[2];
      var axisU = axis.toUpperCase();
      var startP = start.position[axis] + p1 * start[dimName] / 2;
      var endP = end.position[axis] + p2 * end[dimName] / 2;
      return _ref5 = {}, (0, _defineProperty2["default"])(_ref5, "diff" + axisU, Math.abs(d)), (0, _defineProperty2["default"])(_ref5, "start" + axisU, startP), (0, _defineProperty2["default"])(_ref5, "end" + axisU, endP), (0, _defineProperty2["default"])(_ref5, "dir" + axisU, startP >= endP ? -1 : 1), _ref5;
    });
    return r[0];
  }
  function skewDim(_ref6) {
    var r = _ref6.r,
      axis = _ref6.axis,
      i = _ref6.i,
      length = _ref6.length,
      start = _ref6.start,
      end = _ref6.end,
      dirs = _ref6.dirs,
      steps = _ref6.steps,
      starts = _ref6.starts;
    var dimName = axis === "x" ? "width" : axis === "y" ? "height" : "depth";
    r[dimName] = start[dimName] + (i + 1) / length * (end[dimName] - start[dimName]);
    r.position[axis] = starts[axis] + dirs[axis] * steps[axis] * i - r[dimName] / 2;
  }
  function generateRightAngleWall() {
    var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var start = p.start,
      end = p.end,
      map = p.map,
      _p$forces = p.forces,
      forces = _p$forces === void 0 ? {} : _p$forces,
      enableAddStart = p.enableAddStart,
      enableAddEnd = p.enableAddEnd,
      _p$enableExtendDim = p.enableExtendDim,
      enableExtendDim = _p$enableExtendDim === void 0 ? {} : _p$enableExtendDim,
      _p$length = p.length,
      length = _p$length === void 0 ? 10 : _p$length,
      _p$weight = p.weight,
      weight = _p$weight === void 0 ? 0.15 : _p$weight;
    var _getNearestPoints = getNearestPoints({
        start: start,
        end: end,
        axis: "x",
        force: forces.x
      }),
      diffX = _getNearestPoints.diffX,
      startX = _getNearestPoints.startX,
      endX = _getNearestPoints.endX,
      dirX = _getNearestPoints.dirX;
    var _getNearestPoints2 = getNearestPoints({
        start: start,
        end: end,
        axis: "y",
        force: forces.y
      }),
      diffY = _getNearestPoints2.diffY,
      startY = _getNearestPoints2.startY,
      endY = _getNearestPoints2.endY,
      dirY = _getNearestPoints2.dirY;
    var _getNearestPoints3 = getNearestPoints({
        start: start,
        end: end,
        axis: "z",
        force: forces.z
      }),
      diffZ = _getNearestPoints3.diffZ,
      startZ = _getNearestPoints3.startZ,
      endZ = _getNearestPoints3.endZ,
      dirZ = _getNearestPoints3.dirZ;
    var stepX = round(diffX / (length - 1));
    var stepY = round(diffY / (length - 1));
    var stepZ = round(diffZ / (length - 1));
    var dirs = {
      x: dirX,
      y: dirY,
      z: dirZ
    };
    var steps = {
      x: stepX,
      y: stepY,
      z: stepZ
    };
    var starts = {
      x: startX,
      y: startY,
      z: startZ
    };
    var lastR;
    return [].concat((0, _toConsumableArray2["default"])(enableAddStart ? [start] : []), (0, _toConsumableArray2["default"])((0, _toConsumableArray2["default"])(Array(length)).map(function (u, i) {
      var width = Math.abs(diffX) / length;
      var height = Math.abs(diffY) / length;
      var depth = Math.abs(diffZ) / length;
      var x = lastR ? lastR.position.x + dirX * lastR.width / 2 + dirX * width / 2 : startX + dirX * width / 2;
      var y = lastR ? lastR.position.y + dirY * lastR.height / 2 + dirY * height / 2 : startY + dirY * height / 2;
      var z = lastR ? lastR.position.z + dirZ * lastR.depth / 2 + dirZ * depth / 2 : startZ + dirZ * depth / 2;
      var r = {
        width: width,
        height: height,
        depth: depth,
        position: {
          x: x,
          y: y,
          z: z
        }
      };
      Object.keys(enableExtendDim).forEach(function (axis) {
        if (enableExtendDim[axis]) {
          skewDim({
            r: r,
            axis: axis,
            i: i,
            length: length,
            start: start,
            end: end,
            dirs: dirs,
            steps: steps,
            starts: starts
          });
        }
      });
      var r2 = [{
        width: weight,
        height: r.height,
        depth: r.depth,
        position: {
          x: r.position.x - dirX * r.width / 2 + dirX * weight / 2,
          y: r.position.y,
          z: r.position.z
        }
      }, {
        width: r.width,
        height: r.height,
        depth: weight,
        position: {
          x: r.position.x - dirX * r.width / 2 + dirX * weight + dirX * r.width / 2,
          y: r.position.y,
          z: r.position.z + dirZ * r.depth / 2 - dirZ * weight / 2
        }
      }];
      if (map) {
        var _map = map(r, r2, i, {
            length: length
          }),
          _map2 = (0, _slicedToArray2["default"])(_map, 2),
          newR = _map2[0],
          newLastR = _map2[1];
        r = newR;
        if (newLastR) {
          lastR = newLastR;
        } else {
          lastR = r;
        }
      } else {
        lastR = r;
      }
      return r2;
    }).reduce(function (a, r2) {
      a.push.apply(a, (0, _toConsumableArray2["default"])(r2));
      return a;
    }, [])), (0, _toConsumableArray2["default"])(enableAddEnd ? [end] : []));
  }
  function generateRectangularTube() {
    var p = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var start = p.start,
      end = p.end,
      map = p.map,
      _p$forces2 = p.forces,
      forces = _p$forces2 === void 0 ? {} : _p$forces2,
      enableAddStart = p.enableAddStart,
      enableAddEnd = p.enableAddEnd,
      _p$enableExtendDim2 = p.enableExtendDim,
      enableExtendDim = _p$enableExtendDim2 === void 0 ? {} : _p$enableExtendDim2,
      _p$length2 = p.length,
      length = _p$length2 === void 0 ? 10 : _p$length2;
    var _getNearestPoints4 = getNearestPoints({
        start: start,
        end: end,
        axis: "x",
        force: forces.x
      }),
      diffX = _getNearestPoints4.diffX,
      startX = _getNearestPoints4.startX,
      endX = _getNearestPoints4.endX,
      dirX = _getNearestPoints4.dirX;
    var _getNearestPoints5 = getNearestPoints({
        start: start,
        end: end,
        axis: "y",
        force: forces.y
      }),
      diffY = _getNearestPoints5.diffY,
      startY = _getNearestPoints5.startY,
      endY = _getNearestPoints5.endY,
      dirY = _getNearestPoints5.dirY;
    var _getNearestPoints6 = getNearestPoints({
        start: start,
        end: end,
        axis: "z",
        force: forces.z
      }),
      diffZ = _getNearestPoints6.diffZ,
      startZ = _getNearestPoints6.startZ,
      endZ = _getNearestPoints6.endZ,
      dirZ = _getNearestPoints6.dirZ;
    var stepX = round(diffX / (length - 1));
    var stepY = round(diffY / (length - 1));
    var stepZ = round(diffZ / (length - 1));
    var dirs = {
      x: dirX,
      y: dirY,
      z: dirZ
    };
    var steps = {
      x: stepX,
      y: stepY,
      z: stepZ
    };
    var starts = {
      x: startX,
      y: startY,
      z: startZ
    };
    var lastR;
    return [].concat((0, _toConsumableArray2["default"])(enableAddStart ? [start] : []), (0, _toConsumableArray2["default"])((0, _toConsumableArray2["default"])(Array(length)).map(function (u, i) {
      var width = Math.abs(diffX) / length;
      var height = Math.abs(diffY) / length;
      var depth = Math.abs(diffZ) / length;
      var x = lastR ? lastR.position.x + dirX * lastR.width / 2 + dirX * width / 2 : startX + dirX * width / 2;
      var y = lastR ? lastR.position.y + dirY * lastR.height / 2 + dirY * height / 2 : startY + dirY * height / 2;
      var z = lastR ? lastR.position.z + dirZ * lastR.depth / 2 + dirZ * depth / 2 : startZ + dirZ * depth / 2;
      var r = {
        width: width,
        height: height,
        depth: depth,
        position: {
          x: x,
          y: y,
          z: z
        }
      };
      Object.keys(enableExtendDim).forEach(function (axis) {
        if (enableExtendDim[axis]) {
          skewDim({
            r: r,
            axis: axis,
            i: i,
            length: length,
            start: start,
            end: end,
            dirs: dirs,
            steps: steps,
            starts: starts
          });
        }
      });
      if (map) {
        var _map3 = map(r, i, {
            length: length
          }),
          _map4 = (0, _slicedToArray2["default"])(_map3, 2),
          newR = _map4[0],
          newLastR = _map4[1];
        r = newR;
        if (newLastR) {
          lastR = newLastR;
        } else {
          lastR = r;
        }
      } else {
        lastR = r;
      }
      return r;
    })), (0, _toConsumableArray2["default"])(enableAddEnd ? [end] : []));
  }
  function getRotationPoint(x, y, deg) {
    var x1 = x * Math.cos(deg) - y * Math.sin(deg);
    var y1 = x * Math.sin(deg) + y * Math.cos(deg);
    return {
      x1: x1,
      y1: y1
    };
  }
  function generateEndPiece(_ref7) {
    var s8 = _ref7.s8,
      s14 = _ref7.s14,
      material = _ref7.material,
      lensColor = _ref7.lensColor,
      red = _ref7.red,
      yellow = _ref7.yellow,
      opacity = _ref7.opacity,
      weight = _ref7.weight,
      _ref7$clampSize = _ref7.clampSize,
      clampSize = _ref7$clampSize === void 0 ? s14.width / 8 : _ref7$clampSize,
      addFrame = _ref7.addFrame;
    if (shuffleArray([true, false])[0]) {
      var gpLength = weight <= 0.2 ? getRandomInt(10, 14) : getRandomInt(6, 14);
      var genPart = generateRectangularTube({
        start: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s8), {}, {
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s8.position), {}, {
            z: s8.position.z + s8.depth / 2 + s8.depth / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensColor || red
          }, opacity)
        }),
        end: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
          width: addFrame ? clampSize : clampSize + weight,
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
            z: s14.position.z - (addFrame ? weight : 0),
            x: addFrame ? s14.position.x + s14.width / 2 - clampSize / 2 + weight : s14.position.x + s14.width / 2 - (clampSize + weight) / 2 + weight
          })
        }),
        forces: {
          x: [1, 1],
          y: [1, 1]
        },
        length: gpLength,
        enableAddStart: true,
        enableAddEnd: false,
        enableExtendDim: {
          x: true,
          y: true
        },
        map: function map(r) {
          r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensColor || red
          }, opacity);
          r.editLines = {
            disable: true
          };
          return [r];
        }
      });
      if (!addFrame) {
        genPart.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
          width: weight,
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
            x: s14.position.x + s14.width / 2 + weight / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensColor || red
          }, opacity),
          editLines: {
            disable: true
          }
        }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
          width: clampSize + weight,
          depth: weight / 2,
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
            x: s14.position.x + s14.width / 2 - (clampSize + weight) / 2 + weight,
            z: s14.position.z + s14.depth / 2 + weight / 2 / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensColor || red
          }, opacity),
          editLines: {
            disable: true
          }
        }));
      }
      return {
        genPart: genPart,
        gpLength: gpLength,
        endPieceName: "Boxes"
      };
    } else {
      var gpWeight = Math.min(s8.width, s14.depth) / 2;
      var _gpLength = getRandomInt(3, 4);
      var _genPart = generateRightAngleWall({
        start: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s8), {}, {
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s8.position), {}, {
            z: s8.position.z + s8.depth / 2 + s8.depth / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensColor || red
          }, opacity)
        }),
        end: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
          width: clampSize + weight,
          depth: weight,
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
            x: s14.position.x + s14.width / 2 - (clampSize + weight) / 2 + weight,
            z: s14.position.z - s14.depth / 2 - weight / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), opacity)
        }),
        forces: {
          x: [1, 1],
          y: [1, 1]
        },
        length: _gpLength,
        weight: gpWeight,
        enableAddStart: true,
        enableAddEnd: addFrame ? false : true,
        enableExtendDim: {
          y: true
        },
        map: function map(r, r2, i, _ref8) {
          var length = _ref8.length;
          r2.forEach(function (r) {
            r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), opacity);
            r.editLines = {
              disable: true
            };
          });
          if (i < length - 1) {
            var n = 3;
            var h = shuffleArray([0, 1, 2])[0];
            var stepY = r2[0].height / (n + 1) * (h + 1);
            r2.push({
              width: r2[1].width,
              height: r2[0].width,
              depth: r2[0].depth,
              position: {
                x: r2[0].position.x + r2[0].width / 2 - r2[1].width / 2,
                y: r2[0].position.y - r2[0].height / 2 + stepY,
                z: r2[0].position.z + r2[0].depth / 2 + r2[0].depth / 2
              },
              material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, r2[0].material), {}, {
                color: shuffleArray([r2[0].material.color, yellow, red])[0]
              })
            });
          }
          return [r];
        }
      });
      if (!addFrame) {
        _genPart.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
          width: weight,
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
            x: s14.position.x + s14.width / 2 + weight / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), opacity),
          editLines: {
            disable: true
          }
        }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
          width: clampSize + weight,
          depth: weight / 2,
          position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
            x: s14.position.x + s14.width / 2 - (clampSize + weight) / 2 + weight,
            z: s14.position.z + s14.depth / 2 + weight / 2 / 2
          }),
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), opacity),
          editLines: {
            disable: true
          }
        }));
      }
      return {
        genPart: _genPart,
        gpLength: _gpLength,
        endPieceName: "L-shaped"
      };
    }
  }
  function generateFrame(_ref9) {
    var lensGeometry = _ref9.lensGeometry,
      lensWidth = _ref9.lensWidth,
      lensHeight = _ref9.lensHeight,
      lensDepth = _ref9.lensDepth,
      clampSize = _ref9.clampSize,
      weight = _ref9.weight,
      material = _ref9.material,
      s14 = _ref9.s14,
      _ref9$topHeight = _ref9.topHeight,
      topHeight = _ref9$topHeight === void 0 ? lensHeight / 4 : _ref9$topHeight;
    var frameWidth = lensWidth + weight * 2;
    var frameHeight = lensHeight + weight * 2;
    var frameDepth = lensDepth + weight * 2;
    var scaleXForFrame = frameWidth / lensWidth;
    var scaleYForFrame = frameHeight / lensHeight;
    var frameGeometry = (0, _toConsumableArray2["default"])(lensGeometry.map(function (data) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, data), {}, {
        props: [data.props[0] * scaleXForFrame, data.props[1] * scaleYForFrame]
      });
    }));
    var holeWidth = lensWidth - clampSize * 2;
    var holeHeight = lensHeight - clampSize * 2;
    var scaleXForHole = holeWidth / lensWidth;
    var scaleYForHole = holeHeight / lensHeight;
    var holeGeometry = (0, _toConsumableArray2["default"])(lensGeometry.map(function (data) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, data), {}, {
        props: [data.props[0] * scaleXForHole + clampSize + weight, data.props[1] * scaleYForHole + clampSize + weight]
      });
    }));
    var scaleXForHole2 = (lensWidth + weight) / lensWidth;
    var scaleYForHole2 = (lensHeight + weight) / lensHeight;
    var frame = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
      material: (0, _objectSpread2["default"])({}, material),
      position: {
        x: s14.position.x,
        y: s14.position.y,
        z: s14.position.z
      },
      width: frameWidth,
      height: frameHeight,
      depth: frameDepth,
      geometryType: "extrude",
      geometryProps: [frameGeometry.map(function (p) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          props: [p.props[0], p.props[1] + topHeight * scaleYForFrame]
        });
      }), holeGeometry.map(function (p) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          props: [p.props[0], p.props[1] + topHeight * scaleYForHole]
        });
      })],
      shadow: {
        disableReceiveShadow: true
      }
    });
    return [(0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, frame), {}, {
      depth: weight,
      position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, frame.position), {}, {
        z: s14.position.z + lensDepth / 2 + weight / 2
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, frame), {}, {
      depth: lensDepth + weight,
      geometryProps: [frameGeometry.map(function (p) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          props: [p.props[0], p.props[1] + topHeight * scaleYForFrame]
        });
      }), (0, _toConsumableArray2["default"])(lensGeometry.map(function (data) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, data), {}, {
          props: [data.props[0] * scaleXForHole2 + weight / 2, data.props[1] * scaleYForHole2 + weight / 2]
        });
      })).map(function (p) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          props: [p.props[0], p.props[1] + topHeight * scaleYForHole2]
        });
      })],
      material: (0, _objectSpread2["default"])({}, frame.material),
      position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, frame.position), {}, {
        z: s14.position.z - lensDepth / 2 - weight + (lensDepth + weight) / 2
      })
    })
    /*{
    ...frame,
    depth: weight,
    position: {
        ...frame.position,
        z: s14.position.z - lensDepth / 2 - weight / 2
    }
    }*/
    ];
  }
  function getShiftXAndShiftYRotatedPoint(x, y, deg) {
    var _getRotationPoint = getRotationPoint(x, y, deg),
      x1 = _getRotationPoint.x1,
      y1 = _getRotationPoint.y1;
    return {
      shiftX: x1 - x,
      shiftY: y1 - y
    };
  }
  function generateTemples() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _props$start = props.start,
      start = _props$start === void 0 ? {
        position: {
          x: 8.16,
          y: 4.08,
          z: -1.2
        }
      } : _props$start,
      _props$end = props.end,
      end = _props$end === void 0 ? {
        position: {
          x: 7.92,
          y: 4.32,
          z: 10.8
        }
      } : _props$end,
      _props$disable = props.disable,
      disable = _props$disable === void 0 ? [] : _props$disable,
      _props$colorScheme = props.colorScheme,
      colorScheme = _props$colorScheme === void 0 ? {} : _props$colorScheme,
      _props$weight = props.weight,
      weight = _props$weight === void 0 ? 0.15 : _props$weight;
    var length = end.position.z - start.position.z;
    var randomTemplesFuncName = shuffleArray(["generateTemples2", "generateTemples3"].filter(function (name) {
      return disable.indexOf(name) === -1;
    }))[0];
    var _colorScheme$red = colorScheme.red,
      red = _colorScheme$red === void 0 ? "#de1305" : _colorScheme$red,
      _colorScheme$blue = colorScheme.blue,
      blue = _colorScheme$blue === void 0 ? "#426dfb" : _colorScheme$blue,
      _colorScheme$yellow = colorScheme.yellow,
      yellow = _colorScheme$yellow === void 0 ? randomTemplesFuncName === "generateTemples3" ? "#ccb817" : "#e7b508" : _colorScheme$yellow,
      _colorScheme$black = colorScheme.black,
      black = _colorScheme$black === void 0 ? "#000000" : _colorScheme$black,
      _colorScheme$white = colorScheme.white,
      white = _colorScheme$white === void 0 ? "#e7ddcc" : _colorScheme$white;
    var material = {
      color: white,
      transparent: true,
      opacity: 1
    };
    var templesHeight = props.templesHeight ? props.templesHeight : getRandomInt(3, 8) / 10;
    var temples = {
      generateTemples2: function generateTemples2() {
        var s1 = {
          width: weight / 2,
          height: templesHeight,
          depth: length / 12 * 5,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s1.position = {
          x: start.position.x + s1.width / 2 + weight,
          y: start.position.y - s1.height / 2,
          z: start.position.z + s1.depth / 2
        };
        var s2 = {
          width: weight,
          height: s1.height,
          depth: s1.height,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: yellow
          })
        };
        s2.position = {
          x: s1.position.x - s1.width / 2 - s2.width / 2,
          y: s1.position.y + s1.height / 2 - s2.height / 2,
          z: s1.position.z - s1.depth / 2 + s2.depth / 2
        };
        var gptWeight = s2.width / 2;
        var gptLength = weight <= 0.2 ? getRandomInt(8, 10) : getRandomInt(6, 10);
        var rightAngleWallOrTube = shuffleArray([generateRightAngleWall, generateRectangularTube])[0];
        var genPartTip = rightAngleWallOrTube({
          start: s2,
          end: {
            width: s2.width,
            height: s2.height * 1.5,
            depth: s2.depth,
            position: {
              x: start.position.x - length / 12,
              y: start.position.y - length / 6,
              z: start.position.z - length / 4
            },
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: yellow
            })
          },
          forces: {
            x: [1, 1],
            y: [1, 1]
          },
          weight: gptWeight,
          length: gptLength,
          enableAddStart: false,
          enableAddEnd: true,
          enableExtendDim: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, rightAngleWallOrTube === generateRectangularTube ? {
            x: true
          } : {}), {}, {
            y: true
          }),
          map: rightAngleWallOrTube === generateRectangularTube ? function (r) {
            r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: yellow
            });
            r.editLines = {
              disable: true
            };
            return [r];
          } : function (r, r2) {
            r2.forEach(function (r) {
              r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
                color: yellow
              });
              r.editLines = {
                disable: true
              };
            });
            return [r];
          }
        });
        var s3 = {
          width: weight / 2,
          height: s1.height + weight,
          depth: length / 12 * 5 - s2.depth,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s3.position = {
          x: s1.position.x - s1.width / 2 - s3.width / 2,
          y: s1.position.y + s1.height / 2 - s3.height / 2,
          z: s1.position.z - s1.depth / 2 + s3.depth / 2 + s2.depth
        };
        var s7 = {
          width: weight / 2,
          height: templesHeight,
          depth: s1.depth / 8 * 3 / 3 / 2 * 5 / 5 * 2,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s7.position = {
          x: end.position.x + s7.width / 2,
          y: end.position.y - s7.height / 2,
          z: end.position.z - s7.depth / 2
        };
        var s6 = {
          width: weight,
          height: templesHeight,
          depth: s7.depth / 2,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s6.position = {
          x: s7.position.x - s7.width / 2 - s6.width / 2,
          y: s7.position.y,
          z: s7.position.z - s6.depth / 2
        };
        var gpWeight = Math.min(s1.width + s3.width, s7.width + s6.width) / 2;
        var gpLength = getRandomInt(6, 10);
        var genPart = generateRightAngleWall({
          start: {
            width: s1.width + s3.width,
            depth: s1.depth / 6,
            height: s1.height * 2,
            position: {
              x: s1.position.x + s1.width / 2 - (s1.width + s3.width) / 2,
              y: s1.position.y + s1.height / 2 - s1.height * 2 / 2,
              z: s1.position.z + s1.depth / 2 + s1.depth / 6 / 2
            },
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: blue
            })
          },
          end: {
            width: s7.width + s6.width,
            height: s7.height,
            depth: s6.depth,
            position: {
              x: s7.position.x + s7.width / 2 - (s7.width + s6.width) / 2,
              y: s7.position.y + s7.height / 2 - s7.height / 2,
              z: s7.position.z - s7.depth / 2 - s6.depth / 2
            },
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: blue
            })
          },
          forces: {
            x: [1, 1],
            y: [1, 1]
          },
          weight: gpWeight,
          length: gpLength,
          enableAddStart: true,
          enableAddEnd: true,
          enableExtendDim: {
            y: true
          },
          map: function map(r, r2) {
            r2.forEach(function (r) {
              r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
                color: blue
              });
              r.editLines = {
                disable: true
              };
            });
            return [r];
          }
        });
        return {
          name: "L-shaped",
          tipLength: gptLength,
          middleLength: gpLength,
          calloutNotationN: 3 + genPartTip.length - 1 + Math.floor(genPart.length / 2),
          calloutNotationNTip: 2 + Math.floor(genPartTip.length / 2),
          tipName: rightAngleWallOrTube === generateRectangularTube ? "Boxes" : "L-shaped",
          templesHeight: templesHeight,
          d: [s1, s2].concat((0, _toConsumableArray2["default"])(genPartTip), [s3], (0, _toConsumableArray2["default"])(genPart), [s6, s7])
        };
      },
      generateTemples3: function generateTemples3() {
        var s1 = {
          width: weight / 2,
          height: templesHeight,
          depth: length / 12 * 5,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s1.position = {
          x: start.position.x + s1.width / 2 + weight,
          y: start.position.y - s1.height / 2,
          z: start.position.z + s1.depth / 2
        };
        var s2 = {
          width: weight,
          height: s1.height,
          depth: s1.height,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: yellow
          })
        };
        s2.position = {
          x: s1.position.x - s1.width / 2 - s2.width / 2,
          y: s1.position.y + s1.height / 2 - s2.height / 2,
          z: s1.position.z - s1.depth / 2 + s2.depth / 2
        };
        var gptLength = weight <= 0.2 ? getRandomInt(10, 14) : getRandomInt(8, 14);
        var rightAngleWallOrTube = shuffleArray([generateRightAngleWall, generateRectangularTube])[0];
        var genPartTip = rightAngleWallOrTube({
          start: s2,
          end: {
            width: s2.width,
            height: s2.height * 1.5,
            depth: s2.depth,
            position: {
              x: start.position.x - length / 12,
              y: start.position.y - length / 6,
              z: start.position.z - length / 4
            },
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: yellow
            })
          },
          forces: {
            x: [1, 1],
            y: [1, 1]
          },
          length: gptLength,
          enableAddStart: false,
          enableAddEnd: true,
          enableExtendDim: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, rightAngleWallOrTube === generateRectangularTube ? {
            x: true
          } : {}), {}, {
            y: true
          }),
          map: rightAngleWallOrTube === generateRectangularTube ? function (r) {
            r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: yellow
            });
            r.editLines = {
              disable: true
            };
            return [r];
          } : function (r, r2) {
            r2.forEach(function (r) {
              r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
                color: yellow
              });
              r.editLines = {
                disable: true
              };
            });
            return [r];
          }
        });
        var s3 = {
          width: weight / 2,
          height: s1.height + weight,
          depth: length / 12 * 5 - s2.depth,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s3.position = {
          x: s1.position.x - s1.width / 2 - s3.width / 2,
          y: s1.position.y + s1.height / 2 - s3.height / 2,
          z: s1.position.z - s1.depth / 2 + s3.depth / 2 + s2.depth
        };
        var s7 = {
          width: weight / 2,
          height: templesHeight,
          depth: s1.depth / 8 * 3 / 3 / 2 * 5 / 5 * 2,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s7.position = {
          x: end.position.x + s7.width / 2,
          y: end.position.y - s7.height / 2,
          z: end.position.z - s7.depth / 2
        };
        var s6 = {
          width: weight,
          height: templesHeight,
          depth: s7.depth / 2,
          material: (0, _objectSpread2["default"])({}, material)
        };
        s6.position = {
          x: s7.position.x - s7.width / 2 - s6.width / 2,
          y: s7.position.y,
          z: s7.position.z - s6.depth / 2
        };
        var gpLength = getRandomInt(6, 14);
        var genPart = generateRectangularTube({
          start: {
            width: s1.width + s3.width,
            depth: s1.depth / 6,
            height: s1.height * 2,
            position: {
              x: s1.position.x + s1.width / 2 - (s1.width + s3.width) / 2,
              y: s1.position.y + s1.height / 2 - s1.height * 2 / 2,
              z: s1.position.z + s1.depth / 2 + s1.depth / 6 / 2
            },
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: blue
            })
          },
          end: {
            width: s7.width + s6.width,
            height: s7.height,
            depth: s6.depth,
            position: {
              x: s7.position.x + s7.width / 2 - (s7.width + s6.width) / 2,
              y: s7.position.y + s7.height / 2 - s7.height / 2,
              z: s7.position.z - s7.depth / 2 - s6.depth / 2
            },
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: blue
            })
          },
          forces: {
            x: [1, 1],
            y: [1, 1]
          },
          length: gpLength,
          enableAddStart: true,
          enableAddEnd: true,
          enableExtendDim: {
            x: true,
            y: true
          },
          map: function map(r) {
            r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: blue
            });
            r.editLines = {
              disable: true
            };
            return [r];
          }
        });
        return {
          name: "Boxes",
          tipLength: gptLength,
          middleLength: gpLength,
          calloutNotationN: 3 + genPartTip.length - 1 + Math.floor(genPart.length / 2),
          calloutNotationNTip: 2 + Math.floor(genPartTip.length / 2),
          tipName: rightAngleWallOrTube === generateRectangularTube ? "Boxes" : "L-shaped",
          templesHeight: templesHeight,
          d: [s1, s2].concat((0, _toConsumableArray2["default"])(genPartTip), [s3], (0, _toConsumableArray2["default"])(genPart), [s6, s7])
        };
      }
    };
    return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, temples[randomTemplesFuncName]()), {}, {
      funcName: randomTemplesFuncName
    });
  }
  function generateLens() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var _props$weight2 = props.weight,
      weight = _props$weight2 === void 0 ? 0.15 : _props$weight2,
      start = props.start,
      _props$end2 = props.end,
      end = _props$end2 === void 0 ? {
        position: {
          x: 1.14 - weight / 2,
          y: 2.46,
          z: 12.3
        }
      } : _props$end2,
      _props$maxY = props.maxY,
      maxY = _props$maxY === void 0 ? 5.52 : _props$maxY,
      _props$minY = props.minY,
      minY = _props$minY === void 0 ? 1.08 : _props$minY,
      _props$disable2 = props.disable,
      disable = _props$disable2 === void 0 ? [] : _props$disable2,
      _props$colorScheme2 = props.colorScheme,
      colorScheme = _props$colorScheme2 === void 0 ? {} : _props$colorScheme2,
      startCutY = props.startCutY,
      _props$clampSize = props.clampSize,
      clampSize = _props$clampSize === void 0 ? 0.4 : _props$clampSize,
      addFrame = props.addFrame,
      frameOpacity = props.frameOpacity;
    var opacity = {
      opacity: 0.8
    };
    var lensType = props.lensType,
      _props$lensColor = props.lensColor,
      lensColor = _props$lensColor === void 0 ? colorScheme.lensColor : _props$lensColor,
      _props$lensOpacity = props.lensOpacity,
      lensOpacity = _props$lensOpacity === void 0 ? opacity.opacity : _props$lensOpacity,
      _props$glassesTypeLen = props.glassesTypeLensColor,
      glassesTypeLensColor = _props$glassesTypeLen === void 0 ? lensColor : _props$glassesTypeLen,
      _props$glassesTypeLen2 = props.glassesTypeLensOpacity,
      glassesTypeLensOpacity = _props$glassesTypeLen2 === void 0 ? lensOpacity : _props$glassesTypeLen2;

    //opacity.opacity = lensOpacity;

    var lensDepth = props.lensDepth ? props.lensDepth : addFrame ? getRandomInt(15, 30) / 100 : getRandomInt(2, 4) / 10;
    var randomLensFuncName = shuffleArray(["generateLens1", "generateLens2", "generateLens3"].filter(function (name) {
      return disable.indexOf(name) === -1;
    }))[0];
    var _colorScheme$red2 = colorScheme.red,
      red = _colorScheme$red2 === void 0 ? randomLensFuncName === "generateLens3" ? "#bf0103" : "#de1305" : _colorScheme$red2,
      _colorScheme$blue2 = colorScheme.blue,
      blue = _colorScheme$blue2 === void 0 ? "#426dfb" : _colorScheme$blue2,
      _colorScheme$yellow2 = colorScheme.yellow,
      yellow = _colorScheme$yellow2 === void 0 ? randomLensFuncName === "generateLens3" ? "#ccb817" : "#e7b508" : _colorScheme$yellow2,
      _colorScheme$black2 = colorScheme.black,
      black = _colorScheme$black2 === void 0 ? "#000000" : _colorScheme$black2,
      _colorScheme$white2 = colorScheme.white,
      white = _colorScheme$white2 === void 0 ? "#e7ddcc" : _colorScheme$white2;
    var material = {
      color: white,
      transparent: true,
      opacity: 1
    };
    var s8 = {
      width: weight * 2,
      height: start.height,
      depth: weight,
      material: (0, _objectSpread2["default"])({}, material)
    };
    s8.position = {
      x: start.position.x + start.width / 2 - s8.width / 2,
      y: start.position.y,
      z: start.position.z + start.depth / 2 + s8.depth / 2
    };
    var tolerance = 0.01;
    var maxLensHeight = maxY - minY;
    var fullWidth = s8.position.x + s8.width / 2 - end.position.x;
    var lensWidth = props.lensWidth || fullWidth / 5 * (getRandomInt(34, 46) / 10);
    var lensHeight = lensWidth / 7 * (getRandomInt(30, 34) / 10);
    lensHeight = lensHeight > maxLensHeight ? maxLensHeight : lensHeight;
    var defaultLensY = minY + (maxY - minY) / 2; //(s8.position.y + s8.height / 2) - lensHeight / 2;

    var padDepth = Math.abs((end.position.z - start.position.z) / 5);
    var lensZ = end.position.z + padDepth / 2 + lensDepth / 2;
    var lens = {
      generateLens1: function generateLens1() {
        var lensY = defaultLensY - (lensHeight / 2 + lensHeight / 2) < minY - tolerance ? defaultLensY + (minY - (defaultLensY - (lensHeight / 2 + lensHeight / 2))) : defaultLensY + (lensHeight / 2 + lensHeight / 8) > maxY + tolerance ? defaultLensY - (defaultLensY + (lensHeight / 2 + lensHeight / 8) - maxY) : defaultLensY;
        var s14 = {
          width: lensWidth,
          height: lensHeight,
          depth: lensDepth,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensType === "Glasses" ? glassesTypeLensColor || red : lensColor || red,
            opacity: lensType === "Glasses" ? glassesTypeLensOpacity : lensOpacity
          })
        };
        s14.position = {
          x: end.position.x + lensWidth / 2,
          y: lensY,
          z: lensZ
        };
        var s15 = {
          width: 0.15,
          height: s14.height / 3,
          depth: padDepth,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensType === "Glasses" ? glassesTypeLensColor || red : lensColor || red,
            opacity: lensType === "Glasses" ? glassesTypeLensOpacity : lensOpacity
          }),
          rotation: {
            x: 0,
            y: 0,
            z: 15 * Math.PI / 180
          }
        };
        s15.position = {
          x: end.position.x + s15.width / 2 - (addFrame ? weight * 2 : 0),
          y: end.position.y,
          z: end.position.z
        };
        var _generateEndPiece = generateEndPiece({
            s8: s8,
            s14: s14,
            material: material,
            lensColor: lensColor,
            red: red,
            yellow: yellow,
            opacity: opacity,
            weight: weight,
            clampSize: clampSize,
            addFrame: addFrame
          }),
          genPart = _generateEndPiece.genPart,
          gpLength = _generateEndPiece.gpLength,
          endPieceName = _generateEndPiece.endPieceName;
        var fullLength = genPart.length + 3;
        var defaultCutB = Math.tan(15 * Math.PI / 180) * (end.position.y - (s14.position.y - s14.height / 2));
        var cutB = startCutY ? Math.tan(15 * Math.PI / 180) * (startCutY - (s14.position.y - s14.height / 2)) : defaultCutB;
        var cutA = startCutY ? startCutY - (s14.position.y - s14.height / 2) : Math.tan((90 - 15) * Math.PI / 180) * defaultCutB;
        var cutCorner = lensWidth / 12;
        var topHeight = Math.max(cutCorner, lensHeight / 8);
        var lensGeometry = [{
          func: "moveTo",
          props: [0, 0]
        }, {
          func: "lineTo",
          props: [0, -topHeight + cutCorner]
        }, {
          func: "lineTo",
          props: [cutCorner, -topHeight]
        }, {
          func: "lineTo",
          props: [lensWidth - cutCorner, -topHeight]
        }, {
          func: "lineTo",
          props: [lensWidth, -topHeight + cutCorner]
        }, {
          func: "lineTo",
          props: [lensWidth, 0]
        }, {
          func: "lineTo",
          props: [lensWidth, lensHeight]
        }, {
          func: "lineTo",
          props: [lensWidth / 3 * 2, lensHeight + lensHeight / 2]
        }, {
          func: "lineTo",
          props: [lensWidth / 3, lensHeight + lensHeight / 2]
        }].concat((0, _toConsumableArray2["default"])(cutB > 0 ? [{
          func: "lineTo",
          props: [cutB, lensHeight]
        }] : [{
          func: "lineTo",
          props: [0, lensHeight]
        }]), (0, _toConsumableArray2["default"])(cutA > 0 ? [{
          func: "lineTo",
          props: [0, lensHeight - cutA]
        }] : []), [{
          func: "lineTo",
          props: [0, 0]
        }]);
        var frame = generateFrame({
          lensGeometry: lensGeometry,
          lensWidth: lensWidth,
          lensHeight: lensHeight * 1.5 + topHeight,
          lensDepth: lensDepth,
          clampSize: clampSize,
          weight: weight,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, genPart[1].material), lensType !== "Glasses" ? {
            color: white
          } : {}), {}, {
            opacity: frameOpacity || opacity.opacity
          }),
          s14: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
            position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
              y: lensY - (lensHeight * 0.5 + topHeight) / 2 + topHeight
            }),
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.material), {}, {
              depthWrite: false
            })
          }),
          topHeight: topHeight
        });
        return {
          lensForm: "Hexagon",
          lensColor: lensColor || red,
          lensWidth: lensWidth,
          lensDepth: lensDepth,
          lensOpacity: lensOpacity,
          frameOpacity: frameOpacity,
          glassesTypeLensColor: glassesTypeLensColor || red,
          glassesTypeLensOpacity: glassesTypeLensOpacity,
          calloutNotationN: fullLength - 2,
          padN: fullLength - 1,
          startCutY: s14.position.y - s14.height / 2 + cutA,
          endPieceName: endPieceName,
          endPieceLength: gpLength,
          clampSize: clampSize,
          calloutNotationNEndPieces: Math.floor(genPart.length / 2) + 1,
          frame: frame,
          d: [s8].concat((0, _toConsumableArray2["default"])(genPart), [(0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
            shadow: {
              disableReceiveShadow: true
            },
            height: lensHeight * 1.5 + topHeight,
            position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
              y: lensY - (lensHeight * 0.5 + topHeight) / 2 + topHeight
            }),
            geometryType: "extrude",
            geometryProps: [lensGeometry.map(function (p) {
              return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
                props: [p.props[0], p.props[1] + topHeight]
              });
            })]
          }), s15
          //s18
          ])
        };
      },

      generateLens2: function generateLens2() {
        var lensY = defaultLensY - lensHeight * 2 / 2 < minY - tolerance ? defaultLensY + (minY - (defaultLensY - lensHeight * 2 / 2)) : defaultLensY + lensHeight * 2 / 2 > maxY + tolerance ? defaultLensY - (defaultLensY + lensHeight * 2 / 2 - maxY) : defaultLensY;
        var s14 = {
          width: lensWidth,
          height: lensHeight,
          depth: lensDepth,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensType === "Glasses" ? glassesTypeLensColor || red : lensColor || red,
            opacity: lensType === "Glasses" ? glassesTypeLensOpacity : lensOpacity
          })
        };
        s14.position = {
          x: end.position.x + lensWidth / 2,
          y: lensY,
          z: lensZ
        };
        var s15 = {
          width: 0.15,
          height: s14.height / 3,
          depth: padDepth,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensType === "Glasses" ? glassesTypeLensColor || red : lensColor || red,
            opacity: lensType === "Glasses" ? glassesTypeLensOpacity : lensOpacity
          }),
          rotation: {
            x: 0,
            y: 0,
            z: 15 * Math.PI / 180
          }
        };
        s15.position = {
          x: end.position.x + s15.width / 2 - (addFrame ? weight * 2 : 0),
          y: end.position.y,
          z: end.position.z
        };
        var _generateEndPiece2 = generateEndPiece({
            s8: s8,
            s14: s14,
            material: material,
            lensColor: lensColor,
            red: red,
            yellow: yellow,
            opacity: opacity,
            weight: weight,
            clampSize: clampSize,
            addFrame: addFrame
          }),
          genPart = _generateEndPiece2.genPart,
          gpLength = _generateEndPiece2.gpLength,
          endPieceName = _generateEndPiece2.endPieceName;
        var fullLength = genPart.length + 3;
        var defaultCutB = Math.tan(15 * Math.PI / 180) * (end.position.y - (s14.position.y - s14.height / 2));
        var cutB = startCutY ? Math.tan(15 * Math.PI / 180) * (startCutY - (s14.position.y - s14.height / 2)) : defaultCutB;
        var cutA = startCutY ? startCutY - (s14.position.y - s14.height / 2) : Math.tan((90 - 15) * Math.PI / 180) * defaultCutB;
        var lensGeometry = [{
          func: "moveTo",
          props: [0, 0]
        }, {
          func: "lineTo",
          props: [lensWidth / 3, -lensHeight / 2]
        }, {
          func: "lineTo",
          props: [lensWidth / 3 * 2, -lensHeight / 2]
        }, {
          func: "lineTo",
          props: [lensWidth, 0]
        }, {
          func: "lineTo",
          props: [lensWidth, lensHeight]
        }, {
          func: "lineTo",
          props: [lensWidth / 3 * 2, lensHeight + lensHeight / 2]
        }, {
          func: "lineTo",
          props: [lensWidth / 3, lensHeight + lensHeight / 2]
        }].concat((0, _toConsumableArray2["default"])(cutB > 0 ? [{
          func: "lineTo",
          props: [cutB, lensHeight]
        }] : [{
          func: "lineTo",
          props: [0, lensHeight]
        }]), (0, _toConsumableArray2["default"])(cutA > 0 ? [{
          func: "lineTo",
          props: [0, lensHeight - cutA]
        }] : []), [{
          func: "lineTo",
          props: [0, 0]
        }]);
        var frame = generateFrame({
          lensGeometry: lensGeometry,
          lensWidth: lensWidth,
          lensHeight: lensHeight * 2,
          lensDepth: lensDepth,
          clampSize: clampSize,
          weight: weight,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, genPart[1].material), lensType !== "Glasses" ? {
            color: white
          } : {}), {}, {
            opacity: frameOpacity || opacity.opacity
          }),
          s14: s14
        });
        return {
          lensForm: "Octagon",
          lensColor: lensColor || red,
          lensWidth: lensWidth,
          lensDepth: lensDepth,
          lensOpacity: lensOpacity,
          frameOpacity: frameOpacity,
          glassesTypeLensColor: glassesTypeLensColor || red,
          glassesTypeLensOpacity: glassesTypeLensOpacity,
          calloutNotationN: fullLength - 2,
          padN: fullLength - 1,
          startCutY: s14.position.y - s14.height / 2 + cutA,
          endPieceName: endPieceName,
          endPieceLength: gpLength,
          clampSize: clampSize,
          calloutNotationNEndPieces: Math.floor(genPart.length / 2) + 1,
          frame: frame,
          d: [s8].concat((0, _toConsumableArray2["default"])(genPart), [(0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
            shadow: {
              disableReceiveShadow: true
            },
            height: lensHeight * 2,
            geometryType: "extrude",
            geometryProps: [lensGeometry.map(function (p) {
              return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
                props: [p.props[0], p.props[1] + lensHeight / 2]
              });
            })],
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.material), {}, {
              depthWrite: false
            })
          }), s15])
        };
      },
      generateLens3: function generateLens3() {
        var cutCorner = lensWidth / 12;
        var topHeight = Math.max(cutCorner, lensHeight / 8);
        var bottomHeight = topHeight;
        var lensY = defaultLensY - (lensHeight / 2 + bottomHeight) < minY - tolerance ? defaultLensY + (minY - (defaultLensY - (lensHeight / 2 + bottomHeight))) : defaultLensY + (lensHeight / 2 + topHeight) > maxY + tolerance ? defaultLensY - (defaultLensY + (lensHeight / 2 + topHeight) - maxY) : defaultLensY;
        var s14 = {
          width: lensWidth,
          height: lensHeight,
          depth: lensDepth,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensType === "Glasses" ? glassesTypeLensColor || red : lensColor || red,
            opacity: lensType === "Glasses" ? glassesTypeLensOpacity : lensOpacity
          })
        };
        s14.position = {
          x: end.position.x + lensWidth / 2,
          y: lensY,
          z: lensZ
        };
        var s15 = {
          width: 0.15,
          height: s14.height / 3,
          depth: padDepth,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
            color: lensType === "Glasses" ? glassesTypeLensColor || red : lensColor || red,
            opacity: lensType === "Glasses" ? glassesTypeLensOpacity : lensOpacity
          }),
          rotation: {
            x: 0,
            y: 0,
            z: 15 * Math.PI / 180
          }
        };
        s15.position = {
          x: end.position.x + s15.width / 2 - (addFrame ? weight * 2 : 0),
          y: end.position.y,
          z: end.position.z
        };
        var _generateEndPiece3 = generateEndPiece({
            s8: s8,
            s14: s14,
            material: material,
            lensColor: lensColor,
            red: red,
            yellow: yellow,
            opacity: opacity,
            weight: weight,
            clampSize: clampSize,
            addFrame: addFrame
          }),
          genPart = _generateEndPiece3.genPart,
          gpLength = _generateEndPiece3.gpLength,
          endPieceName = _generateEndPiece3.endPieceName;
        var fullLength = genPart.length + 3;
        var defaultCutB = Math.tan(15 * Math.PI / 180) * (end.position.y - (s14.position.y - s14.height / 2));
        var cutB = startCutY ? Math.tan(15 * Math.PI / 180) * (startCutY - (s14.position.y - s14.height / 2)) : defaultCutB;
        var cutA = startCutY ? startCutY - (s14.position.y - s14.height / 2) : Math.tan((90 - 15) * Math.PI / 180) * defaultCutB;
        var lensGeometry = [{
          func: "moveTo",
          props: [0, 0]
        }, {
          func: "lineTo",
          props: [0, -topHeight + cutCorner]
        }, {
          func: "lineTo",
          props: [cutCorner, -topHeight]
        }, {
          func: "lineTo",
          props: [lensWidth - cutCorner, -topHeight]
        }, {
          func: "lineTo",
          props: [lensWidth, -topHeight + cutCorner]
        }, {
          func: "lineTo",
          props: [lensWidth, 0]
        }, {
          func: "lineTo",
          props: [lensWidth, lensHeight]
        }, {
          func: "lineTo",
          props: [lensWidth, lensHeight + bottomHeight - cutCorner]
        }, {
          func: "lineTo",
          props: [lensWidth - cutCorner, lensHeight + bottomHeight]
        }, {
          func: "lineTo",
          props: [cutCorner, lensHeight + bottomHeight]
        }].concat((0, _toConsumableArray2["default"])(cutB > 0 ? [{
          func: "lineTo",
          props: [cutB, lensHeight]
        }] : [{
          func: "lineTo",
          props: [0, lensHeight]
        }]), (0, _toConsumableArray2["default"])(cutA > 0 ? [{
          func: "lineTo",
          props: [0, lensHeight - cutA]
        }] : []), [{
          func: "lineTo",
          props: [0, 0]
        }]);
        var frame = generateFrame({
          lensGeometry: lensGeometry,
          lensWidth: lensWidth,
          lensHeight: lensHeight + (topHeight + bottomHeight),
          lensDepth: lensDepth,
          clampSize: clampSize,
          weight: weight,
          material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, genPart[1].material), lensType !== "Glasses" ? {
            color: white
          } : {}), {}, {
            opacity: frameOpacity || opacity.opacity
          }),
          s14: s14,
          topHeight: topHeight
        });
        return {
          lensForm: "Rectangle",
          lensColor: lensColor || red,
          lensWidth: lensWidth,
          lensDepth: lensDepth,
          lensOpacity: lensOpacity,
          frameOpacity: frameOpacity,
          glassesTypeLensColor: glassesTypeLensColor || red,
          glassesTypeLensOpacity: glassesTypeLensOpacity,
          calloutNotationN: fullLength - 2,
          padN: fullLength - 1,
          startCutY: s14.position.y - s14.height / 2 + cutA,
          endPieceName: endPieceName,
          endPieceLength: gpLength,
          clampSize: clampSize,
          calloutNotationNEndPieces: Math.floor(genPart.length / 2) + 1,
          frame: frame,
          d: [s8].concat((0, _toConsumableArray2["default"])(genPart), [(0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14), {}, {
            shadow: {
              disableReceiveShadow: true
            },
            height: lensHeight + (topHeight + bottomHeight),
            position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.position), {}, {
              y: lensY - (topHeight + bottomHeight) / 2 + topHeight
            }),
            geometryType: "extrude",
            geometryProps: [lensGeometry.map(function (p) {
              return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
                props: [p.props[0], p.props[1] + topHeight]
              });
            })],
            material: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s14.material), {}, {
              depthWrite: false
            })
          }), s15
          //s18
          ])
        };
      }
    };

    return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lens[randomLensFuncName]()), {}, {
      funcName: randomLensFuncName
    });
  }
  function generateBridge() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var start = props.start,
      end = props.end,
      _props$disable3 = props.disable,
      disable = _props$disable3 === void 0 ? [] : _props$disable3,
      _props$colorScheme3 = props.colorScheme,
      colorScheme = _props$colorScheme3 === void 0 ? {} : _props$colorScheme3,
      _props$weight3 = props.weight,
      weight = _props$weight3 === void 0 ? 0.15 : _props$weight3,
      _props$lensDepth = props.lensDepth,
      lensDepth = _props$lensDepth === void 0 ? weight : _props$lensDepth,
      addFrame = props.addFrame;
    var randomBridgeFuncName = shuffleArray(["generateBridge2", "generateBridge3"].filter(function (name) {
      return disable.indexOf(name) === -1;
    }))[0];
    var _colorScheme$red3 = colorScheme.red,
      red = _colorScheme$red3 === void 0 ? randomBridgeFuncName === "generateBridge3" ? "#bf0103" : "#de1305" : _colorScheme$red3,
      _colorScheme$blue3 = colorScheme.blue,
      blue = _colorScheme$blue3 === void 0 ? "#426dfb" : _colorScheme$blue3,
      _colorScheme$yellow3 = colorScheme.yellow,
      yellow = _colorScheme$yellow3 === void 0 ? randomBridgeFuncName === "generateBridge3" ? "#ccb817" : "#e7b508" : _colorScheme$yellow3,
      _colorScheme$black3 = colorScheme.black,
      black = _colorScheme$black3 === void 0 ? "#000000" : _colorScheme$black3,
      _colorScheme$white3 = colorScheme.white,
      white = _colorScheme$white3 === void 0 ? "#e7ddcc" : _colorScheme$white3;
    var material = {
      color: white,
      transparent: true,
      opacity: 1
    };
    var opacity = {
      opacity: 0.8
    };
    material.color = black;
    var _getShiftXAndShiftYRo = getShiftXAndShiftYRotatedPoint(-start.width / 2, start.height / 2, start.rotation.z),
      shiftX = _getShiftXAndShiftYRo.shiftX,
      shiftY = _getShiftXAndShiftYRo.shiftY;
    var width = start.position.x + start.width / 2 + shiftX - (end.position.x - end.width / 2 - shiftX);
    var clampSize = props.clampSize || width / 4;
    var s16 = {
      width: width + (addFrame ? 0 : clampSize * 2) + weight,
      height: clampSize,
      depth: weight,
      material: (0, _objectSpread2["default"])({}, material)
    };
    s16.position = {
      x: start.position.x + start.width / 2 + shiftX - width / 2,
      y: Math.min(start.position.y + start.height / 2 + s16.height / 2, end.position.y + end.height / 2 + s16.height / 2) + start.height / 2,
      z: start.position.z + start.depth / 2 - s16.depth / 2
    };
    var s17 = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s16), {}, {
      width: width + (addFrame ? weight : 0),
      depth: lensDepth,
      position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s16.position), {}, {
        z: s16.position.z + s16.depth / 2 + lensDepth / 2
      })
    });
    var s18 = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s16), {}, {
      depth: weight / 2,
      position: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s16.position), {}, {
        z: s17.position.z + s17.depth / 2 + weight / 2
      })
    });
    var bridge = {
      generateBridge2: function generateBridge2() {
        var gpWeight = weight * 2;
        var gpLength = getRandomInt(3, 5);
        var genPart = generateRightAngleWall({
          start: {
            width: start.width,
            height: weight,
            depth: start.depth,
            position: {
              x: start.position.x + start.width / 2 + shiftX - start.width / 2,
              y: Math.min(start.position.y + start.height / 2 + weight / 2, end.position.y + end.height / 2 + weight / 2) + shiftY,
              z: start.position.z - 0.001
            },
            material: (0, _objectSpread2["default"])({}, material)
          },
          end: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s16), {}, {
            width: weight,
            position: (0, _objectSpread2["default"])({}, s16.position)
          }),
          forces: {
            x: [1, 1]
          },
          length: gpLength,
          weight: gpWeight,
          enableAddStart: true,
          enableAddEnd: false,
          enableExtendDim: {
            z: true
          },
          map: function map(r, r2) {
            r2.forEach(function (r) {
              r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
                color: black
              });
              r.editLines = {
                disable: true
              };
            });
            return [r];
          }
        });
        return {
          name: "L-shaped",
          genPartLength: gpLength * 2,
          calloutNotationN: genPart.length,
          d: [].concat((0, _toConsumableArray2["default"])(genPart), [s16, s17, s18], (0, _toConsumableArray2["default"])(genPart.reduce(function (a, object) {
            a.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, object), {}, {
              position: {
                x: object.position.x * -1,
                y: object.position.y,
                z: object.position.z
              }
            }));
            return a;
          }, [])))
        };
      },
      generateBridge3: function generateBridge3() {
        var gpWeight = weight * 2;
        var gpLength = weight <= 2 ? getRandomInt(4, 5) : getRandomInt(3, 5);
        var genPart = generateRectangularTube({
          start: {
            width: start.width,
            height: weight,
            depth: start.depth,
            position: {
              x: start.position.x + start.width / 2 + shiftX - start.width / 2,
              y: Math.min(start.position.y + start.height / 2 + weight / 2, end.position.y + end.height / 2 + weight / 2) + shiftY,
              z: start.position.z - 0.001
            },
            material: (0, _objectSpread2["default"])({}, material)
          },
          end: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, s16), {}, {
            width: weight,
            position: (0, _objectSpread2["default"])({}, s16.position)
          }),
          forces: {
            x: [1, 1]
          },
          length: gpLength,
          weight: gpWeight,
          enableAddStart: true,
          enableAddEnd: false,
          enableExtendDim: {
            z: true
          },
          map: function map(r) {
            r.material = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, material), {}, {
              color: black
            });
            r.editLines = {
              disable: true
            };
            return [r];
          }
        });
        return {
          name: "Boxes",
          genPartLength: gpLength * 2,
          calloutNotationN: genPart.length,
          d: [].concat((0, _toConsumableArray2["default"])(genPart), [s16, s17, s18], (0, _toConsumableArray2["default"])(genPart.reduce(function (a, object) {
            a.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, object), {}, {
              position: {
                x: object.position.x * -1,
                y: object.position.y,
                z: object.position.z
              }
            }));
            return a;
          }, [])))
        };
      }
    };
    return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, bridge[randomBridgeFuncName]()), {}, {
      funcName: randomBridgeFuncName
    });
  }
  function generateGlasses(_ref10) {
    var colorScheme = _ref10.colorScheme;
    var symmetry = shuffleArray([true, true, true, true, false])[0];
    var addFrame = shuffleArray([true, false])[0];
    var weight = shuffleArray([0.1, 0.15, 0.2].concat((0, _toConsumableArray2["default"])(addFrame ? [] : [0.25, 0.3])))[0];
    var clampSize = shuffleArray([0.1, 0.2, 0.3])[0];
    var templesDepth = getRandomInt(9.6, 10.8);
    var frameOpacity = shuffleArray([0.5, 1])[0];
    var templesStartPoint = {
      position: {
        x: 6.56,
        y: 4.08,
        z: -templesDepth / 2
      }
    };
    var templesEndPoint = {
      position: {
        x: 6.32,
        y: getRandomInt(3.46, 5.12),
        z: templesDepth / 2
      }
    };
    var lensEndPoint = {
      position: {
        x: shuffleArray([0.75, 0.8, 0.85])[0] + (addFrame ? weight : 0),
        y: 2.7,
        z: 11.3 - templesDepth / 2
      }
    };
    var templesLeft = generateTemples({
      start: templesStartPoint,
      end: templesEndPoint,
      colorScheme: colorScheme,
      weight: weight
    });
    var templesRight = symmetry ? (0, _utils.copyObject)(templesLeft) : generateTemples({
      disable: [templesLeft.funcName],
      start: templesStartPoint,
      end: templesEndPoint,
      colorScheme: colorScheme,
      weight: weight,
      templesHeight: templesLeft.templesHeight
    });
    var lensType = shuffleArray(["Sunglasses", "Glasses"])[0];
    var lensLeft = generateLens({
      lensType: lensType,
      lensColor: colorScheme.lensColor,
      lensOpacity: 0.7,
      frameOpacity: frameOpacity,
      glassesTypeLensColor: "#c6e3e3",
      glassesTypeLensOpacity: 0.2,
      start: templesLeft.d[templesLeft.d.length - 1],
      end: lensEndPoint,
      colorScheme: colorScheme,
      weight: weight,
      clampSize: clampSize,
      addFrame: addFrame
    });
    var lensRight = symmetry ? (0, _utils.copyObject)(lensLeft) : generateLens({
      lensType: lensType,
      lensColor: lensLeft.lensColor,
      lensOpacity: lensLeft.lensOpacity,
      frameOpacity: lensLeft.frameOpacity,
      lensWidth: lensLeft.lensWidth,
      lensDepth: lensLeft.lensDepth,
      glassesTypeLensColor: lensLeft.glassesTypeLensColor,
      glassesTypeLensOpacity: lensLeft.glassesTypeLensOpacity,
      startCutY: lensLeft.startCutY,
      disable: [lensLeft.funcName],
      start: templesRight.d[templesRight.d.length - 1],
      end: lensEndPoint,
      colorScheme: colorScheme,
      weight: weight,
      clampSize: clampSize,
      addFrame: addFrame
    });
    function mirror(o) {
      return o.reduce(function (a, object) {
        if (!object._removeSymmetry) {
          var _object$rotation, _object$rotation2, _object$rotation3;
          a.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, object), {}, {
            position: {
              x: object.position.x * -1,
              y: object.position.y,
              z: object.position.z
            },
            rotation: {
              x: (_object$rotation = object.rotation) !== null && _object$rotation !== void 0 && _object$rotation.x ? -object.rotation.x : 0,
              y: (_object$rotation2 = object.rotation) !== null && _object$rotation2 !== void 0 && _object$rotation2.y ? -object.rotation.y : 0,
              z: (_object$rotation3 = object.rotation) !== null && _object$rotation3 !== void 0 && _object$rotation3.z ? -object.rotation.z : 0
            }
          }, object.geometryType === "extrude" && object.geometryProps ? {
            geometryProps: object.geometryProps.map(function (props, i) {
              if (i === 0 && props.length || i === 1 && props.length) {
                props.forEach(function (shapeAction) {
                  if (shapeAction.props && typeof shapeAction.props[0] === "number") {
                    shapeAction.props[0] = shapeAction.props[0] * -1 + object.width;
                  }
                });
              }
              return props;
            })
          } : {}));
        }
        return a;
      }, []);
    }
    lensRight.d = mirror(lensRight.d);
    templesRight.d = mirror(templesRight.d);
    var bridge = generateBridge({
      start: lensLeft.d[lensLeft.padN],
      end: lensRight.d[lensRight.padN],
      clampSize: lensLeft.clampSize,
      colorScheme: colorScheme,
      weight: weight,
      lensDepth: lensLeft.lensDepth,
      addFrame: addFrame
    });
    var lensLeftObject = lensLeft.d[typeof lensLeft.calloutNotationN === "number" ? lensLeft.calloutNotationN : Math.floor(lensLeft.d.length / 2) + 1];
    var lensRightObject = lensRight.d[typeof lensRight.calloutNotationN === "number" ? lensRight.calloutNotationN : Math.floor(lensRight.d.length / 2) + 1];
    var groups = {
      templesLeft: {
        enable: true
      },
      templesRight: {
        enable: true
      },
      frame: {
        enable: true
      },
      lens: {
        enable: true
      }
    };
    templesLeft.d.forEach(function (data) {
      data.group = "templesLeft";
    });
    templesRight.d.forEach(function (data) {
      data.group = "templesRight";
    });
    lensLeftObject.group = "lens";
    lensRightObject.group = "lens";
    var glassesD = [].concat((0, _toConsumableArray2["default"])(templesLeft.d), (0, _toConsumableArray2["default"])(lensLeft.d), (0, _toConsumableArray2["default"])(addFrame && lensLeft.frame ? (0, _toConsumableArray2["default"])(lensLeft.frame) : []), (0, _toConsumableArray2["default"])(bridge.d), (0, _toConsumableArray2["default"])(lensRight.d), (0, _toConsumableArray2["default"])(addFrame && lensRight.frame ? mirror((0, _toConsumableArray2["default"])(lensRight.frame)) : []), (0, _toConsumableArray2["default"])(templesRight.d));
    glassesD.forEach(function (data) {
      if (!data.group) {
        data.group = "frame";
      }
    });
    function setNewMinMax(_ref11) {
      var _ref11$mm = _ref11.mm,
        mm = _ref11$mm === void 0 ? {} : _ref11$mm,
        object = _ref11.object,
        p = _ref11.p,
        d = _ref11.d,
        callback = _ref11.callback;
      var min = object.position[p] - object[d] / 2;
      var max = object.position[p] + object[d] / 2;
      if (typeof mm["min" + p] === "undefined" || mm["min" + p] > min) {
        mm["min" + p] = min;
        if (callback) {
          callback({
            type: "min",
            object: object
          });
        }
      }
      if (typeof mm["max" + p] === "undefined" || mm["max" + p] < max) {
        mm["max" + p] = max;
        if (callback) {
          callback({
            type: "max",
            object: object
          });
        }
      }
    }
    var generatedMinMax = {};
    glassesD.forEach(function (object, i) {
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    var bridgeNullPoint = bridge.d[bridge.calloutNotationN].position.y - bridge.d[bridge.calloutNotationN].height / 2;
    var shiftYForCMinY = -bridgeNullPoint;
    delete generatedMinMax.miny;
    delete generatedMinMax.maxy;
    glassesD.forEach(function (object) {
      object.position.y = object.position.y + shiftYForCMinY;
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "y",
        d: "height"
      });
    });
    var minMaxLens = {};
    var minLens = null;
    [].concat((0, _toConsumableArray2["default"])(lensLeft.d), (0, _toConsumableArray2["default"])(lensRight.d)).forEach(function (object) {
      setNewMinMax({
        mm: minMaxLens,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: minMaxLens,
        object: object,
        p: "y",
        d: "height",
        callback: function callback(_ref12) {
          var type = _ref12.type,
            object = _ref12.object;
          if (type === "min") {
            minLens = object;
          }
        }
      });
      setNewMinMax({
        mm: minMaxLens,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    var minMaxTemples = {};
    var minTemples = null;
    [].concat((0, _toConsumableArray2["default"])(templesLeft.d), (0, _toConsumableArray2["default"])(templesRight.d)).forEach(function (object) {
      setNewMinMax({
        mm: minMaxTemples,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: minMaxTemples,
        object: object,
        p: "y",
        d: "height",
        callback: function callback(_ref13) {
          var type = _ref13.type,
            object = _ref13.object;
          if (type === "min") {
            minTemples = object;
          }
        }
      });
      setNewMinMax({
        mm: minMaxTemples,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    var addForMainCalloutNotation = [];
    var font = shuffleArray(["/assets/fonts/Permanent-Marker_Regular.typeface.json", "/assets/fonts/Kalam_Regular.typeface.json"])[0];
    var defaultCalloutNotationProps = {
      font: font,
      size: font.match("Kalam") ? 0.42 : 0.36,
      color: colorScheme.borderAndFont,
      opacity: font.match("Kalam") ? 0.85 : 0.7,
      lineProps: {
        opacity: 0.85,
        color: colorScheme.borderAndFont,
        linewidth: getRandomInt(6, 10) / 10
      }
    };
    var defaultCalloutNotationObjectProps = {
      width: 0.02,
      height: 0.03,
      depth: 0.01,
      material: {
        opacity: 0
      },
      lineProps: {
        disable: true
      }
    };
    addForMainCalloutNotation.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, templesLeft.d[typeof templesLeft.calloutNotationN === "number" ? templesLeft.calloutNotationN : Math.floor(templesLeft.d.length / 2) + 1]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: templesLeft.name + " " + templesLeft.middleLength,
        position: {
          x: 1.2,
          y: 3.6,
          z: 0
        },
        boundingX: "left"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, templesLeft.d[typeof templesLeft.calloutNotationNTip === "number" ? templesLeft.calloutNotationNTip : Math.floor(templesLeft.d.length / 3) + 1]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: templesLeft.tipName + " " + templesLeft.tipLength,
        position: {
          x: 1.2,
          y: 3.6,
          z: 0
        },
        boundingX: "left"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, templesRight.d[typeof templesRight.calloutNotationN === "number" ? templesRight.calloutNotationN : Math.floor(templesRight.d.length / 2) + 1]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: templesRight.name + " " + templesRight.middleLength,
        position: {
          x: -1.2,
          y: 3.6,
          z: 0
        },
        boundingX: "right"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, templesRight.d[typeof templesRight.calloutNotationNTip === "number" ? templesRight.calloutNotationNTip : Math.floor(templesRight.d.length / 3) + 1]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: templesRight.tipName + " " + templesRight.tipLength,
        position: {
          x: -1.2,
          y: 3.6,
          z: 0
        },
        boundingX: "right"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lensLeft.d[typeof lensLeft.calloutNotationNEndPieces === "number" ? lensLeft.calloutNotationNEndPieces : 2]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: lensLeft.endPieceName + " " + lensLeft.endPieceLength,
        position: {
          x: 3.6,
          y: -4.8,
          z: 0
        },
        boundingX: "left"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lensLeft.d[typeof lensLeft.calloutNotationN === "number" ? lensLeft.calloutNotationN : Math.floor(lensLeft.d.length / 2) + 1]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: lensLeft.lensForm,
        position: {
          x: 2.4,
          y: -6,
          z: 2.4
        },
        boundingX: "left"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lensRight.d[typeof lensRight.calloutNotationNEndPieces === "number" ? lensRight.calloutNotationNEndPieces : 2]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: lensRight.endPieceName + " " + lensRight.endPieceLength,
        position: {
          x: -3.6,
          y: -4.8,
          z: 0
        },
        boundingX: "right"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lensRight.d[typeof lensRight.calloutNotationN === "number" ? lensRight.calloutNotationN : Math.floor(lensRight.d.length / 2) + 1]), defaultCalloutNotationObjectProps), {}, {
      calloutNotation: (0, _objectSpread2["default"])({
        text: lensRight.lensForm,
        position: {
          x: -2.4,
          y: -6,
          z: 2.4
        },
        boundingX: "right"
      }, defaultCalloutNotationProps)
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, bridge.d[typeof bridge.calloutNotationN === "number" ? bridge.calloutNotationN : Math.floor(bridge.d.length / 2) + 1]), defaultCalloutNotationObjectProps), {}, {
      width: 0.03,
      height: 0.01,
      depth: 0.02,
      calloutNotation: (0, _objectSpread2["default"])({
        text: bridge.name + " " + bridge.genPartLength,
        position: {
          x: 0,
          y: -7.2,
          z: 3.6
        },
        boundingX: "center"
      }, defaultCalloutNotationProps)
    }));
    var lensTypeCalloutY = generatedMinMax.maxy + 1;
    addForMainCalloutNotation.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lensLeftObject), defaultCalloutNotationObjectProps), {}, {
      width: 0.03,
      height: 0.01,
      depth: 0.02,
      position: (0, _objectSpread2["default"])({}, lensLeftObject.position),
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: (lensType === "Glasses" ? "Transparent" : "Colorized") + " Lens",
        position: {
          x: -lensLeftObject.position.x,
          y: lensTypeCalloutY - lensLeftObject.position.y,
          z: 0
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        },
        disableLookAtCamera: true
      }),
      lineProps: {
        disable: true
      }
    }));
    addForMainCalloutNotation.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, lensRightObject), defaultCalloutNotationObjectProps), {}, {
      position: (0, _objectSpread2["default"])({}, lensRightObject.position),
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        position: {
          x: -lensRightObject.position.x,
          y: lensTypeCalloutY - lensRightObject.position.y,
          z: 0
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        },
        disableLookAtCamera: true
      }),
      lineProps: {
        disable: true
      }
    }));
    var b = minTemples.position.y - minTemples.height / 2 - (minLens.position.y - minLens.height / 2);
    var a = minLens.position.z + minLens.depth / 2 - (minTemples.position.z - minTemples.depth / 2);
    var rad = Math.atan(b / a);
    var fullWidth = generatedMinMax.maxx - generatedMinMax.minx;
    var fullDepth = generatedMinMax.maxz - generatedMinMax.minz;
    var fullHeight = generatedMinMax.maxy - generatedMinMax.miny;
    var shiftY = Math.tan(rad) * (minLens.position.z + minLens.depth / 2);
    var shiftZ = (generatedMinMax.maxz + generatedMinMax.minz) / 2;
    var shiftX = (generatedMinMax.maxx + generatedMinMax.minx) / 2;
    var scaleForCM = 1;
    var cm = " cm";
    var roundDepthCm = Math.round((generatedMinMax.maxz - generatedMinMax.minz) * scaleForCM * 10) / 10;
    var templesDepthCm = Math.round((templesEndPoint.position.z - generatedMinMax.minz) * scaleForCM * 10) / 10;
    var endItemDepthCm = Math.round((roundDepthCm - templesDepthCm) * 10) / 10;
    var depthText = roundDepthCm + cm;
    var templesDepthText = templesDepthCm + cm;
    var endItemDepthText = endItemDepthCm + cm;
    var roundWidthCm = Math.round((generatedMinMax.maxx - generatedMinMax.minx) * scaleForCM * 10) / 10;
    var widthText = roundWidthCm + cm;
    var leftLensWidth = Math.round((lensLeftObject.position.x + lensLeftObject.width / 2 - lensEndPoint.position.x) * scaleForCM * 10) / 10;
    var bridgeWidth = Math.round((lensEndPoint.position.x - -lensEndPoint.position.x - (addFrame ? weight * 2 : 0)) * scaleForCM * 10) / 10;
    //const bridgeWidthByLens = (lensLeftObject.position.x - lensLeftObject.width / 2) * 2;
    //const bridgetWidthByFrame = (lensLeft.frame.position.x - lensLeft.frame.width / 2) * 2;

    var rightLensWidth = Math.round((-lensEndPoint.position.x - (lensRightObject.position.x - lensRightObject.width / 2)) * scaleForCM * 10) / 10;
    var leftLensWidthText = leftLensWidth + cm;
    var leftLensWidthTextMM = leftLensWidth * 10 + "";
    var bridgeWidthText = bridgeWidth + cm;
    var rightLensWidthText = rightLensWidth + cm;
    var rightLensWidthTextMM = rightLensWidth * 10 + "";
    var leftLensHeight = Math.round(lensLeftObject.height * scaleForCM * 10) / 10;
    var leftLensHeightText = leftLensHeight + cm;
    var leftLensHeightTextMM = leftLensHeight * 10 + "";
    var rightLensHeight = Math.round(lensRightObject.height * scaleForCM * 10) / 10;
    var rightLensHeightText = rightLensHeight + cm;
    var rightLensHeightTextMM = rightLensHeight * 10 + "";
    var lensDepthTextMM = Math.round(lensLeft.lensDepth * scaleForCM * 100 * 10) / 100 + "";
    var clampSizeTextMM = Math.round(clampSize * scaleForCM * 100 * 10) / 100 + "";
    var weightTextMM = Math.round(weight * scaleForCM * 100 * 10) / 100 + "";
    var templesHeightTextMM = Math.round(templesLeft.templesHeight * scaleForCM * 100 * 10) / 100 + "";
    addForMainCalloutNotation.push((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      width: 0.03,
      height: 0.02,
      depth: 0.01,
      position: {
        x: shiftX,
        y: minLens.position.y - minLens.height / 2 + shiftY + fullHeight / 2,
        z: shiftZ + fullDepth
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: symmetry ? "Symmetrical" : "Asymmetrical",
        position: {
          x: 0,
          y: 0,
          z: -fullDepth * 2
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          arrowEndOnMain: 0.98,
          disableFrenet: true,
          forceArrowBase: true,
          startPoint: 0.05,
          endPoint: 1,
          opacity: 0.6
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 8,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        disableLookAtCamera: true,
        position: {
          x: 0,
          y: 0,
          z: -fullDepth
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          opacity: 0.6,
          isDimensionLine: true,
          dimensionLinePoints: [{
            position: {
              x: 0,
              y: 0,
              z: templesEndPoint.position.z - generatedMinMax.minz
            }
          }]
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 8,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: templesDepthText,
        disableLookAtCamera: true,
        position: {
          x: -fullWidth / 48,
          y: 0,
          z: -fullDepth + (templesEndPoint.position.z - generatedMinMax.minz) / 2
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 90 * Math.PI / 180
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 8,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: endItemDepthText,
        disableLookAtCamera: true,
        position: {
          x: -fullWidth / 48,
          y: 0,
          z: -(generatedMinMax.maxz - templesEndPoint.position.z) / 2
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 90 * Math.PI / 180
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 8 + fullWidth / 16,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        disableLookAtCamera: true,
        position: {
          x: 0,
          y: 0,
          z: -fullDepth
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 90 * Math.PI / 180
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          opacity: 0.6,
          isDimensionLine: true
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 8 + fullWidth / 16,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: depthText,
        disableLookAtCamera: true,
        position: {
          x: -fullWidth / 48,
          y: 0,
          z: -fullDepth / 2
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 90 * Math.PI / 180
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2 + fullWidth / 8
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        position: {
          x: -fullWidth,
          y: 0,
          z: 0
        },
        disableLookAtCamera: true,
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          opacity: 0.6,
          isDimensionLine: true,
          extendDimForArrowBase: "z",
          dimensionLinePoints: [{
            position: {
              x: lensLeftObject.position.x + lensLeftObject.width / 2 - generatedMinMax.minx,
              y: 0,
              z: 0
            }
          }, {
            position: {
              x: lensEndPoint.position.x - generatedMinMax.minx,
              y: 0,
              z: 0
            }
          }, {
            position: {
              x: -lensEndPoint.position.x - generatedMinMax.minx,
              y: 0,
              z: 0
            }
          }, {
            position: {
              x: lensRightObject.position.x - lensRightObject.width / 2 - generatedMinMax.minx,
              y: 0,
              z: 0
            }
          }]
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2 + fullWidth / 8
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: leftLensWidthText,
        position: {
          x: -(generatedMinMax.maxx - (lensLeftObject.position.x + lensLeftObject.width / 2)) - (lensLeftObject.position.x + lensLeftObject.width / 2 - lensEndPoint.position.x) / 2,
          y: 0,
          z: -fullDepth / 48
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 0
        },
        disableLookAtCamera: true,
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2 + fullWidth / 8
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: rightLensWidthText,
        position: {
          x: -fullWidth + (lensRightObject.position.x - lensRightObject.width / 2 - generatedMinMax.minx) + (-lensEndPoint.position.x - (lensRightObject.position.x - lensRightObject.width / 2)) / 2,
          y: 0,
          z: -fullDepth / 48
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 0
        },
        disableLookAtCamera: true,
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2 + fullWidth / 8
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: bridgeWidthText,
        position: {
          x: -fullWidth / 2,
          y: 0,
          z: -fullDepth / 48
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 0
        },
        disableLookAtCamera: true,
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2 + fullWidth / 8 + fullWidth / 16
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        position: {
          x: -fullWidth,
          y: 0,
          z: 0
        },
        disableLookAtCamera: true,
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          opacity: 0.6,
          isDimensionLine: true,
          extendDimForArrowBase: "z"
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2,
        y: minLens.position.y - minLens.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2 + fullWidth / 8 + fullWidth / 16
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: widthText,
        position: {
          x: -fullWidth / 2,
          y: 0,
          z: -fullDepth / 48
        },
        rotation: {
          x: -90 * Math.PI / 180,
          y: 0,
          z: 0
        },
        disableLookAtCamera: true,
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }));
    var addForSecondViewCalloutNotation = [(0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 32,
        y: lensLeftObject.position.y + lensLeftObject.height / 2 + 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        disableLookAtCamera: true,
        position: {
          x: 0,
          y: -lensLeftObject.height,
          z: 0
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          opacity: 0.6,
          isDimensionLine: true,
          dimensionLinePoints: [{
            position: {
              x: 0,
              y: 0,
              z: 0
            }
          }]
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX + fullWidth / 2 + fullWidth / 16,
        y: lensLeftObject.position.y - lensLeftObject.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: leftLensHeightText,
        disableLookAtCamera: true,
        position: {
          x: fullWidth / 48,
          y: lensLeftObject.height / 2,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 90 * Math.PI / 180
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX - fullWidth / 2 - fullWidth / 32,
        y: lensRightObject.position.y + lensRightObject.height / 2 + 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: " ",
        disableLookAtCamera: true,
        position: {
          x: 0,
          y: -lensRightObject.height,
          z: 0
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationProps.lineProps), {}, {
          opacity: 0.6,
          isDimensionLine: true,
          dimensionLinePoints: [{
            position: {
              x: 0,
              y: 0,
              z: 0
            }
          }]
        })
      })
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultCalloutNotationObjectProps), {}, {
      position: {
        x: shiftX - fullWidth / 2 - fullWidth / 16,
        y: lensRightObject.position.y - lensRightObject.height / 2 - 0.1 / 2,
        z: shiftZ + fullDepth / 2
      },
      rotation: {
        x: rad,
        y: 0,
        z: 0
      },
      calloutNotation: (0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        text: rightLensHeightText,
        disableLookAtCamera: true,
        position: {
          x: -fullWidth / 48,
          y: lensRightObject.height / 2,
          z: 0
        },
        rotation: {
          x: 0,
          y: 0,
          z: 270 * Math.PI / 180
        },
        boundingX: "center",
        boundingY: "center"
      }, defaultCalloutNotationProps), {}, {
        lineProps: {
          disable: true
        }
      })
    })];
    return {
      generatedData: [{
        width: fullWidth * 2,
        depth: fullDepth * 2,
        height: 0.1,
        position: {
          x: 0,
          y: minLens.position.y - minLens.height / 2 + shiftY * 0.95 - 0.1 / 2,
          z: 0
        },
        rotation: {
          x: rad,
          y: 0,
          z: 0
        },
        material: {
          color: "#ffffff",
          opacity: 0
        },
        lineProps: {
          disable: true
        },
        camera: {
          "2": {
            hidden: true
          },
          "3": {
            hidden: true
          },
          "4": {
            hidden: true
          }
        }
      }].concat((0, _toConsumableArray2["default"])(glassesD), (0, _toConsumableArray2["default"])(addForMainCalloutNotation.map(function (d) {
        if (d.calloutNotation) {
          d.calloutNotation.camera = {
            "2": {
              hidden: true
            },
            "3": {
              hidden: true
            },
            "4": {
              hidden: true
            }
          };
        }
        return d;
      })), (0, _toConsumableArray2["default"])(addForSecondViewCalloutNotation.map(function (d) {
        if (d.calloutNotation) {
          d.calloutNotation.camera = {
            "0": {
              hidden: true
            },
            "3": {
              hidden: true
            },
            "4": {
              hidden: true
            }
          };
        }
        return d;
      }))),
      glassesD: glassesD,
      generatedMinMax: generatedMinMax,
      groups: groups,
      analytics: {
        "1. Item type": lensType,
        "2. Frame": addFrame ? clampSizeTextMM + (frameOpacity < 1 ? "-Translucent" : "") : "Rimless",
        "3. Symmetry": symmetry ? "Symmetrical" : "Asymmetrical",
        "4. Material thickness": weightTextMM,
        "5. Color scheme": colorScheme.name,
        "6. Temples height": templesHeightTextMM,
        "7. Temples tip (left)": templesLeft.tipName + " " + templesLeft.tipLength,
        "8. Temples middle (left)": templesLeft.name + " " + templesLeft.middleLength,
        "9. End piece (left)": lensLeft.endPieceName + " " + lensLeft.endPieceLength,
        "10. Lens (left)": lensLeft.lensForm + "-" + (lensType === "Glasses" ? "Transparent" : "Colorized") + "-" + leftLensWidthTextMM + "x" + leftLensHeightTextMM + "x" + lensDepthTextMM,
        "11. Bridge": bridgeWidth + "-" + bridge.name + " " + bridge.genPartLength,
        "12. Lens (right)": lensRight.lensForm + "-" + (lensType === "Glasses" ? "Transparent" : "Colorized") + "-" + rightLensWidthTextMM + "x" + rightLensHeightTextMM + "x" + lensDepthTextMM,
        "13. End piece (right)": lensRight.endPieceName + " " + lensRight.endPieceLength,
        "14. Temples middle (right)": templesRight.name + " " + templesRight.middleLength,
        "15. Temples tip (right)": templesRight.tipName + " " + templesRight.tipLength
      }
    };
  }
  function generateCompositionsForGlasses() {
    var colorScheme = shuffleArray([{
      background: "#faf5df",
      sketchBg: "rgba(76,192,199,0.4)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["#f9f7f0", "#faf5df"],
      name: "Constructivist coast",
      shortCode: "CC",
      borderAndFont: "rgba(29,29,38,0.7)"
    }, {
      background: "#e4a55e",
      sketchBg: "rgba(40,146,142,0.6)",
      sketchBgGrayscale: "rgba(236, 210, 167, 0.3)",
      canvas1Colors: ["#e4a55e", "#e4a55e"],
      name: "Constructivism, sea of sand",
      shortCode: "CS",
      borderAndFont: "rgba(29,29,38,0.7)"
    }, {
      background: "#e6ecef",
      sketchBg: "rgba(26,45,80,0.2)",
      sketchBgGrayscale: "rgba(255,255,255,0.3)",
      canvas1Colors: ["#e6ecef", "#e6ecef"],
      name: "Leather, denim",
      shortCode: "LD",
      borderAndFont: "#1a2d50",
      white: "#1a2d50",
      red: "rgb(61,36,4)",
      blue: "rgb(61,36,4)",
      yellow: "rgb(61,36,4)",
      black: "rgb(61,36,4)",
      lensColor: "rgb(175,105,16)"
    }, {
      background: "#bec5c7",
      sketchBg: "rgba(34,196,138,0.24)",
      sketchBgGrayscale: "rgba(34,196,138,0.24)",
      canvas1Colors: ["#bec5c7", "#bec5c7"],
      name: "Wood, bone, leaf",
      shortCode: "WB",
      borderAndFont: "#312517",
      white: "#f0dfc1",
      red: "#e8c582",
      blue: "#ebca9d",
      yellow: "#886a4e",
      black: "#f0dfc1",
      lensColor: "#a1b27e"
    }, {
      background: "linear-gradient(rgba(255,110,64,1), rgba(206,147,216,1))",
      sketchBg: "rgba(30,27,27,0.16)",
      sketchBgGrayscale: "rgba(30,27,27,0.16)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Night city lights",
      shortCode: "CL",
      borderAndFont: "#312517",
      white: "#ce93d8",
      red: "#ce93d8",
      blue: "#ff6e40",
      yellow: "#ff9800",
      black: "#ff8a80",
      lensColor: "#ff5252"
    }, {
      background: "#e6efee",
      sketchBg: "rgba(171,204,201,0.2)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Aquamarine port",
      shortCode: "AP",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#90a4ae",
      red: "#2e8572",
      blue: "#90a4ae",
      yellow: "#32b297",
      black: "#90a4ae",
      lensColor: "#32b297"
    }, {
      background: "#ffcdd2",
      sketchBg: "rgba(148,124,145,0.4)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Piggy pink geek",
      shortCode: "PP",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#90a4ae",
      red: "#394643",
      blue: "#24201f",
      yellow: "#ffcdd2",
      black: "#90a4ae",
      lensColor: "#ffcdd2"
    }, {
      background: "#e6efee",
      sketchBg: "rgba(144,164,174,0.51)",
      sketchBgGrayscale: "rgba(193,207,215,0.34)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Mandarin, cadet gray",
      shortCode: "MG",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#90a4ae",
      red: "#ef8c55",
      blue: "#90a4ae",
      yellow: "#ea6722",
      black: "#90a4ae",
      lensColor: "#ef8c55"
    }, {
      background: "#e2eeee",
      sketchBg: "rgba(174,197,197,0.29)",
      sketchBgGrayscale: "rgba(205,224,224,0.21)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Orange, dark charcoal",
      shortCode: "OD",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#2c2f2f",
      red: "#ea6722",
      blue: "#2c2f2f",
      yellow: "#454d4d",
      black: "#2c2f2f",
      lensColor: "#ea6722"
    }, {
      background: "#e2e4ec",
      sketchBg: "rgba(201,205,220,0.44)",
      sketchBgGrayscale: "rgba(212,217,238,0.27)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Laguna blue metal",
      shortCode: "LB",
      borderAndFont: "rgba(14,36,47,0.62)",
      white: "#0e242f",
      red: "#3f59d3",
      blue: "#0e242f",
      yellow: "#2542c9",
      black: "#0e242f",
      lensColor: "#2542c9"
    }, {
      background: "#e6ecef",
      sketchBg: "rgba(26,45,80,0.2)",
      sketchBgGrayscale: "rgba(57,78,115,0.2)",
      canvas1Colors: ["#e6ecef", "#e6ecef"],
      name: "Madonna blue, white",
      shortCode: "BW",
      borderAndFont: "#1c2d34",
      white: "#85c8e3",
      red: "#71B5D1",
      blue: "#71B5D1",
      yellow: "#ffffff",
      black: "#71B5D1",
      lensColor: "#71B5D1"
    }, {
      background: "#e6ecef",
      sketchBg: "rgba(26,45,80,0.2)",
      sketchBgGrayscale: "rgba(255,255,255,0.3)",
      canvas1Colors: ["#e6ecef", "#e6ecef"],
      name: "Madonna blue, night",
      shortCode: "BN",
      borderAndFont: "#1c2d34",
      white: "#363e41",
      red: "#363e41",
      blue: "#363e41",
      yellow: "#363e41",
      black: "#71B5D1",
      lensColor: "#71B5D1"
    }, {
      background: "#e6ecef",
      sketchBg: "rgba(28,45,52,0.12)",
      sketchBgGrayscale: "rgba(255,255,255,0.3)",
      canvas1Colors: ["#e6ecef", "#e6ecef"],
      name: "American pink, night",
      shortCode: "PN",
      borderAndFont: "#1c2d34",
      white: "#363e41",
      red: "#363e41",
      blue: "#363e41",
      yellow: "#363e41",
      black: "#d17171",
      lensColor: "#d17171"
    }, {
      background: "#faf5df",
      sketchBg: "rgba(76,192,199,0.4)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["#f9f7f0", "#faf5df"],
      name: "Carmine pink, gold",
      shortCode: "PG",
      borderAndFont: "#3b3333",
      white: "#d99202",
      red: "#efaa1e",
      blue: "#fdd349",
      yellow: "#fbbd4e",
      black: "#ec4949",
      lensColor: "#ec4949"
    }, {
      background: "#e6efee",
      sketchBg: "rgba(144,164,174,0.2)",
      sketchBgGrayscale: "rgba(144,164,174,0.2)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Marigold, rose gold",
      shortCode: "MR",
      borderAndFont: "#3b3333",
      white: "#E9A7A1",
      red: "#E9A7A1",
      blue: "#e9b7a1",
      yellow: "#e9a7a1",
      black: "#efaa1e",
      lensColor: "#efaa1e"
    }, {
      background: "#bec5c7",
      sketchBg: "rgba(34,196,138,0.24)",
      sketchBgGrayscale: "rgba(34,196,138,0.24)",
      canvas1Colors: ["#bec5c7", "#bec5c7"],
      name: "Purple, silver",
      shortCode: "PS",
      borderAndFont: "#3b3333",
      white: "#c4ced0",
      red: "#D5DDE0",
      blue: "#e9ecee",
      yellow: "#e1eaee",
      black: "#a990e0",
      lensColor: "#a990e0"
    }, {
      background: "#eeeeee",
      sketchBg: "rgba(144,164,174,0.3)",
      sketchBgGrayscale: "rgba(193,207,215,0.2)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Black monochrome",
      shortCode: "BM",
      borderAndFont: "#212728",
      white: "#2b3233",
      red: "#1d2021",
      blue: "#212728",
      yellow: "#363838",
      black: "#3c4344",
      lensColor: "#3b3f3f"
    }, {
      background: "#eeeeee",
      sketchBg: "rgba(144,164,174,0.3)",
      sketchBgGrayscale: "rgba(193,207,215,0.2)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "White monochrome",
      shortCode: "WM",
      borderAndFont: "#212728",
      white: "#f1f5f5",
      red: "#f1f5f5",
      blue: "#f1f5f5",
      yellow: "#f1f5f5",
      black: "#f1f5f5",
      lensColor: "#3b3f3f"
    }, {
      background: "#eeeeee",
      sketchBg: "rgba(144,164,174,0.51)",
      sketchBgGrayscale: "rgba(193,207,215,0.34)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "Black, cadet gray",
      shortCode: "BG",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#90a4ae",
      red: "#90a4ae",
      blue: "#90a4ae",
      yellow: "#1d2021",
      black: "#3c4344",
      lensColor: "#3b3f3f"
    }, {
      background: "#eeeeee",
      sketchBg: "rgba(144,164,174,0.51)",
      sketchBgGrayscale: "rgba(193,207,215,0.34)",
      canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      name: "White, Grays",
      shortCode: "WG",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#90a4ae",
      red: "#90a4ae",
      blue: "#90a4ae",
      yellow: "#f1f5f5",
      black: "#f1f5f5",
      lensColor: "#3b3f3f"
    }, {
      background: "#faf5df",
      sketchBg: "rgba(76,192,199,0.4)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["#f9f7f0", "#faf5df"],
      name: "Yellow, White, Gray",
      shortCode: "YW",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#90a4ae",
      red: "#90a4ae",
      blue: "#90a4ae",
      yellow: "#f1f5f5",
      black: "#f1f5f5",
      lensColor: "#fab810"
    }, {
      background: "#faf5df",
      sketchBg: "rgba(76,192,199,0.4)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["#f9f7f0", "#faf5df"],
      name: "Yellow, Black, Gray",
      shortCode: "YB",
      borderAndFont: "rgba(29,29,38,0.7)",
      white: "#1d2021",
      red: "#90a4ae",
      blue: "#90a4ae",
      yellow: "#1d2021",
      black: "#1d2021",
      lensColor: "#fab810"
    }, {
      background: "#f1efef",
      sketchBg: "rgba(27,30,30,0.16)",
      sketchBgGrayscale: "rgba(27,30,29,0.16)",
      canvas1Colors: ["#f1efef", "#f1efef"],
      borderAndFont: "rgba(29,29,38,0.7)",
      name: "Mint, White, Gray",
      shortCode: "MW",
      white: "#90a4ae",
      red: "#90a4ae",
      blue: "#90a4ae",
      yellow: "#f1f5f5",
      black: "#72CBA2",
      lensColor: "#72CBA2"
    }, {
      background: "#f1efef",
      sketchBg: "rgba(27,30,30,0.16)",
      sketchBgGrayscale: "rgba(27,30,29,0.16)",
      canvas1Colors: ["#f1efef", "#f1efef"],
      borderAndFont: "rgba(29,29,38,0.7)",
      name: "Mint, Black, Gray",
      shortCode: "MB",
      white: "#1d2021",
      red: "#90a4ae",
      blue: "#90a4ae",
      yellow: "#1d2021",
      black: "#72CBA2",
      lensColor: "#72CBA2"
    }])[0];
    var ggData = generateGlasses({
      colorScheme: colorScheme
    });
    var ggDataTemp = (0, _utils.copyObject)(ggData);
    var generatedData = (0, _toConsumableArray2["default"])(ggData.generatedData);
    var sketchBgColor = colorScheme.sketchBg;
    var sketchBgColorGrayscale = colorScheme.sketchBgGrayscale;
    var backgroundBorderProps = [[colorScheme.borderAndFont, "rgba(0,0,0,0)", colorScheme.borderAndFont, "rgba(0,0,0,0)"], 29.5, 29.5, 20, 20];
    var codes = (0, _defineProperty2["default"])({
      "Glasses": "G",
      "Sunglasses": "S",
      "Full": "FF",
      "Rimless": "RL",
      "Symmetrical": "S",
      "Asymmetrical": "A",
      "Rectangle": "R",
      "Octagon": "O",
      "Hexagon": "H",
      "Colorized": "C",
      "Transparent": "T",
      "Translucent": "T",
      "Boxes": "B",
      "L-shaped": "L"
    }, colorScheme.name, colorScheme.shortCode);
    var pn = ggData.analytics ? ["1. Item type", "2. Frame", "3. Symmetry", "4. Material thickness", "5. Color scheme", "6. Temples height", "-", "7. Temples tip (left)", "8. Temples middle (left)", "-", "9. End piece (left)", "10. Lens (left)", "-", "11. Bridge", "-", "12. Lens (right)", "13. End piece (right)", "-", "14. Temples middle (right)", "15. Temples tip (right)"].map(function (name) {
      if (name === "-") {
        return name;
      }
      if (ggData.analytics[name]) {
        var t = ggData.analytics[name];
        Object.keys(codes).forEach(function (rs) {
          if (ggData.analytics[name].match(rs)) {
            t = t.replace(rs, codes[rs]);
          }
        });
        t = t.replace(/-/g, "");
        t = t.replace(/\s/g, "");
        return t;
      }
      return "";
    }).join("") : "";
    var pnPart1 = pn.split("-").slice(0, 3).join("-");
    var pnPart2 = pn.split("-").slice(3).join("-");
    var title = "GENERATIVE GLASSES";
    var subTitle = "eyewear product design";
    function generateDate() {
      var monthAndYear = shuffleArray(["03/2023", "02/2023", "01/2023", "12/2022", "11/2022"])[0];
      var dayMax = monthAndYear.startsWith("02") ? 28 : monthAndYear.startsWith("11") ? 30 : 31;
      var day = getRandomInt(1, dayMax);
      var dayT = day < 10 ? "0" + day : day;
      return dayT + "/" + monthAndYear;
    }
    var date = generateDate();
    var compositions = (0, _toConsumableArray2["default"])(initialData.map(function (v) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, v), {}, {
        data: generatedData
      });
    }));
    compositions[0].background = colorScheme.background;
    var randomVariables = (0, _toConsumableArray2["default"])(Array(100).fill(0).map(function () {
      return getRandomInt(0, 99999) / 100000;
    }));
    compositions[0].randomVariables = randomVariables;
    compositions[0].canvasFullBackground = [{
      type: "canvas1",
      props: colorScheme.canvas1Colors
    }, {
      type: "border",
      props: [backgroundBorderProps[0][0]]
    }, {
      type: "titleBox",
      props: [colorScheme.borderAndFont, 2, 2, 2, 2, [21, 51], 14, pn ? [title, subTitle] : title, pn ? ["P/N: " + pnPart1, pnPart2] : subTitle, date]
    }, {
      type: "carco",
      props: [colorScheme.borderAndFont, 2, 2, 2, 2, [7, 17], 7, 10]
    }];
    var mainSketchBgType = shuffleArray(["sketchBgVertical", "sketchBgHorizontal"])[0];
    var subViewsSketchBgType = shuffleArray(["sketchBgVertical", "sketchBgHorizontal"])[0];
    compositions[0].groups = ggData.groups;
    compositions[0].canvasBackground = [{
      type: "border",
      props: backgroundBorderProps
    }, {
      type: mainSketchBgType,
      props: [sketchBgColor, 30, 30, 20, 20]
    }];
    compositions[0].editLines = {
      disableChangeCalloutNotations: false,
      show: true
    };
    compositions[0].calloutNotations = {
      disableChangeEditLines: false,
      show: true
    };
    compositions[0].cameraTarget = {
      "x": 0,
      "y": 472,
      "z": 0
    };
    compositions[0].cameraPosition = {
      "x": 33.37,
      "y": 496,
      "z": 48.6
    };
    compositions[0].objectsPosition = {
      "x": 0,
      "y": 473,
      "z": 0
    };
    compositions[0].cameraZoom = 1.167;
    compositions[0].objectsRotation = {
      x: -ggData.generatedData[0].rotation.x,
      y: 0,
      z: 0
    };
    compositions[0].controlsProps = {
      minPolarAngle: -Math.PI / 2,
      maxPolarAngle: Math.PI / 2,
      minAzimuthAngle: -Math.PI / 2,
      maxAzimuthAngle: Math.PI / 2
    };
    compositions[0].lights = [{
      x: -20,
      y: 60,
      z: ggData.generatedMinMax.maxz + 20,
      type: "Directional",
      props: ["#ffffff", 2]
    }];
    compositions[0].ambientLight = {
      props: ["#ffffff", 3]
    };
    compositions[0].animation = ["D"];
    var generatedDimension = shuffleArray(["landscape4K", "portrait4K", "square4K"])[0];
    var dimensions = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _getDefaults.defaultDimensions), {}, {
      squareHD: {}
    });
    compositions[0].dimensions = {
      dimensions: Object.keys(dimensions).map(function (key) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, dimensions[key]), {}, {
          key: key
        });
      }),
      defaultDimension: generatedDimension
    };
    compositions[0].splitScreen = {
      startWithSplitScreen: true,
      hideBorders: true,
      renderersData: {
        "0": {
          width: 100,
          height: 70,
          left: 0,
          top: 0,
          canvasBackground: [{
            type: "border",
            props: backgroundBorderProps
          }, {
            type: mainSketchBgType,
            props: [sketchBgColor, 30, 30, 20, 20]
          }],
          editLines: {
            show: true
          },
          calloutNotations: {
            show: true
          },
          cameraPosition: (0, _objectSpread2["default"])({}, compositions[0].cameraPosition),
          cameraZoom: 1.945,
          cameraTarget: (0, _objectSpread2["default"])({}, compositions[0].cameraTarget)
        },
        "2": {
          width: 40,
          height: 40,
          left: 5,
          top: 55,
          postFilters: [{
            type: "grayscale",
            value: 1
          }],
          canvasBackground: [{
            type: "border",
            props: [backgroundBorderProps[0], 39.5, 19.5, 20, 20]
          }, {
            type: subViewsSketchBgType,
            props: [sketchBgColorGrayscale, 40, 20, 20, 20]
          }],
          editLines: {
            show: true
          },
          calloutNotations: {
            show: true
          },
          cameraPosition: {
            x: compositions[0].objectsPosition.x + 36,
            y: compositions[0].objectsPosition.y + 1.8,
            z: compositions[0].cameraPosition.z
          },
          cameraTarget: {
            x: compositions[0].cameraTarget.x,
            y: compositions[0].objectsPosition.y + 1.8,
            z: compositions[0].cameraTarget.z
          },
          cameraZoom: 1.945
        },
        "3": {
          visibility: "hidden",
          editLines: {
            show: false
          },
          calloutNotations: {
            show: false
          }
        },
        "4": {
          width: 40,
          height: 40,
          left: 54,
          top: 55,
          postFilters: [{
            type: "grayscale",
            value: 1
          }],
          canvasBackground: [{
            type: "border",
            props: [backgroundBorderProps[0], 29.5, 49.5, 20, 20]
          }, {
            type: subViewsSketchBgType,
            props: [sketchBgColorGrayscale, 30, 50, 20, 20]
          }],
          editLines: {
            show: true
          },
          calloutNotations: {
            show: true
          },
          cameraPosition: {
            x: compositions[0].cameraPosition.x - 74,
            y: compositions[0].cameraPosition.y - 1,
            z: compositions[0].cameraPosition.z - 5.4
          },
          cameraTarget: {
            x: compositions[0].cameraTarget.x,
            y: compositions[0].cameraTarget.y - 1,
            z: compositions[0].cameraTarget.z
          },
          cameraZoom: 1.945
        }
      }
    };
    compositions[0].ar = {
      enable: true,
      boundingBox: {
        min: {
          x: ggData.generatedMinMax.minx,
          y: ggData.generatedMinMax.miny,
          z: ggData.generatedMinMax.minz
        },
        max: {
          x: ggData.generatedMinMax.maxx,
          y: ggData.generatedMinMax.maxy,
          z: ggData.generatedMinMax.maxz
        }
      }
    };
    var startCompIndex = shuffleArray([0, 1, 2, 4])[0];
    var analytics = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, ggData.analytics), {}, {
      "16. Product Number": pn,
      "17. Product Created": date,
      "18. Default Aspect Ratio": dimensions[generatedDimension].name,
      "19. Preview type": startCompIndex === 0 ? "Abstract view" : startCompIndex === 1 ? "Front-left" : startCompIndex === 2 ? "Front-right" : "Product design"
    });
    compositions[0].data.forEach(function (d) {
      if (d.material.opacity && !d.calloutNotations) {
        d.shadow = {
          disableReceiveShadow: true
        };
      }
    });
    compositions[1] = (0, _utils.copyObject)(compositions[0]);
    compositions[2] = (0, _utils.copyObject)(compositions[0]);
    compositions[3] = (0, _utils.copyObject)(compositions[0]);
    compositions[4] = (0, _utils.copyObject)(compositions[0]);
    compositions[5] = (0, _utils.copyObject)(compositions[0]);
    compositions[0].data = compositions[0].data.filter(function (d) {
      return !d.calloutNotation;
    });
    compositions[1].data = compositions[1].data.filter(function (d) {
      return !d.calloutNotation;
    });
    compositions[2].data = compositions[2].data.filter(function (d) {
      return !d.calloutNotation;
    });
    compositions[3].data = compositions[3].data.filter(function (d) {
      return !d.calloutNotation;
    });
    compositions[5].data = compositions[3].data.filter(function (d) {
      return !d.calloutNotation;
    });
    var tempData = compositions[0].data.map(function (d, i) {
      d.i = i;
      return d;
    });
    var completePositionObjects = [];
    function getIntersectedObjects(d) {
      var minx = d.position.x - d.width / 2;
      var maxx = d.position.x + d.width / 2;
      var minz = d.position.z - d.depth / 2;
      var maxz = d.position.z + d.depth / 2;
      var r = completePositionObjects.filter(function (c) {
        var cminx = c.position.x - c.width / 2;
        var cmaxx = c.position.x + c.width / 2;
        var cminz = c.position.z - c.depth / 2;
        var cmaxz = c.position.z + c.depth / 2;
        var xI1 = minx >= cminx && minx <= cmaxx || maxx >= cminx && maxx <= cmaxx;
        var zI1 = minz >= cminz && minz <= cmaxz || maxz >= cminz && maxz <= cmaxz;
        var xI2 = cminx >= minx && cminx <= maxx || cmaxx >= minx && cmaxx <= maxx;
        var zI2 = cminz >= minz && cminz <= maxz || cmaxz >= minz && cmaxz <= maxz;
        return xI1 && zI1 || xI2 && zI2;
      });
      return r.sort(function (a, b) {
        var amaxy = a.position.y + a.height / 2;
        var bmaxy = b.position.y + b.height / 2;
        return amaxy > bmaxy ? -1 : amaxy < bmaxy ? 1 : 0;
      });
    }
    function objectTransform(d, rAxis) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({
        width: d.width,
        depth: d.depth,
        height: d.height
      }, rAxis === "z" ? {
        width: d.height,
        height: d.width
      } : {}), rAxis === "x" ? {
        depth: d.height,
        height: d.depth
      } : {}), {}, {
        position: {
          x: d.position.x,
          y: d.position.y,
          z: d.position.z
        }
      });
    }
    function getRAxis(d) {
      var minDim = d.width <= d.height && d.width <= d.depth ? "x" : d.depth <= d.height && d.depth <= d.width ? "z" : "y";
      return minDim === "x" ? "z" : minDim === "z" ? "x" : "y";
    }
    tempData.sort(function (a, b) {
      var aO = objectTransform(a, getRAxis(a));
      var bO = objectTransform(b, getRAxis(b));
      var aM = aO.width * aO.depth;
      var bM = bO.width * bO.depth;
      return aM > bM ? -1 : aM < bM ? 1 : 0;
    });
    tempData.forEach(function (d) {
      var _d$material;
      if (((_d$material = d.material) === null || _d$material === void 0 ? void 0 : _d$material.opacity) !== 0) {
        if (!d.rotation) {
          d.rotation = {
            x: 0,
            y: 0,
            z: 0
          };
        }
        var minDim = d.width <= d.height && d.width <= d.depth ? "x" : d.depth <= d.height && d.depth <= d.width ? "z" : "y";
        var rAxis = minDim === "x" ? "z" : minDim === "z" ? "x" : "y";
        d.rotation[rAxis] = minDim !== "y" ? -Math.PI / 2 : 0;
        var addY = (rAxis === "z" ? d.width : rAxis === "x" ? d.depth : d.height) / 2;
        var maxDim = rAxis === "z" ? Math.max(d.height, d.depth) : rAxis === "x" ? Math.max(d.height, d.width) : Math.max(d.width, d.depth);
        d.position.y = ggData.generatedMinMax.miny + addY;
        d.position.x = getRandomInt((ggData.generatedMinMax.minx + maxDim / 2) * 100, (ggData.generatedMinMax.maxx - maxDim / 2) * 100) / 100;
        d.position.z = getRandomInt((ggData.generatedMinMax.minz + maxDim / 2) * 100, (ggData.generatedMinMax.maxz - maxDim / 2) * 100) / 100;
        var io = getIntersectedObjects(objectTransform(d, rAxis))[0];
        if (io) {
          d.position.y = io.position.y + io.height / 2 + addY;
        }
        completePositionObjects.push(objectTransform(d, rAxis));
        d.editLines = {
          disable: true
        };
      }
    });
    tempData.forEach(function (d) {
      var _d$material2;
      if ((_d$material2 = d.material) !== null && _d$material2 !== void 0 && _d$material2.opacity) {
        var minDim = d.width <= d.height && d.width <= d.depth ? "x" : d.depth <= d.height && d.depth <= d.width ? "z" : "y";
        var rAxis = minDim === "x" ? "z" : minDim === "z" ? "x" : "y";
        d.rotation[rAxis === "z" ? "y" : minDim] = getRandomInt(-30, 30) / 100;
      }
    });
    compositions[0].data.forEach(function (d, i) {
      var tD = tempData.find(function (d) {
        return d.i === i;
      });
      d.position.x = tD.position.x;
      d.position.y = tD.position.y;
      d.position.z = tD.position.z;
    });
    compositions[0].data[0].rotation.x = 0;
    compositions[0].data[0].position.y = ggData.generatedMinMax.miny - 0.1 / 2;
    compositions[0].objectsRotation.x = 0;
    var defaultCameraTarget = (0, _objectSpread2["default"])({}, compositions[0].cameraTarget);
    var defaultCameraPosition = (0, _objectSpread2["default"])({}, compositions[0].cameraPosition);
    compositions[0].cameraPosition = {
      x: defaultCameraTarget.x,
      y: defaultCameraPosition.y + 35,
      z: defaultCameraTarget.z
    };
    compositions[0].cameraTarget = {
      x: defaultCameraTarget.x,
      y: defaultCameraTarget.y,
      z: defaultCameraTarget.z
    };
    compositions[0].cameraZoom = 2;
    compositions[0].canvasFullBackground = (0, _toConsumableArray2["default"])(compositions[0].canvasFullBackground.slice(0, 2));
    compositions[0].canvasBackground = [];
    compositions[0].lights = [];
    compositions[0].ambientLight = {
      props: ["#ffffff", 6]
    };
    compositions[1].cameraZoom = 1.667;
    compositions[1].canvasFullBackground = (0, _toConsumableArray2["default"])(compositions[1].canvasFullBackground.slice(0, 2));
    compositions[1].canvasBackground = [];
    compositions[2].cameraZoom = 1.667;
    compositions[2].cameraTarget = {
      x: -defaultCameraTarget.x,
      y: defaultCameraTarget.y,
      z: defaultCameraTarget.z
    };
    compositions[2].cameraPosition = {
      x: -defaultCameraPosition.x,
      y: defaultCameraPosition.y,
      z: defaultCameraPosition.z
    };
    compositions[2].canvasFullBackground = (0, _toConsumableArray2["default"])(compositions[2].canvasFullBackground.slice(0, 2));
    compositions[2].canvasBackground = [];
    compositions[3].cameraPosition = {
      x: defaultCameraTarget.x,
      y: defaultCameraPosition.y + 35,
      z: defaultCameraTarget.z
    };
    compositions[3].cameraTarget = {
      x: defaultCameraTarget.x,
      y: defaultCameraTarget.y,
      z: defaultCameraTarget.z
    };
    compositions[3].cameraZoom = 1.667;
    compositions[3].canvasFullBackground = (0, _toConsumableArray2["default"])(compositions[3].canvasFullBackground.slice(0, 2));
    compositions[3].canvasBackground = [];
    compositions[4].cameraPosition = {
      x: defaultCameraPosition.x,
      y: defaultCameraPosition.y - 2,
      z: defaultCameraPosition.z
    };
    compositions[4].cameraTarget = {
      x: defaultCameraTarget.x,
      y: defaultCameraTarget.y - 2,
      z: defaultCameraTarget.z
    };
    compositions[5].cameraZoom = compositions[1].cameraZoom;
    compositions[5].cameraPosition = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, compositions[3].cameraPosition), {}, {
      y: defaultCameraPosition.y - 10.6,
      z: defaultCameraPosition.z + 17.4
    });
    compositions[5].cameraTarget = (0, _objectSpread2["default"])({}, compositions[3].cameraTarget);
    compositions[5].canvasFullBackground = (0, _toConsumableArray2["default"])(compositions[5].canvasFullBackground.slice(0, 2));
    compositions[5].canvasBackground = [];
    compositions[0].editLines.disableChangeEditLines = true;
    compositions[0].calloutNotations.disableChangeCalloutNotations = true;
    compositions[1].calloutNotations.disableChangeCalloutNotations = true;
    compositions[2].calloutNotations.disableChangeCalloutNotations = true;
    compositions[3].calloutNotations.disableChangeCalloutNotations = true;
    compositions[5].calloutNotations.disableChangeCalloutNotations = true;
    function disableSplitScreen(n) {
      compositions[n].splitScreen.disableChangeSplitScreen = true;
      compositions[n].splitScreen.renderersData["0"] = {
        width: 100,
        height: 100,
        left: 0,
        top: 0
      };
      compositions[n].splitScreen.renderersData["2"] = {
        visibility: "hidden",
        editLines: {
          show: false
        },
        calloutNotations: {
          show: false
        }
      };
      compositions[n].splitScreen.renderersData["3"] = (0, _objectSpread2["default"])({}, compositions[n].splitScreen.renderersData["2"]);
      compositions[n].splitScreen.renderersData["4"] = (0, _objectSpread2["default"])({}, compositions[n].splitScreen.renderersData["2"]);
    }
    disableSplitScreen(0);
    disableSplitScreen(3);
    disableSplitScreen(5);
    compositions[2].splitScreen.renderersData["2"] = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, compositions[2].splitScreen.renderersData["2"]), {}, {
      cameraPosition: {
        x: compositions[2].objectsPosition.x - 36,
        y: compositions[2].objectsPosition.y + 1.8,
        z: compositions[2].cameraPosition.z
      },
      cameraTarget: {
        x: compositions[2].cameraTarget.x,
        y: compositions[2].objectsPosition.y + 1.8,
        z: compositions[2].cameraTarget.z
      },
      cameraZoom: 1.945
    });
    compositions[2].splitScreen.renderersData["4"] = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, compositions[2].splitScreen.renderersData["4"]), {}, {
      cameraPosition: {
        x: compositions[2].splitScreen.renderersData["0"].cameraPosition.x,
        y: compositions[2].splitScreen.renderersData["0"].cameraPosition.y - 1,
        z: compositions[2].splitScreen.renderersData["0"].cameraPosition.z
      },
      cameraTarget: {
        x: compositions[2].splitScreen.renderersData["0"].cameraTarget.x,
        y: compositions[2].splitScreen.renderersData["0"].cameraTarget.y - 1,
        z: compositions[2].splitScreen.renderersData["0"].cameraTarget.z
      }
    });
    compositions[2].splitScreen.renderersData["0"] = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, compositions[2].splitScreen.renderersData["0"]), {}, {
      cameraPosition: (0, _objectSpread2["default"])({}, compositions[2].cameraPosition),
      cameraZoom: 1.945,
      cameraTarget: (0, _objectSpread2["default"])({}, compositions[2].cameraTarget)
    });
    compositions[0].splitScreen.startWithSplitScreen = false;
    compositions[1].splitScreen.startWithSplitScreen = false;
    compositions[2].splitScreen.startWithSplitScreen = false;
    compositions[3].splitScreen.startWithSplitScreen = false;
    compositions[4].splitScreen.startWithSplitScreen = false;
    compositions[5].splitScreen.startWithSplitScreen = false;
    delete compositions[0].ar;
    delete compositions[4].ar;
    compositions[4].animation = ["Space", 1000, "Space", 200, "D"];
    compositions[5].animation = ["ArrowUp", "D"];
    compositions[0].animationProps = {
      addObjectsAnimationType: "fromCurrentToNext",
      addObjectsAnimationTime: [3000, 6000],
      cameraMoveTime: 5000
    };
    compositions[1].animationProps = {
      addObjectsAnimationType: "fromCurrentToNext",
      addObjectsAnimationTime: [5000, 10000],
      cameraMoveTime: 5000
    };
    compositions[2].animationProps = {
      addObjectsAnimationType: "fromCurrentToNext"
    };
    compositions[3].animationProps = {
      addObjectsAnimationType: "fromCurrentToNext"
    };
    compositions[4].animationProps = {
      addObjectsAnimationType: "fromCurrentToNext",
      addObjectsAnimationTime: [600, 800]
    };
    compositions[5].animationProps = {
      addObjectsAnimationTime: [3000, 6000]
    };
    var copyComp1 = (0, _toConsumableArray2["default"])(compositions);
    var copyComp2 = (0, _toConsumableArray2["default"])(compositions);
    compositions = copyComp1.splice(startCompIndex).concat(copyComp2.splice(0, startCompIndex));
    return {
      compositions: compositions,
      generatedData: generatedData,
      analytics: analytics,
      ggData: ggDataTemp
    };
  }
  function generateRenderPropsForGlasses() {
    return {
      renderType: "WebGL",
      shadow: {
        enable: true,
        opacity: 0.5
      },
      editLines: {
        enable: true
      },
      calloutNotations: {
        enable: true
      },
      /*composer: {
      enable: false,
      renderer: {
          opacity: 1,
          blendMode: "darken"
      },
      waterColor: {
          enable: true,
          intensity: 1
      }
      },*/
      camera: {
        d: 25,
        fov: 40,
        type: "perspective"
      }
    };
  }
  return {
    generateCompositionsForGlasses: generateCompositionsForGlasses,
    generateRenderPropsForGlasses: generateRenderPropsForGlasses
  };
}