"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _defaultAppData = _interopRequireDefault(require("default-app/dist/common/defaultAppData"));
var _default = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _defaultAppData["default"]), {}, {
  "compositions": [{
    data: [{
      width: 6,
      height: 0.9,
      depth: 9,
      material: {
        color: 0xe7b508,
        opacity: 0.95
      },
      position: {
        x: -10,
        y: 0,
        z: 0.12
      }
    }, {
      width: 12,
      height: 14,
      depth: 0.2,
      material: {},
      position: {
        x: -14.5,
        y: -1,
        z: -4.5
      }
    }, {
      width: 4.5,
      height: 14,
      depth: 0.2,
      material: {
        color: 0xf5f1ef,
        opacity: 0.95
      },
      position: {
        x: -10.75,
        y: -1,
        z: -4.49
      }
    }, {
      width: 2.5,
      height: 3,
      depth: 0.2,
      material: {
        opacity: 0
      },
      position: {
        x: -9.75,
        y: 7.5,
        z: -4.5
      }
    }, {
      width: 5.5,
      height: 6.45,
      depth: 0.2,
      material: {
        color: 0xede1305
      },
      position: {
        x: -4.25,
        y: 2.775,
        z: 2.5
      }
    }, {
      width: 0.2,
      height: 3.5,
      depth: 9,
      material: {
        opacity: 0.95
      },
      position: {
        x: -1.55,
        y: 4.26,
        z: -1.8
      }
    }, {
      width: 0.2,
      height: 3,
      depth: 2.5,
      material: {
        opacity: 0
      },
      position: {
        x: -1.55,
        y: 7.5,
        z: -5.05
      }
    }, {
      width: 3,
      height: 12,
      depth: 0.2,
      material: {
        color: 0xede1305
      },
      position: {
        x: -1.1,
        y: 8.5,
        z: -6.4
      }
    }, {
      width: 3.5,
      height: 12,
      depth: 0.2,
      material: {},
      position: {
        x: -4.35,
        y: 8.5,
        z: -6.4
      }
    }, {
      width: 0.2,
      height: 10,
      depth: 9,
      material: {
        opacity: 0.95
      },
      position: {
        x: 0.45,
        y: 8.1,
        z: -11.4
      }
    }, {
      width: 0.2,
      height: 14.5,
      depth: 6,
      material: {
        color: 0xe6e6ee
      },
      position: {
        x: -0.05,
        y: 5.85,
        z: -17.7
      }
    }, {
      width: 4,
      height: 1.2,
      depth: 7,
      material: {
        color: 0x000000,
        opacity: 0.95
      },
      position: {
        x: 0,
        y: -2,
        z: -18.2
      }
    }, {
      width: 7,
      height: 0.8,
      depth: 4.5,
      material: {
        color: 0xffffff,
        opacity: 0.95
      },
      position: {
        x: -3.7,
        y: -0.95,
        z: -22.7
      }
    }, {
      width: 5,
      height: 0.8,
      depth: 5,
      material: {
        color: 0x426dfb
      },
      position: {
        x: 4.5,
        y: -3,
        z: -12.6
      }
    }, {
      width: 0.2,
      height: 20.5,
      depth: 6.5,
      material: {
        color: 0xede1305,
        opacity: 0.9
      },
      position: {
        x: 10.5,
        y: 3,
        z: -5.25
      }
    }, {
      width: 4,
      height: 21.5,
      depth: 0.2,
      material: {},
      position: {
        x: 9.5,
        y: 3.5,
        z: -2
      }
    }, {
      width: 0.2,
      height: 20.5,
      depth: 8.5,
      material: {
        color: 0x426dfb
      },
      position: {
        x: 7.6,
        y: 3,
        z: 2.4
      }
    }, {
      width: 4.2,
      height: 0.9,
      depth: 6,
      material: {
        color: 0xe7b508,
        opacity: 0.95
      },
      position: {
        x: 9.85,
        y: 2.175,
        z: 2.5
      }
    }, {
      width: 2,
      height: 23.5,
      depth: 0.2,
      material: {},
      position: {
        x: 7,
        y: 4.5,
        z: 7.5
      }
    }, {
      width: 0.2,
      height: 22,
      depth: 2.5,
      material: {},
      position: {
        x: 5.8,
        y: 6,
        z: 8.65
      }
    }, {
      width: 3,
      height: 20.6,
      depth: 0.2,
      material: {
        color: 0x426dfb
      },
      position: {
        x: 4.2,
        y: 5.3,
        z: 9.8
      }
    }, {
      width: 2.2,
      height: 0.8,
      depth: 2,
      material: {
        opacity: 0.95
      },
      position: {
        x: 3.8,
        y: -0.7,
        z: 10.6
      }
    }, {
      width: 2.8,
      height: 3,
      depth: 1,
      material: {
        color: 0x000000,
        opacity: 0.95
      },
      position: {
        x: 3.5,
        y: -6.5,
        z: 10.4
      }
    }, {
      width: 16,
      height: 4,
      depth: 2.5,
      material: {},
      position: {
        x: -2,
        y: -3,
        z: 4.12
      }
    }, {
      width: 16,
      height: 0.01,
      depth: 2.5,
      material: {
        color: 0xcacad0,
        opacity: 0.95
      },
      position: {
        x: -2,
        y: -1,
        z: 4.12
      }
    }],
    background: "#eeac56",
    cameraPosition: {
      x: 16,
      y: 346,
      z: 9
    }
  }]
});
exports["default"] = _default;