"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGenerateFunctions;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _generateBlank = _interopRequireDefault(require("./generateBlank"));
var _generateConstructions = _interopRequireDefault(require("./generateConstructions"));
var _generateGlasses = _interopRequireDefault(require("./generateGlasses"));
var _generateTextile = _interopRequireDefault(require("./generateTextile"));
var _generateBinaryWords = _interopRequireDefault(require("./generateBinaryWords"));
var _generateNoNeedForNoise = _interopRequireDefault(require("./generateNoNeedForNoise"));
var _generateSynesthesia = _interopRequireDefault(require("./generateSynesthesia"));
var _defaultAppData = _interopRequireDefault(require("../../../defaultAppData"));
function getGenerateFunctions(props) {
  var initialData = [(0, _objectSpread2["default"])({}, _defaultAppData["default"].compositions[0])];
  var _getGenerateBlankFunc = (0, _generateBlank["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForBlank = _getGenerateBlankFunc.generateCompositionsForBlank,
    generateRenderPropsForBlank = _getGenerateBlankFunc.generateRenderPropsForBlank;
  var _getGenerateConstruct = (0, _generateConstructions["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForConstructions = _getGenerateConstruct.generateCompositionsForConstructions;
  var _getGenerateGlassesFu = (0, _generateGlasses["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForGlasses = _getGenerateGlassesFu.generateCompositionsForGlasses,
    generateRenderPropsForGlasses = _getGenerateGlassesFu.generateRenderPropsForGlasses;
  var _getGenerateTextileFu = (0, _generateTextile["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForTextile = _getGenerateTextileFu.generateCompositionsForTextile,
    generateRenderPropsForTextile = _getGenerateTextileFu.generateRenderPropsForTextile;
  var _getGenerateBinaryWor = (0, _generateBinaryWords["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForBinaryWords = _getGenerateBinaryWor.generateCompositionsForBinaryWords,
    generateRenderPropsForBinaryWords = _getGenerateBinaryWor.generateRenderPropsForBinaryWords;
  var _getGenerateNoNeedFor = (0, _generateNoNeedForNoise["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForNoNeedForNoise = _getGenerateNoNeedFor.generateCompositionsForNoNeedForNoise,
    generateRenderPropsForNoNeedForNoise = _getGenerateNoNeedFor.generateRenderPropsForNoNeedForNoise;
  var _getGenerateSynesthes = (0, _generateSynesthesia["default"])((0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, props), {}, {
      initialData: initialData
    })),
    generateCompositionsForSynesthesia = _getGenerateSynesthes.generateCompositionsForSynesthesia,
    generateRenderPropsForSynesthesia = _getGenerateSynesthes.generateRenderPropsForSynesthesia;
  function generateRenderProps() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var generateType = props.generateType;
    if (generateType === "sunglasses") {
      return generateRenderPropsForGlasses();
    } else if (generateType === "textile") {
      return generateRenderPropsForTextile(props);
    } else if (generateType === "binaryWords") {
      return generateRenderPropsForBinaryWords(props);
    } else if (generateType === "noNeedForNoise") {
      return generateRenderPropsForNoNeedForNoise(props);
    } else if (generateType === "synesthesia") {
      return generateRenderPropsForSynesthesia(props);
    } else if (generateType === "blank") {
      return generateRenderPropsForBlank(props);
    }
  }
  function generateCompositions() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var generateType = props.generateType;
    if (generateType === "sunglasses") {
      return generateCompositionsForGlasses(props);
    } else if (generateType === "textile") {
      return generateCompositionsForTextile(props);
    } else if (generateType === "binaryWords") {
      return generateCompositionsForBinaryWords(props);
    } else if (generateType === "noNeedForNoise") {
      return generateCompositionsForNoNeedForNoise(props);
    } else if (generateType === "synesthesia") {
      return generateCompositionsForSynesthesia(props);
    } else if (generateType === "blank") {
      return generateCompositionsForBlank(props);
    } else {
      return generateCompositionsForConstructions(props);
    }
  }
  return {
    generateRenderProps: generateRenderProps,
    generateCompositions: generateCompositions
  };
}