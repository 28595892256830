"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGenerateBlankFunctions;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _utils = require("wapplr/dist/common/utils");
var _getDefaults = require("./getDefaults");
function getGenerateBlankFunctions(_ref) {
  var shuffleArray = _ref.shuffleArray,
    getRandomInt = _ref.getRandomInt,
    initialData = _ref.initialData;
  function generateBlank(_ref2) {
    var colorScheme = _ref2.colorScheme;
    var blankD = [{
      width: 1,
      heidht: 1,
      depth: 1,
      position: {
        x: 0,
        y: 0,
        z: 0
      },
      material: {
        color: colorScheme.yellow
      }
    }];
    function setNewMinMax(_ref3) {
      var _ref3$mm = _ref3.mm,
        mm = _ref3$mm === void 0 ? {} : _ref3$mm,
        object = _ref3.object,
        p = _ref3.p,
        d = _ref3.d,
        callback = _ref3.callback;
      var min = object.position[p] - object[d] / 2;
      var max = object.position[p] + object[d] / 2;
      if (typeof mm["min" + p] === "undefined" || mm["min" + p] > min) {
        mm["min" + p] = min;
        if (callback) {
          callback({
            type: "min",
            object: object
          });
        }
      }
      if (typeof mm["max" + p] === "undefined" || mm["max" + p] < max) {
        mm["max" + p] = max;
        if (callback) {
          callback({
            type: "max",
            object: object
          });
        }
      }
    }
    var generatedMinMax = {};
    blankD.forEach(function (object, i) {
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    return {
      generatedData: [].concat(blankD),
      generatedMinMax: generatedMinMax,
      analytics: {
        "1. Color scheme": colorScheme.name
      }
    };
  }
  function generateCompositionsForBlank() {
    var defaultColors = {
      red: "#de1305",
      blue: "#426dfb",
      yellow: "#e7b508",
      black: "#000000",
      white: "#e7ddcc"
    };
    var colorScheme = shuffleArray([(0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultColors), {}, {
      background: "#faf5df",
      sketchBg: "rgba(76,192,199,0.4)",
      sketchBgGrayscale: "rgba(115,121,128,0.3)",
      canvas1Colors: ["#f9f7f0", "#faf5df"],
      name: "Constructivist coast",
      shortCode: "CC",
      borderAndFont: "rgba(29,29,38,0.7)"
    }), (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, defaultColors), {}, {
      background: "#e4a55e",
      sketchBg: "rgba(40,146,142,0.6)",
      sketchBgGrayscale: "rgba(236, 210, 167, 0.3)",
      canvas1Colors: ["#e4a55e", "#e4a55e"],
      name: "Constructivism, sea of sand",
      shortCode: "CS",
      borderAndFont: "rgba(29,29,38,0.7)"
    })])[0];
    var ggData = generateBlank({
      colorScheme: colorScheme
    });
    var generatedData = (0, _toConsumableArray2["default"])(ggData.generatedData);
    var sketchBgColor = colorScheme.sketchBg;
    var backgroundBorderProps = [[colorScheme.borderAndFont, "rgba(0,0,0,0)", colorScheme.borderAndFont, "rgba(0,0,0,0)"], 29.5, 29.5, 20, 20];
    var compositions = (0, _toConsumableArray2["default"])(initialData.map(function (v) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, v), {}, {
        data: generatedData
      });
    }));
    compositions[0].background = colorScheme.background;
    compositions[0].randomVariables = (0, _toConsumableArray2["default"])(Array(100).fill(0).map(function () {
      return getRandomInt(0, 99999) / 100000;
    }));
    compositions[0].canvasFullBackground = [{
      type: "canvas1",
      props: colorScheme.canvas1Colors
    }, {
      type: "border",
      props: [backgroundBorderProps[0][0]]
    }, {
      type: "carco",
      props: [colorScheme.borderAndFont, 2, 2, 2, 2, [7, 17], 7, 10]
    }];
    var mainSketchBgType = shuffleArray(["sketchBgVertical", "sketchBgHorizontal"])[0];
    compositions[0].canvasBackground = [{
      type: "border",
      props: backgroundBorderProps
    }, {
      type: mainSketchBgType,
      props: [sketchBgColor, 30, 30, 20, 20]
    }];
    compositions[0].cameraTarget = {
      "x": 0,
      "y": 0,
      "z": 0
    };
    compositions[0].cameraPosition = {
      "x": 0,
      "y": 24,
      "z": 0
    };
    compositions[0].objectsPosition = {
      "x": 0,
      "y": 0,
      "z": 0
    };
    compositions[0].cameraZoom = 1;
    compositions[0].splitScreen = {
      disableChangeSplitScreen: true
    };
    compositions[0].objectsRotation = {
      x: 0,
      y: 0,
      z: 0
    };
    compositions[0].controlsProps = {
      minPolarAngle: -Math.PI / 2,
      maxPolarAngle: Math.PI / 2,
      minAzimuthAngle: -Math.PI / 2,
      maxAzimuthAngle: Math.PI / 2
    };
    compositions[0].lights = [{
      x: -20,
      y: 60,
      z: ggData.generatedMinMax.maxz + 20,
      type: "Directional",
      props: ["#ffffff", 2]
    }];
    compositions[0].ambientLight = {
      props: ["#ffffff", 3]
    };
    compositions[0].animation = ["D"];
    compositions[0].animationProps = {
      addObjectsAnimationType: "fromCurrentToNext",
      addObjectsAnimationTime: [3000, 6000],
      cameraMoveTime: 5000
    };
    var generatedDimension = shuffleArray(["landscape4K", "portrait4K", "square4K"])[0];
    var dimensions = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _getDefaults.defaultDimensions), {}, {
      squareHD: {}
    });
    compositions[0].dimensions = {
      dimensions: Object.keys(dimensions).map(function (key) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, dimensions[key]), {}, {
          key: key
        });
      }),
      defaultDimension: generatedDimension
    };
    compositions[0].data.forEach(function (d) {
      if (d.material.opacity) {
        d.shadow = {
          disableReceiveShadow: true
        };
      }
    });
    compositions[1] = (0, _utils.copyObject)(compositions[0]);
    var analytics = (0, _objectSpread2["default"])({}, ggData.analytics);
    return {
      compositions: compositions,
      generatedData: generatedData,
      analytics: analytics
    };
  }
  function generateRenderPropsForBlank() {
    return {
      renderType: "WebGL",
      shadow: {
        enable: true,
        opacity: 0.5
      },
      camera: {
        d: 25,
        fov: 40,
        type: "perspective"
      }
    };
  }
  return {
    generateCompositionsForBlank: generateCompositionsForBlank,
    generateRenderPropsForBlank: generateRenderPropsForBlank
  };
}