"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getDefaults;
exports.defaultDimensions = void 0;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _defaultAppData = require("default-app/dist/common/defaultAppData");
var defaultDimensions = (0, _objectSpread2["default"])({}, _defaultAppData.defaultDimensions);
exports.defaultDimensions = defaultDimensions;
function getDefaults() {
  var defaultMaterialProps = {
    color: 0xffffff
  };
  var defaultCameraPosition = {
    x: 16,
    y: 346,
    z: 9
  };
  var defaultCameraUp = {
    x: 0,
    y: 1,
    z: 0
  };
  var defaultCameraTarget = {
    x: 0,
    y: 301,
    z: -10
  };
  function getDefaultObjectsPosition(_ref) {
    var target = _ref.target;
    return {
      x: 1.9,
      y: target.y + 1,
      z: -5.8
    };
  }
  var defaultControlsProps = {
    minPolarAngle: -Math.PI / 2,
    maxPolarAngle: Math.PI / 2,
    minAzimuthAngle: 0,
    maxAzimuthAngle: Math.PI / 2
  };
  var defaultLightParams = {
    x: 150,
    y: 150,
    z: 150,
    type: "Point",
    props: ["#ffffff", 0.7]
  };
  return {
    defaultMaterialProps: defaultMaterialProps,
    defaultCameraPosition: defaultCameraPosition,
    defaultCameraUp: defaultCameraUp,
    defaultCameraTarget: defaultCameraTarget,
    defaultDimensions: defaultDimensions,
    getDefaultObjectsPosition: getDefaultObjectsPosition,
    defaultControlsProps: defaultControlsProps,
    defaultLightParams: defaultLightParams
  };
}