"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getGenerateBinaryWordsFunctions;
var _objectSpread2 = _interopRequireDefault(require("@babel/runtime/helpers/objectSpread2"));
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _utils = require("wapplr/dist/common/utils");
var _getDefaults = require("./getDefaults");
var _color = _interopRequireDefault(require("color"));
function text2Binary(string) {
  return string.split('').map(function (_char) {
    return _char.charCodeAt(0).toString(2).padStart(8, 0).split("").map(function (n) {
      return Number(n);
    });
  });
}
function binary2Text(binary) {
  var binString = '';
  binary.forEach(function (binA) {
    var bin = binA.join("");
    binString += String.fromCharCode(parseInt(bin, 2));
  });
  return binString;
}
function chunkString(str, length) {
  return str.match(new RegExp('.{1,' + length + '}', 'g'));
}
function chunkArray(inputArray) {
  var perChunk = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 8;
  return inputArray.reduce(function (resultArray, item, index) {
    var chunkIndex = Math.floor(index / perChunk);
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);
}
function binaryFromData(_ref) {
  var data = _ref.data,
    _ref$nullColors = _ref.nullColors,
    nullColors = _ref$nullColors === void 0 ? ["#263238", "#273238", "#607d8b", "#90a4ae"] : _ref$nullColors;
  var td = (0, _toConsumableArray2["default"])(data);
  var mergedData = td.reverse().reduce(function (nd, d) {
    if (!nd.find(function (sd) {
      return sd.position.x === d.position.x && sd.position.y === d.position.y && sd.width === d.width && sd.height === d.height;
    })) {
      nd.push(d);
    }
    return nd;
  }, []).sort(function (a, b) {
    var aX = a.position.x;
    var aY = a.position.y;
    var bX = b.position.x;
    var bY = b.position.y;
    return aY > bY ? 1 : aY < bY ? -1 : aX > bX ? 1 : -1;
  });
  return chunkArray(mergedData.map(function (d) {
    return nullColors.indexOf(d.material.color) > -1 ? 0 : 1;
  }));
}
function textFromData(p) {
  var binary = binaryFromData(p);
  return binary2Text(binary);
}
function getShapes(binary) {
  var shapes = binary.reduce(function (a, v, row) {
    v.forEach(function (bin, column) {
      var ne = [];
      if (bin === 0 && column !== 0) {
        if (binary[row] && binary[row][column - 1] === 0) {
          ne.push([row, column - 1]);
        }
        if (binary[row] && binary[row][column + 1] === 0) {
          ne.push([row, column + 1]);
        }
        if (binary[row - 1] && binary[row - 1][column] === 0) {
          ne.push([row - 1, column]);
        }
        if (binary[row + 1] && binary[row + 1][column] === 0) {
          ne.push([row + 1, column]);
        }
        if (binary[row - 1] && binary[row - 1][column + 1] === 0 && binary[row] && binary[row][column + 1] === 0) {
          ne.push([row - 1, column + 1]);
        }
        if (ne.length) {
          var foundShape = a.find(function (shape) {
            return shape.find(function (_ref2) {
              var _ref3 = (0, _slicedToArray2["default"])(_ref2, 2),
                r = _ref3[0],
                c = _ref3[1];
              return ne.find(function (_ref4) {
                var _ref5 = (0, _slicedToArray2["default"])(_ref4, 2),
                  row = _ref5[0],
                  column = _ref5[1];
                return r === row && c === column;
              });
            });
          });
          if (foundShape) {
            foundShape.push([row, column]);
          } else {
            a.push([[row, column]]);
          }
        } else {
          a.push([[row, column]]);
        }
      }
    });
    return a;
  }, []);
  shapes = shapes.reduce(function (a, shape) {
    if (shape.length === 1) {
      var foundShape = shapes.find(function (s) {
        return shape !== s && s.length === 1 && s[0][0] === shape[0][0] - 1 && s[0][1] === shape[0][1] - 1;
      });
      if (foundShape) {
        foundShape.push(shape[0]);
      } else {
        a.push(shape);
      }
    } else {
      a.push(shape);
    }
    return a;
  }, []);
  return shapes;
}
function getShape(shapes, binary, _ref6) {
  var _ref7 = (0, _slicedToArray2["default"])(_ref6, 2),
    row = _ref7[0],
    column = _ref7[1];
  var foundShape;
  var bin = binary[row] && binary[row][column];
  if (bin === 0 && column !== 0) {
    foundShape = shapes.find(function (shape) {
      return shape.find(function (_ref8) {
        var _ref9 = (0, _slicedToArray2["default"])(_ref8, 2),
          r = _ref9[0],
          c = _ref9[1];
        return r === row && c === column;
      });
    });
  }
  return foundShape;
}
function getShapesObject(shapes, binary, rowCols) {
  return rowCols.reduce(function (a, rowcol) {
    var shape = getShape(shapes, binary, rowcol);
    if (shape) {
      if (a.indexOf(shape) === -1) {
        a.push(shape);
      }
    } else {
      a.push(null);
    }
    return a;
  }, []);
}
function getShapeIfIsLight(shapes, binary, row, column) {
  if (binary[row] && binary[row][column] === 0) {
    return null;
  }
  var s1 = getShape(shapes, binary, [row, column + 1]);
  var s2s = getShapesObject(shapes, binary, [[row, column + 2], [row - 1, column + 1]]);
  var s3s = getShapesObject(shapes, binary, [[row, column + 2], [row + 1, column + 1]]);
  return binary[row] && binary[row][column + 1] === 0 ? s1 : binary[row - 1] && binary[row - 1][column + 2] === 1 && s2s.length === 1 ? s2s[0] : binary[row + 1] && binary[row + 1][column + 2] === 1 && s3s.length === 1 ? s3s[0] : null;
}
function getLightsForShapes(shapes, binary) {
  return binary.reduce(function (o, v, row) {
    v.map(function (bin, column) {
      var isLight = getShapeIfIsLight(shapes, binary, row, column);
      var shapeN = isLight && shapes.indexOf(isLight);
      if (isLight && shapeN > -1) {
        if (!o[shapeN]) {
          o[shapeN] = [];
        }
        o[shapeN].push([row, column]);
      }
    });
    return o;
  }, {});
}
function floor(n, p) {
  return Math.floor(n * p) / p;
}
function isIntersectedWith(d, i) {
  var minx = d.position.x - d.width / 2;
  var maxx = d.position.x + d.width / 2;
  var miny = d.position.y - d.height / 2;
  var maxy = d.position.y + d.height / 2;
  return i.filter(function (c) {
    var cminx = c.position.x - c.width / 2;
    var cmaxx = c.position.x + c.width / 2;
    var cminy = c.position.y - c.height / 2;
    var cmaxy = c.position.y + c.height / 2;
    var xI1 = minx >= cminx && minx <= cmaxx || maxx >= cminx && maxx <= cmaxx;
    var yI1 = miny >= cminy && miny <= cmaxy || maxy >= cminy && maxy <= cmaxy;
    var xI2 = cminx >= minx && cminx <= maxx || cmaxx >= minx && cmaxx <= maxx;
    var yI2 = cminy >= miny && cminy <= maxy || cmaxy >= miny && cmaxy <= maxy;
    return xI1 && yI1 || xI2 && yI2;
  });
}
function getGenerateBinaryWordsFunctions(_ref10) {
  var shuffleArray = _ref10.shuffleArray,
    getRandomInt = _ref10.getRandomInt,
    generatePropsArray = _ref10.generatePropsArray,
    initialData = _ref10.initialData;
  function generateBinaryWords(props) {
    var _props$word = props.word,
      word = _props$word === void 0 ? [{
        d: "Through art save the world"
      }
      //{d: "The machine, which is thought to be cold and inhuman, can help to realize what is most subjective, unattainable, and profound in a human being - Vera Molnar"},
      //{d: "Generative art opens up the possibility of exploring new dimensions of human art and communicating with others in new ways - Michael Noll"}
      ] : _props$word,
      _props$nullColors = props.nullColors,
      nullColors = _props$nullColors === void 0 ? [{
        d: "#263238",
        name: "Dark Gray"
      }, {
        d: "#607d8b",
        name: "Middle Gray"
      }, {
        d: "#90a4ae",
        name: "Concrete Gray"
      }] : _props$nullColors,
      _props$lightColors = props.lightColors,
      lightColors = _props$lightColors === void 0 ? [{
        d: [{
          d: "#ff9800",
          n: 0
        }, {
          d: "#ff6e40",
          n: 0
        }, {
          d: "#ce93d8",
          n: 0
        }, {
          d: "#ff8a80",
          n: 0
        }, {
          d: "#ff5252",
          n: 0
        }],
        name: "City Lights"
      }, {
        d: [{
          d: "#ff8a65",
          n: 0
        }, {
          d: "#ffa000",
          n: 0
        }, {
          d: "#ffc107",
          n: 0
        }, {
          d: "#ffcdd2",
          n: 0
        }],
        name: "Pastel Roses"
      }, {
        d: [{
          d: "#fa448c",
          n: 0
        }, {
          d: "#fec859",
          n: 0
        }, {
          d: "#491d88",
          n: 0
        }, {
          d: "#331a38",
          n: 0
        }],
        name: "Neon Glow"
      }, {
        d: [{
          d: "#d99202",
          n: 0
        }, {
          d: "#efaa1e",
          n: 0
        }, {
          d: "#fdd349",
          n: 0
        }, {
          d: "#fbbd4e",
          n: 0
        }],
        name: "Gold Light"
      }, {
        d: [{
          d: "#d2fbfb",
          n: 0
        }, {
          d: "#a6e0e3",
          n: 0
        }, {
          d: "#e794fe",
          n: 0
        }, {
          d: "#fe94c3",
          n: 0
        }, {
          d: "#ffa7ce",
          n: 0
        }],
        name: "Candy"
      }] : _props$lightColors,
      _props$landscapeColor = props.landscapeColors,
      landscapeColors = _props$landscapeColor === void 0 ? [[{
        d: "#ffba52",
        name: "Desert"
      }, {
        d: "#1e88e5",
        name: "Clear Sky"
      }], [{
        d: "#ffba52",
        name: "Desert"
      }, {
        d: "#1e56e5",
        name: "Blue Night"
      }], [{
        d: "#5494d9",
        name: "Sea"
      }, {
        d: ["#de5959", "#f57360"],
        name: "Sunset Beach"
      }], [{
        d: "#5494d9",
        name: "Sea"
      }, {
        d: "#1e88e5",
        name: "Clear Sky"
      }], [{
        d: ["#64b1e1", "#83ffff"],
        name: "Laguna"
      }, {
        d: "#45289f",
        name: "Purple Night"
      }], [{
        d: "#0139cc",
        name: "Ocean"
      }, {
        d: "#7743e0",
        name: "Purple Afternoon"
      }], [{
        d: "#0139cc",
        name: "Ocean"
      }, {
        d: "#1e88e5",
        name: "Clear Sky"
      }], [{
        d: ["#7f324e", "#ff80ab"],
        name: "Rose Field",
        exclude: ["iloveyou"]
      }, {
        d: ["#2c1367", "#572039"],
        name: "Starry Sky",
        exclude: ["iloveyou"]
      }], [{
        d: ["#9a5db2", "#d37b86"],
        name: "Lavender Field"
      }, {
        d: "#1e88e5",
        name: "Clear Sky"
      }], [{
        d: ["#64b1e1", "#83ffff"],
        name: "Laguna"
      }, {
        d: ["#2079e7", "#F57360FF"],
        name: "Sunset"
      }], [{
        d: ["#64b1e1", "#83ffff"],
        name: "Laguna"
      }, {
        d: ["#de5959", "#f57360"],
        name: "Sunset Beach"
      }], [{
        d: "#0139cc",
        name: "Ocean"
      }, {
        d: ["#2079e7", "#F57360FF"],
        name: "Sunset"
      }], [{
        d: "#0139cc",
        name: "Ocean"
      }, {
        d: ["#de5959", "#f57360"],
        name: "Sunset Beach"
      }], [{
        d: ["#7f324e", "#ff80ab"],
        name: "Rose Field",
        exclude: ["iloveyou"]
      }, {
        d: "#45289f",
        name: "Purple Night"
      }], [{
        d: ["#9a5db2", "#d37b86"],
        name: "Lavender Field"
      }, {
        d: "#f57360",
        name: "Sunset"
      }]] : _props$landscapeColor,
      _props$geometryType = props.geometryType,
      geometryType = _props$geometryType === void 0 ? shuffleArray(["extrude", "rectangle", "mix"])[0] : _props$geometryType;
    var randomWord = word[getRandomInt(0, word.length - 1)];
    var _shuffleArray$ = (0, _slicedToArray2["default"])(shuffleArray(landscapeColors.filter(function (_ref11) {
        var _ref12 = (0, _slicedToArray2["default"])(_ref11, 2),
          groundColor = _ref12[0],
          skyColor = _ref12[1];
        return !(groundColor.exclude && groundColor.exclude.indexOf(randomWord.d) > -1 || skyColor.exclude && skyColor.exclude.indexOf(randomWord.d) > -1);
      }))[0], 2),
      groundColor = _shuffleArray$[0],
      skyColor = _shuffleArray$[1];
    var nullColor = shuffleArray(nullColors)[0];
    var binary = text2Binary(randomWord.d);
    var shapes = getShapes(binary);
    var randomLightColors = shuffleArray(lightColors)[0];
    var lightColorsByLength = generatePropsArray({
      props: randomLightColors.d,
      length: shapes.length
    });
    var gColor = typeof groundColor.d === "object" ? [(0, _color["default"])(groundColor.d[0]), (0, _color["default"])(groundColor.d[1])] : (0, _color["default"])(groundColor.d);
    var sColor = typeof skyColor.d === "object" ? [(0, _color["default"])(skyColor.d[0]), (0, _color["default"])(skyColor.d[1])] : (0, _color["default"])(skyColor.d);
    var colM = Math.sqrt(randomWord.d.length * 8);
    var col = (colM % 8 ? Math.floor(colM / 8) + 1 : colM / 8) * 8;
    var lettersOnRow = col / 8;
    var row = randomWord.d.length % (col / 8) ? Math.floor(randomWord.d.length / (col / 8)) + 1 : randomWord.d.length / (col / 8);
    var nN = [col, row];
    var groundN = row % 2 ? Math.floor(row / 2) + 1 : row / 2;
    var skyN = row - groundN;
    var ground = Array(groundN).fill(null).map(function (v, i) {
      if (Array.isArray(gColor)) {
        return gColor[0].mix(gColor[1], 1 / (groundN - 1) * i).hex();
      }
      return gColor.darken(i / (groundN * 3)).hex();
    });
    var sky = Array(skyN).fill(null).map(function (v, i) {
      if (Array.isArray(sColor)) {
        return sColor[0].mix(sColor[1], 1 / (skyN - 1) * i).hex();
      }
      return sColor.lighten(i / (skyN * 3)).hex();
    });
    var landscape = [].concat((0, _toConsumableArray2["default"])(sky), (0, _toConsumableArray2["default"])(ground));
    var width = 82;
    var height = 82;
    var wN = nN[0];
    var dN = nN[1];
    var wO = width / wN;
    var hO = height / dN;
    var dO = 0.05;
    var startX = -width / 2;
    var startY = height / 2;
    var startZ = 0;
    var genPart = [];
    function getOctagon(_ref13) {
      var width = _ref13.width,
        height = _ref13.height;
      var wO = width;
      var heightO = height / 2;
      return [[{
        func: "moveTo",
        props: [0, 0]
      }, {
        func: "lineTo",
        props: [wO / 3, -heightO / 2]
      }, {
        func: "lineTo",
        props: [wO / 3 * 2, -heightO / 2]
      }, {
        func: "lineTo",
        props: [wO, 0]
      }, {
        func: "lineTo",
        props: [wO, heightO]
      }, {
        func: "lineTo",
        props: [wO / 3 * 2, heightO + heightO / 2]
      }, {
        func: "lineTo",
        props: [wO / 3, heightO + heightO / 2]
      }, {
        func: "lineTo",
        props: [0, heightO]
      }, {
        func: "lineTo",
        props: [0, 0]
      }].map(function (p) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, p), {}, {
          props: [p.props[0], p.props[1] + heightO / 2]
        });
      })];
    }
    (0, _toConsumableArray2["default"])(Array(wN)).forEach(function (_, wI) {
      (0, _toConsumableArray2["default"])(Array(dN)).forEach(function (_, dI) {
        genPart.push({
          width: wO,
          height: hO,
          depth: dO,
          position: {
            x: startX + wI * wO + wO / 2,
            y: startY - dI * hO - hO / 2,
            z: startZ + dO / 2
          },
          material: {
            color: landscape[dI] || "#ffffff"
          },
          rotation: {
            x: 0,
            y: 0,
            z: 0
          }
        });
      });
    });
    genPart.push.apply(genPart, (0, _toConsumableArray2["default"])(binary.reduce(function (a, v, row) {
      a.push.apply(a, (0, _toConsumableArray2["default"])(v.map(function (bin, column) {
        var isLight = getShapeIfIsLight(shapes, binary, row, column);
        var shapeN = isLight && shapes.indexOf(isLight);
        var lightColor = isLight && shapeN > -1 && lightColorsByLength[shapeN];
        if (bin === 0 || isLight) {
          return (0, _objectSpread2["default"])({
            width: wO,
            height: hO,
            depth: dO,
            position: {
              x: startX + (column + row % lettersOnRow * 8) * wO + wO / 2,
              y: startY - Math.floor(row / lettersOnRow) * hO - hO / 2,
              z: startZ + dO / 2 + dO
            },
            material: {
              color: bin === 0 ? nullColor.d : isLight ? lightColor : null,
              opacity: 1
            },
            rotation: {
              x: 0,
              y: 0,
              z: 0
            },
            userData: {
              isBinaryData: true,
              bin: bin,
              row: row,
              column: column
            }
          }, geometryType === "extrude" ? {
            geometryType: "extrude",
            geometryProps: getOctagon({
              width: wO,
              height: hO
            })
          } : geometryType === "mix" ? shuffleArray([{}, {
            geometryType: "extrude",
            geometryProps: getOctagon({
              width: wO,
              height: hO
            })
          }])[0] : geometryType === "sphere" ? {
            geometryType: geometryType
          } : {});
        }
        return null;
      }).filter(function (d) {
        return d;
      })));
      return a;
    }, [])));
    function getElementByRowCol(data, rowCol) {
      var children = (0, _toConsumableArray2["default"])(data);
      return children.reverse().find(function (child) {
        var childUserData = child.userData || {};
        return childUserData.column === rowCol[1] && childUserData.row === rowCol[0];
      });
    }
    function getElementsFromData(data) {
      var lights = getLightsForShapes(shapes, binary);
      return shapes.map(function (shape, i) {
        var r = shape.map(function (_ref14) {
          var _ref15 = (0, _slicedToArray2["default"])(_ref14, 2),
            row = _ref15[0],
            column = _ref15[1];
          return getElementByRowCol(data, [row, column]);
        });
        var lightsForThisShape = lights[i.toString()] || [];
        r.push.apply(r, (0, _toConsumableArray2["default"])(lightsForThisShape.map(function (_ref16) {
          var _ref17 = (0, _slicedToArray2["default"])(_ref16, 2),
            row = _ref17[0],
            column = _ref17[1];
          return getElementByRowCol(data, [row, column]);
        })));
        return r;
      }).filter(function (shape) {
        return shape.indexOf(undefined) === -1;
      });
    }
    getElementsFromData(genPart).forEach(function (shape, i) {
      shape.forEach(function (d) {
        d.userData.isShape = i;
      });
    });
    var binaryWordsD = [].concat(genPart);
    function setNewMinMax(_ref18) {
      var _ref18$mm = _ref18.mm,
        mm = _ref18$mm === void 0 ? {} : _ref18$mm,
        object = _ref18.object,
        p = _ref18.p,
        d = _ref18.d,
        callback = _ref18.callback;
      var min = object.position[p] - object[d] / 2;
      var max = object.position[p] + object[d] / 2;
      if (typeof mm["min" + p] === "undefined" || mm["min" + p] > min) {
        mm["min" + p] = min;
        if (callback) {
          callback({
            type: "min",
            object: object
          });
        }
      }
      if (typeof mm["max" + p] === "undefined" || mm["max" + p] < max) {
        mm["max" + p] = max;
        if (callback) {
          callback({
            type: "max",
            object: object
          });
        }
      }
    }
    var generatedMinMax = {};
    binaryWordsD.forEach(function (object, i) {
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "x",
        d: "width"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "y",
        d: "height"
      });
      setNewMinMax({
        mm: generatedMinMax,
        object: object,
        p: "z",
        d: "depth"
      });
    });
    return {
      row: row,
      col: col,
      sky: sky,
      ground: ground,
      generatedData: (0, _toConsumableArray2["default"])(binaryWordsD.map(function (data) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, data), {}, {
          width: floor(data.width, 100),
          height: floor(data.height, 100),
          depth: floor(data.depth, 100),
          position: {
            x: floor(data.position.x, 100),
            y: floor(data.position.y, 100),
            z: floor(data.position.z, 100)
          },
          rotation: {
            x: floor(data.rotation.x, 1000),
            y: floor(data.rotation.y, 1000),
            z: floor(data.rotation.z, 1000)
          }
        });
      })),
      generatedMinMax: generatedMinMax,
      analytics: {
        Quote: randomWord.d,
        "Null Color": nullColor.name,
        Ground: groundColor.name,
        Sky: skyColor.name,
        Lights: randomLightColors.name
      }
    };
  }
  function generateCompositionsForBinaryWords() {
    var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var generatedDimension = shuffleArray(["landscape4K", "portrait4K", "square4K"])[0];
    var randomVariables = (0, _toConsumableArray2["default"])(Array(100).fill(0).map(function () {
      return getRandomInt(0, 99999) / 100000;
    }));
    var randomEasing = shuffleArray([{
      easingFunction: "Elastic",
      easingType: "Out",
      cameraEasingFunction: "Elastic",
      cameraEasingType: "Out",
      objectsEasingFunction: "Elastic",
      objectsEasingType: "Out"
    }
    /*{
        easingFunction: "Linear",
        easingType: "None"
    },
    {
        easingFunction: "Exponential",
        easingType: "In"
    },*/])[0];
    var randomEasing2 = shuffleArray([{
      easingFunction: "Sinusoidal",
      easingType: "Out",
      cameraEasingFunction: "Sinusoidal",
      cameraEasingType: "Out",
      objectsEasingFunction: "Sinusoidal",
      objectsEasingType: "Out"
    }
    /*{
        easingFunction: "Linear",
        easingType: "None"
    },
    {
        easingFunction: "Exponential",
        easingType: "Out"
    },*/])[0];
    var ggData = generateBinaryWords(props);
    var bgColor = (0, _color["default"])(ggData.ground[0]);
    var colorScheme = {
      background: bgColor.lightness(95).saturationl(10).hex(),
      //canvas1Colors: ["rgba(0,0,0,0)", "rgba(0,0,0,0)"],
      //name: "Default",
      borderAndFont: ggData.ground[0]
      //black: "#000000"
    };

    var generatedData = (0, _toConsumableArray2["default"])(ggData.generatedData);
    var compositions = (0, _toConsumableArray2["default"])(initialData.map(function (v) {
      return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, v), {}, {
        data: generatedData
      });
    }));
    compositions[0].background = colorScheme.background;
    compositions[0].randomVariables = randomVariables;
    compositions[0].canvasFullBackground = [{
      type: "carco",
      props: [colorScheme.borderAndFont, 2, 2, 2, 2, [7, 17], 7, 10]
    }];
    compositions[0].canvasBackground = [];
    compositions[0].cameraTarget = {
      x: 0,
      y: 0,
      z: 0
    };
    compositions[0].cameraPosition = {
      x: 0,
      y: 170,
      z: 0
    };
    compositions[0].objectsPosition = {
      x: 0,
      y: 0,
      z: 0
    };
    compositions[0].cameraZoom = 1;
    compositions[0].splitScreen = {
      disableChangeSplitScreen: true
    };
    compositions[0].objectsRotation = {
      x: -Math.PI / 2,
      y: 0,
      z: 0
    };
    compositions[0].controlsProps = {
      minPolarAngle: 0,
      maxPolarAngle: 0,
      minAzimuthAngle: -Math.PI / 2,
      maxAzimuthAngle: Math.PI / 2
    };
    compositions[0].lights = [{
      x: 0,
      y: ggData.generatedMinMax.maxy + 80,
      z: 80,
      type: "Directional",
      props: ["#ffffff", 1]
    }];
    compositions[0].ambientLight = {
      props: ["#ffffff", 3]
    };
    compositions[0].animation = ["D"];
    compositions[0].animationProps = (0, _objectSpread2["default"])({
      addObjectsAnimationType: "fromCurrentToNext",
      addObjectsAnimationTime: [3000, 5000],
      cameraMoveTime: 3000,
      waitTimeBetweenAnimations: 1,
      waitTimeBeforeAnimation: 1,
      waitTimeBeforeAnimation2: 1,
      waitTimeFinalEnd: 1
    }, randomEasing);
    var dimensions = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, _getDefaults.defaultDimensions), {}, {
      squareHD: {}
    });
    compositions[0].dimensions = {
      dimensions: Object.keys(dimensions).map(function (key) {
        return (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, dimensions[key]), {}, {
          key: key
        });
      }),
      defaultDimension: generatedDimension
    };
    compositions[0].data.forEach(function (d) {
      if (d.material.opacity > 0 || typeof d.material.opacity === "undefined") {
        d.shadow = {
          disableReceiveShadow: true
        };
      }
    });
    compositions[1] = (0, _utils.copyObject)(compositions[0]);
    compositions[0].data = compositions[0].data.filter(function (d) {
      var _d$userData;
      return !((_d$userData = d.userData) !== null && _d$userData !== void 0 && _d$userData.isBinaryData);
    });
    compositions[1].objectsRotation = {
      x: -Math.PI / 2,
      y: 0,
      z: 0
    };
    compositions[2] = (0, _utils.copyObject)(compositions[1]);
    compositions[2].objectsRotation = {
      x: -Math.PI / 2,
      y: 0,
      z: 45 * Math.PI / 180
    };
    compositions[2].cameraPosition = {
      x: 0,
      y: 210,
      z: 0
    };
    var cube1 = {
      width: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4,
      height: (ggData.generatedMinMax.maxy - ggData.generatedMinMax.miny) / 4,
      depth: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4,
      position: {
        x: 0,
        y: 0,
        z: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 / 2
      }
    };
    var cube2 = {
      width: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 2,
      height: (ggData.generatedMinMax.maxy - ggData.generatedMinMax.miny) / 4 * 2,
      depth: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 2,
      position: {
        x: 0,
        y: 0,
        z: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 2 / 2
      }
    };
    var cube3 = {
      width: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 3,
      height: (ggData.generatedMinMax.maxy - ggData.generatedMinMax.miny) / 4 * 3,
      depth: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 3,
      position: {
        x: 0,
        y: 0,
        z: (ggData.generatedMinMax.maxx - ggData.generatedMinMax.minx) / 4 * 3 / 2
      }
    };
    compositions[2].data.forEach(function (d) {
      var r = isIntersectedWith(d, [cube1]);
      if (!r.length) {
        d.rotation = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.rotation), {}, {
          x: shuffleArray([d.rotation.x + 179.9 * Math.PI / 180, d.rotation.x, floor(d.rotation.x + getRandomInt(-90, 90) * Math.PI / 180, 1000), floor(d.rotation.x + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0],
          z: shuffleArray([d.rotation.z, d.rotation.z * 2,
          //d.rotation.z + 360 * Math.PI / 180,
          floor(d.rotation.z + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0],
          y: shuffleArray([
          //d.rotation.y + 180 * Math.PI / 180,
          d.rotation.y, floor(d.rotation.y + getRandomInt(-90, 90) * Math.PI / 180, 1000), floor(d.rotation.y + getRandomInt(-10, 10) * Math.PI / 180, 1000)])[0]
        });
        d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
          y: shuffleArray([d.position.y, floor(d.position.y - Math.min(d.width, d.height) * (getRandomInt(0, 10) / 10), 1000)])[0]
        });
        d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
          z: floor(d.position.z + 4 * (getRandomInt(10, 20) / 10), 1000)
        });
        var r2 = isIntersectedWith(d, [cube2]);
        var r3 = isIntersectedWith(d, [cube3]);
        if (!r2.length && !r3.length) {
          d.position.z = d.position.z * 4;
        } else if (r3.length && !r2.length) {
          d.position.z = d.position.z;
        } else if (r2.length) {
          d.position.z = -d.position.z * 2;
        }
      } else {
        d.rotation = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.rotation), {}, {
          z: shuffleArray([floor(d.rotation.z + getRandomInt(-30, 30) * Math.PI / 180, 1000)])[0]
        });
        d.position = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, d.position), {}, {
          z: floor(d.position.z + 4 * (getRandomInt(10, 20) / 10), 1000) * -6
        });
      }
    });

    /*const shapesZ = {};
     compositions[2].data.forEach((d)=>{
        if (typeof d.userData?.isShape === "number" && d.userData.isShape >= 0) {
            const z = shapesZ[d.userData.isShape] || (getRandomInt(10,20) / 5);
            shapesZ[d.userData.isShape] = z;
            d.position.z = floor(d.position.z + z, 1000) * (6)
        }
    })*/

    //compositions[2].data.push({...cube, material: {color: colorScheme.black}})

    compositions[2].animationProps = (0, _objectSpread2["default"])((0, _objectSpread2["default"])({}, compositions[2].animationProps), randomEasing2);
    compositions.splice(0, 1);
    var analytics = (0, _objectSpread2["default"])({}, ggData.analytics);
    return {
      compositions: compositions,
      generatedData: generatedData,
      analytics: analytics,
      ggData: ggData
    };
  }
  function generateRenderPropsForBinaryWords() {
    return {
      renderType: "WebGL",
      shadow: {
        enable: true,
        opacity: 0.5
      },
      camera: {
        d: 25,
        fov: 40,
        type: "perspective"
      },
      lineProps: {
        opacity: 0.02
      }
    };
  }
  return {
    generateCompositionsForBinaryWords: generateCompositionsForBinaryWords,
    generateRenderPropsForBinaryWords: generateRenderPropsForBinaryWords
  };
}